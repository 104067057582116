<template>

  <div v-bind:id="id" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="{'modal-lg': (size == 'lg')}" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">{{ title }}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <slot></slot>

      </div>
    </div>
  </div>

</template>

<script>
  export default {
    props: ['id','title','size'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>

<style src="./modal.scss" lang="scss"></style>
