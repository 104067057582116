<template>
  <div class="c-card-project-select-proposal">

    <!-- Card project -->
    <div class="card-white rounded card-project">
      <p class="large">Escolha uma proposta</p>
      <h3>Filme</h3>
      <div class="box-thumbs my-2">
        <img :src="'https://picsum.photos/600/600?random=' + Math.round(Math.random() * 10)" alt="Card image" class="thumb-project">
        <img :src="'https://picsum.photos/602/602?random=' + Math.round(Math.random() * 10)" alt="Card image" class="thumb-project">
        <img :src="'https://picsum.photos/603/603?random=' + Math.round(Math.random() * 10)" alt="Card image" class="thumb-project">
        <h5 class="ml-3 d-inline-block">12 profissionais</h5>
      </div>
      <div class="column-2">
        <p class="large">Entrega</p>
        <p class="large">Verba</p>
      </div>
      <div class="column-2">
        <h5 class="grey">28/11/2019</h5>
        <h5 class="purple">R$ 16.000,00</h5>
      </div>
      <a href="" class="btn btn-primary sm rounded d-lg-none">Ver projeto</a>
    </div>


  </div>
</template>

<script>
  export default {
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
