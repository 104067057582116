<template>
  <div class="c-card-briefing-client">

    <!-- Card Briefing -->
    <div class="card-white card-briefing rounded">
      <div class="content-img">
        <img v-if="company.logo" :src="'/' + company.logo" alt="Card image" class="thumb-briefing">
        <img v-else src="/images/avatar.jpg" alt="Card image" class="thumb-briefing">
      </div>
      <div class="content-text my-auto">
        <p>{{ $t("dash.cliente") }}</p>
        <h5>{{ company.nome_fantasia ? company.nome_fantasia : company.razao }}</h5>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['company'],
    mounted() {
        console.log('Component mounted.')
    }
  }
</script>
