<template>
  <div class="d-flex justify-content-center justify-content-lg-start">
    <img src="/images/professionals/icons/star-full.svg" v-for="i in Math.floor(this.rate)" />
    <img src="/images/professionals/icons/star-half.svg" v-for="i in 5 - Math.floor(this.rate)" />
    <span>{{ this.rate.toFixed(1).replace('.',',') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    rate: Number,
  },
};
</script>

<style scoped lang="scss">
div {
  align-items: center;
  img {
    margin-right: 8px;
  }
  span {
    margin-left: 4px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }
}
</style>
