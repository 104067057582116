<template>
  <div class="c-menu">
    <!-- Menu Dash -->
    <div class="wrapper-menu">
      <div class="menu mx-3 mx-lg-0 navbar">

        <!-- dashboard -->
        <!-- forcar carregamento via anchor e não router-link -->
        <router-link class="mb-lg-3 btn-icons btn-logo order-4 order-lg-1" to="/dashboard/admin" v-if="role == 'admin'">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm.svg" alt="" class="logo-mini d-none d-lg-block" data-toggle="tooltip" data-placement="right" title="Dashboard">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm-grey.svg" alt="" class="logo-mini d-lg-none">
        </router-link>
        <router-link class="mb-lg-3 btn-icons btn-logo order-3 order-lg-1" to="/dashboard/client" v-if="role == 'client'">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm.svg" alt="" class="logo-mini d-none d-lg-block" data-toggle="tooltip" data-placement="right" title="Dashboard">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm-grey.svg" alt="" class="logo-mini d-lg-none">
        </router-link>
        <router-link class="mb-lg-3 btn-icons btn-logo order-3 order-lg-1" to="/dashboard/professional" v-if="role == 'professional'">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm.svg" alt="" class="logo-mini d-none d-lg-block" data-toggle="tooltip" data-placement="right" title="Dashboard">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm-grey.svg" alt="" class="logo-mini d-lg-none">
        </router-link>

        <!-- notifications -->
        <router-link class="btn-icons secondary btn-bell order-1 order-lg-2" :to="{ name: 'notifications' }" v-if="role != 'admin'">
          <i class="ico-bell icons" data-toggle="tooltip" data-placement="right" title="Notificações">
            <span class="circle-notify">{{ notifications.length }}</span>
          </i>
        </router-link>
        <!-- my community -->
        <router-link class="btn-icons secondary order-2 order-lg-3" to="/dashboard/client/mycommunity/" v-if="me.company_id && company.community_flag">
          <i class="ico-user icons" data-toggle="tooltip" data-placement="right" title="My community"></i>
        </router-link>
        <router-link class="btn-icons secondary order-2 order-lg-3" to="/dashboard/client/mycommunity-notamember" v-if="me.company_id && !company.community_flag">
          <i class="ico-user icons" data-toggle="tooltip" data-placement="right" title="My community"></i>
        </router-link>
        <!-- historic -->
        <router-link class="btn-icons btn-chart secondary d-lg-inline-block order-2" :class="{'d-none': role == 'client'}" :to="'/dashboard/' + role + '/historic/'" v-if="role != 'admin'">
          <i class="ico-chart icons" data-toggle="tooltip" data-placement="right" title="Histórico de atividades"></i>
        </router-link>
        <router-link class="btn-icons btn-chart secondary d-lg-inline-block order-2" :to="'/dashboard/' + role + '/growth/'" v-if="role == 'admin'">
          <i class="ico-chart icons" data-toggle="tooltip" data-placement="right" title="Histórico de atividades"></i>
        </router-link>
        <!-- users -->
        <router-link class="btn-icons btn-single-user secondary order-3" to="/dashboard/admin/users" v-if="role == 'admin'">
          <i class="ico-single-user icons" data-toggle="tooltip" data-placement="right" title="Usuários"></i>
        </router-link>

        <router-link class="btn-icons btn-edit secondary order-4" to="/dashboard/admin/cms" v-if="role == 'admin'">
          <i class="ico-edit icons" data-toggle="tooltip" data-placement="right" title="Editar"></i>
        </router-link>

        <!-- settings -->
        <router-link class="btn-icons btn-settings secondary d-lg-inline-block order-5" :class="{'d-none': role == 'client'}" :to="'/dashboard/' + role + '/settings/'">
          <i class="ico-settings icons" data-toggle="tooltip" data-placement="right" title="Configurações"></i>
        </router-link>

        <router-link class="btn-icons btn-profile secondary d-lg-none order-6" to="/dashboard/client/company" v-if="role == 'client'">
          <i class="ico-profile icons" data-toggle="tooltip" data-placement="right" title="Perfil"></i>
        </router-link>

        <router-link class="btn-icons btn-profile secondary d-lg-none order-6" to="/dashboard/professional/profile" v-if="role == 'professional'">
          <i class="ico-profile icons" data-toggle="tooltip" data-placement="right" title="Perfil"></i>
        </router-link>

        <!-- new briefing -->
        <router-link class="btn-icons primary btn-plus white d-lg-none order-7" to="/dashboard/client/new-briefing" v-if="role == 'client'">
          <i data-feather="plus" data-toggle="tooltip" data-placement="right" title="New briefing"></i>
        </router-link>

        <div class="items-bottom d-none d-lg-block">
          <div class="btn-question">
            <span class="btn btn-icons terciary" data-toggle="tooltip" data-placement="right" title="Ajuda" v-on:click="openDrift">?</span>
          </div>

          <router-link  class="btn-user-menu" to="/dashboard/admin" v-if="role == 'admin'">
            <img :src="avatar" alt="" class="thumb-user-menu" data-toggle="tooltip" data-placement="right" title="Perfil">
          </router-link>

          <router-link  class="btn-user-menu" to="/dashboard/client/company" v-if="role == 'client'">
            <img :src="avatar" alt="" class="thumb-user-menu" data-toggle="tooltip" data-placement="right" title="Perfil">
          </router-link>

          <router-link  class="btn-user-menu" to="/dashboard/professional/profile" v-if="role == 'professional'">
            <img :src="avatar" alt="" class="thumb-user-menu" data-toggle="tooltip" data-placement="right" title="Perfil">
          </router-link>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    //
    me() {
      return this.$store.state.me;
    },
    avatar() {
      if (this.me.avatar) return '/' + this.me.avatar;
      return this.$store.getters.avatar;
    },
    company() {
      return this.$store.state.company;
    },
    role() {
      return this.$store.state.role;
    },
    notifications() {
      return this.$store.state.notifications.filter(notification => { return !notification.status; });
    }
  },
  methods: {
    openDrift: function() {
      console.log("DRIFT");
      drift.api.openChat();
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    console.log(this.role);
  }
}
</script>
