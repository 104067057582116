import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  pt: {
    home: {
      hero: {
        title: 'A Powerful Influence Marketing Platform',
        title_mobile: 'A Powerful Influence Marketing Platform',
        subtitle: 'Conectamos criadores com marcas que desejam <strong>escalar sua comunicação</strong> e <strong>criar conversas</strong> com suas&nbsp;comunidades.',
        btn1: 'Agendar Demo',
        btn2: 'Login',
        btn3: 'Assistir Demo',
      },
      be: 'Seja',
      hire: 'Contrate',
      demonstration: {
        title: 'Solução completa de marketing de influência, do briefing ao relatório de resultados.',
        title1: 'Creators Match',
        text1: 'Curadoria obsessiva e algoritmos inteligentes para conectar criadores com marcas, de acordo com suas paixões e comunidades. Tecnologia proprietária para garantir diversidade de gênero, étnica e regional.',
        title2: 'Creators Squads ',
        text2: 'Contrate times de influenciadores em um único clique! A partir dos objetivo de marca nossa plataforma entrega: Estratégia, Mapeamento, Boost Criativo, Gerenciamento de Conteúdo e Relatório de Resultados.',
        title3: 'Suporte end-to-end',
        text3: 'Mediação e suporte humanizado do ínicio ao fim de cada projeto - Do briefing ao relatório de resultados.',
        title4: 'Creators Pay',
        text4: 'Deixe a burocracia com a gente. Pagamentos e contratos seguros via plataforma com proteção para ambos. Homologação e faturamento simples e unificado em Reais, Euro ou Dólar.',
      },
      cards: {
        title: 'Influenciadores únicos prontos para aumentar a presença digital de marcas admiradas',
      },
      product: {
        be: 'Para quem cria',
        title1: 'Faça parte da rede CREATORS. A maneira mais segura de acessar briefs e colaborar com marcas globais. Ao ser selecionado, aceite ou decline propostas e controle seu fluxo sem penalidades.',
        hire: 'Para quem contrata',
        title2: 'Acesse a uma rede selecionada de freelancers criativos e influenciadores digitais. Encontre o Creator perfeito ou crie Squads, que potencializam resultados de comunicação.',
      },
      video: {
        title: 'Creators é uma rede curada e segura',
      },
      testimonials: {
        title: 'Matchmaking que empodera criadores e marcas admiradas',
      },
      tags: {
        title: 'Excelência e diversidade em todos os campos da economia criativa',
      },
      professionals: {
        title: 'Suporte em todas as etapas do match',
      },
      clients: {
        title: 'Aprovada por players influentes no mercado',
        who: 'Quem acredita',
        approved: 'Aprovada por:',
      },
      footer: {
        terms: 'Termos de uso e Política de privacidade',
        privacy: 'Política de privacidade',
      },
      login: {
        send_2: 'Enviar',
        error_1: 'Credenciais incorretas',
        error_2: 'Campo obrigatório',
        error_3: 'E-mail inválido',
        error_4: 'E-mail não cadastrado',
        error_5: 'E-mail já cadastrado',
        error_6: 'A senha deve conter no mínimo 6 caracteres',
        error_7: 'CNPJ inválido',
        error_8: 'CNPJ já cadastrado',
        label_email: 'E-mail',
        label_senha: 'Senha',
        label_name: 'Seu nome',
        label_first_name: 'Primeiro nome',
        label_phone: 'Telefone com DDD',
        label_phone_2: 'Seu telefone com DDD',
        label_codigo_curador: 'Código de curador',
        label_p_habilidade: 'Sua principal habilidade – as opções estão em inglês',
        label_habilidades: 'Suas tags complementares - escolha até 5',
        label_company: 'Nome da empresa',
        label_linkedin: 'Linkedin',
        label_site: 'Website',
        label_instagram: 'Instagram',
        label_behance: 'Behance',
        label_vimeo: 'Vimeo',
        label_youtube: 'YouTube',
        label_cnpj: 'CNPJ',
        phone_error: 'Número inválido',
        forgot_pass: 'Esqueceu sua senha?',
        forgot_pass_2: 'Não lembra sua senha? Não se preocupe, vamos criar uma nova',
        cadastro: 'Ainda não tem cadastro?',
        new_pass: 'Defina sua nova senha',
        new_pass_2: 'Nova senha',
        confirme: 'Confirme seu e-mail cadastrado',
        be: {
          txt_1: 'Crie seu perfil na rede CREATORS. A maneira mais segura de colaborar e monetizar seu talento com as marcas que você se identifica. ',
          txt_2: 'A maneira mais segura de acessar briefs e colaborar com marcas globais.',
          txt_3: 'Acesse diretamente com',
          txt_4: 'ou preencha',
          txt_5: 'Conte mais sobre você!',
          features: '<p><strong>Match Perfeito</strong><br>Crie um perfil completo e deixe nossa curadoria avaliar seus talentos. Quando seu perfil for pré-selecionado para um brief - aceite, decline ou faça uma contraproposta</p><p><strong>Deixe a Burocracia com a gente</strong><br>Pagamentos e contratos seguros via plataforma com proteção para ambos e sem custo para você</p><p><strong>Suporte Humanizado</strong><br>Mediação e suporte do nosso time durante todo o processo - do briefing ao relatório de resultados.</p><p><strong>Proposta Segura</strong><br>Além de receber convites para participar de nossas curadorias, envie propostas para seus clientes e garanta nossa intermediação livre de riscos.</p>',
          summary: 'Além de receber propostas de empresas, você também poderá enviar <br>propostas para seus clientes pela CREATORS :)',
        },
        termos_1: 'Li e concordo com os',
        termos_2: 'Termos de Uso',
        termos_error: 'É necessário concordar com os termos para continuar com o cadastro',
        continuar: 'Continuar',
        cadastrar: 'Cadastrar',
        hire: {
          txt_1: 'Contrate o Creator perfeito ou crie Squads. Conectamos criadores com marcas que desejam criar conversas autênticas com suas comunidades.',
          txt_2: 'Conectamos criadores com marcas que precisam escalar sua comunicação e criar conversas com suas comunidades.',
          features: '<p><strong>Match Perfeito</strong><br>Acesso a uma rede selecionada de freelancers criativos e influenciadores digitais. Encontre o Creator perfeito ou crie Squads, que potencializam resultados de comunicação.</p><p><strong>Deixe a Burocracia com a gente</strong><br>Pagamentos e contratos seguros via plataforma com proteção para ambos.</p><p><strong>Suporte Humanizado</strong><br>Conte com nossa mediação e suporte - do <strong>briefing</strong> ao <strong>relatório de resultados</strong></p><p><strong>Proposta Segura</strong><br>Já encontrou o talento que precisa? Consolide através da CREATORS e conte com nossa intermediação livre de riscos.</p>',
        }
      },
    },
    dash: {
      activities: 'Atividades',
      notification: 'Notificações',
      messages: 'Mensagens',
      load_more: 'Mostrar mais',
      initialmsg: 'Conectamos criadores com marcas que precisam escalar sua comunicação e criar conversas autênticas com suas comunidades.',
      proposals: 'Propostas',
      progress_project: 'Projetos em andamento',
      received_proposals: 'Propostas recebidas',
      received_proposals_s: 'Proposta recebida',
      accepted_proposals: 'Propostas aceitas',
      proposta: 'Proposta aprovada',
      complete_profile: 'Complete seu perfil',
      edit_profile: 'Edite seu perfil',
      enviar: 'Enviar proposta',
      send: 'Enviar',
      selecao: 'Seleção em andamento',
      encerrada: 'Seleção encerrada',
      notyou: 'Infelizmente você não foi selecionado. Caso você tenha enviado uma proposta, obrigado por participar desta seleção, boa sorte nas próximas.',
      aprovacao: 'Briefing em aprovação',
      curadoria: 'Curadoria em andamento',
      escolha: 'Escolha uma proposta',
      contratacao: 'Contratação em andamento',
      contratacao_ended: 'Contratação finalizada',
      cancelada: 'Cancelada',
      cancelar: 'Cancelar',
      salvar: 'Salvar',
      encerrada: 'Encerrada',
      entrega: 'Entrega',
      verba: 'Verba',
      evaluation_error: "Ocorreu um problema ao avaliar. Tente novamente mais tarde.",
      my_projects: 'Meus projetos',
      card_client: {
        txt_1: 'Trabalhe agora com alguns dos creators',
        txt_2: 'mais reconhecidos do mercado.',

      },
      iniciar_b: 'Iniciar briefing',
      add_user: 'Adicionar usuário',
      add_modal: 'Adicione novos usuários – sua equipe e colegas também podem acessar sua conta Creators!',
      full_name: 'Nome completo',
      email: 'E-mail',
      convite: 'Enviar convite',
      modal_alert_1: 'Oops! Vamos por partes?',
      modal_alert: 'Antes de enviar um briefing e contratar Creators, complete todos os dados do perfil da sua empresa',
      modal_alert_3: 'Você precisa completar alguns dados antes de escolher um profissional.',
      modal_alert_4: 'Você precisa completar seus dados financeiros antes de enviar sua proposta.',
      close: 'Fechar',
      complete: 'Completar',
      page: 'Página',
      de: 'de',
      previous: 'PRÓXIMAS',
      next: 'ANTERIORES',
      avaliacao: 'Avaliação realizada',
      razao: 'Razão social',
      cliente: 'Cliente',
      projeto: 'Projeto',
      com: 'com',
      conexoes: 'Conexões',
      profile: 'Perfil',
      renovar: 'Renovar contrato',
      renovar_txt: 'Você pode renovar o contrato especificando as datas e contando um pouco sobre o projeto.',
      d_inicio: 'Data de início',
      d_termino: 'Data de término',
      valor_adicional: 'Valor adicional R$',
      descricao: 'Descreva um pouco o projeto',
      avalie: 'Avalie o/a Creator',
      avalie_empresa: 'Avaliação da empresa',
      experiencia: 'Como foi sua experiência?',
      sua_avaliacao: 'Sua avaliação:',
      avaliar: 'Avaliar',
      deu_errado: {
        errado: 'O que deu errado?',
        comunicacao: 'Comunicação',
        pontualidade: 'Pontualidade',
        qualidade: 'Qualidade',
      },
      status: 'Status',
      pagamento: 'Pagamento',
      valor: 'Proposta feita de',
      nota: 'Subir Nota Fiscal',
      n_arquivo: 'Nome do arquivo',
      vencimento: 'Vencimento',
      anexo_b: 'Selecione ou arraste anexos do briefing',
      boleto: 'Acessar boleto',
      pagamento_1: 'Em andamento',
      pagamento_3: 'Concluído',
      contrato: 'Contrato para o projeto',
      para: 'para',
      cancelar_contrato: 'cancelar contrato',
      visualizar_contrato: 'Visualizar meu contrato',
      alert_contrato: 'Tem certeza que deseja cancelar o contrato com',
      alert_contrato_2: 'Esta ação não poderá ser desfeita.',
      dados: 'Dados necessários',
      selecionar_c: 'Selecionar Creator',
      selecionar_renewal: 'Selecionar Creator',
      b_aprovado: 'Briefing aprovado',
      empresa: 'Empresa',
      usuario: 'Usuário',
      equipamento: {
        equipamento: 'Equipamento',
        proprio: 'Próprio',
        fornecido: 'Fornecido',
        alugado: 'Alugado',
        equipamento_p: 'Equipamento próprio',
        equipamento_f: 'Equipamento será fornecido',
        equipamento_a: 'Desejo alugar equipamento com desconto (SP e POA)',
      },
      n_divulgado: 'Não divulgado',
      inicio: 'Início',
      termino: 'Término',
      software: 'Software específico',
      tipo_acesso: {
        tipo: 'Tipo de acesso',
        email: 'E-mail',
        sede: 'Sede',
      },
      anexos: 'Anexos',
      habilidades: 'Habilidades',
      enviado: 'enviado',
      recebido: 'recebido',
      profile_2: {
        since: 'Cadastro em',
        creator_since: 'Creator desde',
        area_trans: 'Copiado para a área de transferência',
        area_trans_tip: 'Clique no link para copiar para a área de transferência',
        professional_background_edit_tip: 'Suba uma imagem de background com 1920 x 1080 pixel em JPG ou GIF.',
        professional_link_edit_tip: 'Este link é a URL aberta do seu perfil na Creators. Assim que os clientes te visualizam por aqui. Fique a vontade para compartilhar está pagina e usá-la como portfólio.',
        professional_community_tip: 'Este número indica quantos clientes adicionaram você como favorito/a.',
        added_community: 'Membro adicionado à sua Community',
        removed_community: 'Membro removido da sua Community',
        add_to_community: 'Adicionar à sua Community',
        remove_from_community: 'Remover da sua Community',
        project_participation: 'Participação nos seus projetos:',
        carregando: 'carregando...',
        habilidade: 'Qual a sua habilidade principal?',
        habilidades: 'Escolha outras habilidades complementares (até 5)',
        editar: 'Editar',
        excluir: 'Excluir',
        up_image: 'Adicione ou arraste imagens em: JPEG, PNG ou GIF até 5MB',
        project_title: 'Título do projeto',
        project_desc: 'Breve descrição do projeto',
        publish_projects_tip: "Publique seus melhores projetos e aumente suas chances de aprovação.",
        publish_posts_tip: "Publique seus melhores posts no Instagram e aumente suas chances de aprovação.",
      },
      select: 'Selecione',
      pf: 'Pessoa física',
      pj: 'Pessoa jurídica',
      titular: 'Nome do titular',
      banco: 'Banco',
      agencia: 'Agência',
      conta: 'Conta',
      b_aprovacao: 'Proposta em aprovação',
      b_enviado: 'Proposta',
      b_rejeitado: 'Proposta rejeitada',
      m_ideia: 'mudei de ideia',
      b_send_proposal: 'Envie sua proposta',
      b_minha_proposta: 'Minha proposta para esse projeto é:',
      b_minha_proposta_2: 'Coloque um valor na proposta',
      b_minha_proposta_3: 'Sim, tenho interesse e disponibilidade para o período solicitado',
      visualizar: 'Visualizar',
      recusar: 'Recusar',
      aceitar: 'Aceitar',
      historico: {
        titulo: 'Histórico',
        total: 'Total de contratações',
        total_movi: 'Total movimentado',
        ticket: 'Ticket médio',
        finalizados: 'Projetos finalizados',
      },
      settings: {
        titulo: 'Para garantir que você se conecte aos jobs certos, complete ao máximo seu perfil – lembre-se: use tags objetivas e capriche na mini-bio!',
        habilidade: 'Defina sua habilidade principal – o que você faz de melhor?',
        habilidades: 'Escolha até 5 tags complementares – quais outros talentos, soft skills ou aspectos pessoais você possui?',
        perfil: 'Perfil',
        foto: 'Escolha uma foto de perfil ou arraste aqui',
        minibio: 'Mini-bio – uma breve apresentação sobre você',
        dados: 'Dados pessoais',
        nome_social: 'Nome social ou Nome fantasia',
        razao_social_empresa: 'Razão social da sua empresa',
        documento_empresa: 'CNPJ/RE',
        nome: 'Primeiro nome',
        sobrenome: 'Sobrenome',
        phone: 'Telefone',
        nascimento: 'Data de nascimento',
        cep: 'CEP',
        country: 'País',
        state: 'Estado',
        city: 'Cidade',
        logradouro: 'Logradouro',
        numero: 'Número',
        complemento: 'Complemento',
        dados_bancarios: 'Dados bancários',
        excluir: 'Excluir perfil',
        logout: 'Fazer logout',
        salvando: 'Salvando',
        warning: 'Atenção',
        warning_2: 'Tem certeza que deseja excluir seu perfil?',
        warning_3: 'esta ação não poderá ser desfeita!',
        excluir_perfil: 'Excluir perfil',
        cliente: {
          titulo: 'Confira os dados da sua empresa – lembre-se: para contratar Creators, todas as informações devem estar preenchidas.',
          empresa: 'Dados da empresa',
          empresa_local: 'Onde sua empresa se localiza?',
          fantasia: 'Nome fantasia',
          logo: 'Logo da empresa - selecione ou arraste',
          responsavel: 'Nome do responsável',
          responsavel_email: 'E-mail do responsável',
          boleto: {
            titulo: 'Boleto bancário',
            txt_1: 'No mesmo dia da contratação aprovada, você receberá um boleto com vencimento em 35 dias, cujo valor engloba a remuneração do(a) profissional + a taxa da Creators.',
            txt_2: 'Para assinantes dos planos Community ou Business, a mensalidade Creators é faturada no dia 20 do mês vigente com vencimento para dia 5 do mês seguinte.',
            txt_3: 'Conheça nossos planos',
            txt_4: 'Consulte mais informações nos',
          },
          plano: 'Plano Creators',
          minha_conta: 'Minha conta Creators',
          email_login: 'E-mail de login',
          excluir_conta: 'Excluir conta',
          excluir_empresa: 'Excluir empresa',
          warning: 'Tem certeza que deseja excluir a sua conta na Creators?',
          warning_empresa: 'Tem certeza que deseja excluir a conta da sua empresa na Creators?',
          mudar_plano: 'Deseja mudar seu plano?',
          mudar_plano_2: 'Fale com a gente!',
          currency: 'Moedas de faturamento (clique para mudar)',
        },
      },
      briefing: {
        confirmar: 'Confirmar contratação de',
        anotacao: 'Creators selecionados',
        previa: 'Prévia do contrato',
        aprovar: 'Aprovar creators',
        total_investimento: 'Total de investimento',
        escolha_pro: 'Selecione os profissionais que deseja'
      },
      company: {
        equipe: 'Equipe',
        add: 'Adicionar usuário(a)',
        contratacoes: 'Contratações',
        plan: 'Plano',
        config: 'Configurações',
        see_history: 'Ver histórico',
        profile: {
          login: 'Logar como',
          remover_user: 'Remover usuário/a',
          remover: 'Remover',
          excluir: 'Tem certeza que deseja remover',
        },
        newmembers: {
          titulo: 'Novos Creators',
          titulo_2: 'Convide um profissional da sua confiança para se tornar Creator',
          encontrar: 'Encontrar Creators',
          filtrar: 'Filtre por habilidade',
          nome: 'Nome',
          ver_perfil: 'Ver perfil',
          adicionar: 'Adicionar',
          remover: 'Remover',
        },
      },
      community: {
        titulo: 'Community',
        notamember: {
          titulo: 'Assine agora os planos Community ou Business e trabalhe com os profissionais melhor avaliados na Creators - além de benefícios exclusivos',
          txt_1: 'Acesso free',
          txt_2: '1 matching/mês com suporte de especialista',
          txt_2_tip: 'Abertura de briefing/curadoria via plataforma com shortlist em 24h. Incluso análise e edição de briefing para garantir que esteja direcionado aos perfis corretos',
          txt_3_tip: 'Pagamento integrado - vencimento 30 dias após aprovação do profissional contratado.',
          txt_4: 'Faturamento integrado',
          txt_4_tip: 'Taxa de intermediação e processamento de pagamento de 15% descontada no input de verba, ou seja, as propostas que você recebe já tem a taxa carregada. Pagamento via boleto Ebanx - vencimento 30 dias após aprovação do profissional contratado.',
          txt_5: 'R$ 3.100/mês',
          txt_5_2: 'Benefícios Plano Open +',
          txt_6: 'Briefings ilimitados',
          txt_6_tip: 'Abertura de briefing/curadoria via plataforma com shortlist em 24h. Incluso análise e edição de briefing para garantir que esteja direcionado aos perfis corretos',
          txt_7: '20 matching/mês com suporte de especialista', // ''5 Recrutamentos específicos/mês',
          txt_7_tip: 'Suporte de especialista em recrutamento de talentos criativos, mapeamento de perfil, shortlist D+2, follows e mediação da contratação. R$ 650 por match adicional.',
          txt_8_tip: 'Crie sua lista de profissionais favoritos para contato direto e contratações futuras',
          txt_9_tip: 'Taxa de intermediação e processamento de pagamento de 15% descontada no input de verba, ou seja, as propostas que você recebe já tem a taxa carregada. Pagamento via boleto Ebanx - vencimento 30 dias após aprovação do profissional contratado.',
          txt_10: 'Faturamento integrado',
          txt_11: 'Sob consulta',
          txt_12: 'Recrutamentos específicos ilimitados',
          txt_12_tip: 'Suporte de especialista em recrutamento de talentos criativos, mapeamento de perfil, shortlist D+2, follows e mediação da contratação.',
          txt_13: 'Faturamento Personalizado',
          txt_novo_1: "Suporte do ínicio ao <br> fim de cada contrato",
          txt_novo_1_tip: 'Ritual único para conectar e humanizar contratante e criador em torno da missão a ser desenvolvida. Se você não estiver 100% satisfeito com o match, forneceremos outro sem custo adicional',
          txt_novo_2: 'Contrato Inteligente',
          assinar: 'Quero assinar',
        },
      },
      ver_projeto: 'Ver projeto',
    },
    newbriefing: {
      type: 'Que tipo de talento você procura?',
      creator: 'Crie conversas autênticas com seu público através de squads de influenciadores de acordo com seus objetivos de comunicação.',
      freelancer: 'Contrate talentos independentes para seus projetos de comunicação. Designers, Copywriters, Motions, Strategists, 3Ds, Illustrators e + de 40 especialidades da economia criativa.',
      title: 'Vamos encontrar o Creator ideal para seu projeto? - Defina sua busca',
      about: 'Sobre o projeto',
      project: {
        title: 'Título do projeto (exemplo: Aumentar Engajamento da Comunidade)',
        predescription: 'Conte sobre seu projeto e as expectativas com a entrega do/a Creator que está buscando. <strong>Inspire. Engage. Especifique. Comprometa.</strong>',
        description: 'Conte sobre seu projeto e as expectativas com a entrega do/a Creator que está buscando.<br>Inspire. Engage. Especifique. Comprometa.',
        placeholder: 'Exemplo: A CREATORS é a rede mais confiável de criadores. E queremos fortalecer o espírito de comunidade através de conteúdos relevantes. Para isso procuramos uma copywriter apaixonada por comunidades para criar 2 artigos semanais de 800 palavras/cada.',
        changesCompleted: 'Alterações Realizadas com Sucesso',
      },
      files: 'Selecione ou arraste anexos do briefing',
      profile: 'Perfil do/a Creator',
      mainspeciality: 'Qual deve ser a principal habilidade deste Creator?',
      specialities: 'Selecione uma habilidade principal e outras 3 tags complementares ou aspectos pessoais',
      about_hiring: 'Sobre a contratação',
      budget: 'Confirme sua verba para esta contratação.',
      sugested: 'Budget recomendado',
      worktype: {
        title: 'Formato',
        inloco: 'Presencial',
        remote: 'Remoto',
        mixed: 'Híbrido',
      },
      address: 'Endereço',
      modal: {
        title: 'Oops! Vamos por partes?',
        text: 'Antes de enviar um briefing e contratar Creators, complete todos os dados do perfil da sua empresa.',
        close: 'Fechar',
        edit: 'Completar',
        exceeded: 'sua empresa excedeu o limite de 1 briefing aberto na Creators este mês. Assine agora um de nossos planos e amplie seu acesso a rede Creators. Se desejar seguir no plano OPEN, aguarde a virada do mês para abrir novos briefings ou adquira um crédito de R$ 650 para abrir um novo briefing. Contato: hello@creators.llc',
        plans: 'Conhecer planos',
      },
      send: 'Enviar',
      sending: 'Enviando',
    },
    professional_card: {
      view: 'Ver perfil',
      select: 'Selecionar',
    },
    chat: {
      info: 'Garantias para contratos firmados através da CREATORS: Suporte 24/7, Contrato e Pagamento Seguros via Plataforma, Suporte Jurídico e <a href="/documents/Termos_de_Uso_Creators.pdf" target="_blank" style="text-decoration: underline;">Código de Conduta</a> livre de riscos.',
    }
  },

  // ENGLISH
  en: {
    home: {
      hero: {
        title: 'A Powerful Influence Marketing Platform',
        title_mobile: 'A Powerful Influence Marketing Platform',
        subtitle: 'Boost communication results with Squads that connect Content Creators and a curated net of Creative Freelancers.',
        btn1: 'schedule demo',
        btn2: 'Login',
        btn3: 'View Demo',
      },
      be: 'Be',
      hire: 'Hire',
      demonstration: {
        title: 'We connect creators with brands who want to generate authentic conversations with their communities.',
        title1: 'Perfect Match',
        text1: 'Obsessive curatorship and smart algorithms to connect talents with communication projects, according to their passions and abilities.',
        title2: 'Creators Squads',
        text2: 'A Talent, a Squad or a Community? We combine Creators, Designers, Copywriters, Motions, Strategists - and all needs for each mission.',
        title3: 'Support end-to-end',
        text3: 'Mediation and humanized support from start to finish of each project - From the briefing to the results report.',
        title4: 'No red tape',
        text4: 'Secure payments and contracts via platform with protection for both. Simple and unified approval and billing.',
      },
      cards: {
        title: 'Matchmaking that empowers unique creators and admired brands',
      },
      product: {
        be: 'For Creators',
        title1: 'Be part of the CREATORS network. The safest way to access briefs and collaborate with global brands. When selected, accept or decline projects and control their flow without penalty.',
        hire: 'For hirers',
        title2: 'Access to a selected network with a satisfaction guarantee. Find the perfect talent or create Squads, which leverage communication results.',
      },
      video: {
        title: 'A trusted creative net',
      },
      testimonials: {
        title: 'We empower Creators thru matchmaking',
      },
      tags: {
        title: 'Excellence in many fields of the creative industry',
      },
      professionals: {
        title: 'End-to-end support at matchmaking',
      },
      clients: {
        title: 'Approved by influential market players',
        who: 'Who believes in us',
        approved: 'Approved by:',
      },
      footer: {
        terms: 'Terms and conditions and Privacy policy',
        privacy: 'Privacy policy',
      },
      login: {
        send_2: 'Submit',
        error_1: 'Incorrect credentials',
        error_2: 'Field required',
        error_3: 'Invalid email',
        error_4: 'Email not registered',
        error_5: 'Email already registered',
        error_6: 'The password must contain at least 6 characters',
        error_7: 'Invalid CNPJ',
        error_8: 'CNPJ already registered',
        label_email: 'Email',
        label_senha: 'Password',
        label_name: 'Your name',
        label_first_name: 'First name',
        label_phone: 'Phone number with area code (DDD)',
        label_phone_2: 'Your phone number with area code (DDD)',
        label_codigo_curador: 'Curator code (optional)',
        label_p_habilidade: 'Your main skill (options are in English)',
        label_habilidades: 'Your additional skills - choose up to 5 skills',
        label_company: 'Company name',
        label_linkedin: 'Linkedin',
        label_site: 'Website',
        label_instagram: 'Instagram',
        label_behance: 'Behance',
        label_vimeo: 'Vimeo',
        label_youtube: 'YouTube',
        label_cnpj: 'CNPJ', //NEED TO VERIFY IF IS NEEDED
        phone_error: 'Invalid number',
        forgot_pass: 'Forgot your password?',
        forgot_pass_2: 'Can’t remember your password? Don’t worry, we will create a new one',
        cadastro: 'Not registered yet?',
        new_pass: 'Define your new password',
        new_pass_2: 'New password',
        confirme: 'Confirm your registered email',
        be: {
          txt_1: 'Be part of the CREATORS. The safest way to access briefs and collaborate with global brands.',
          txt_2: 'The safest way to access briefs and collaborate with global brands.',
          txt_3: 'Access directly with',
          txt_4: 'or enter',
          txt_5: 'Tell us more about yourself!',
          features: '<p><strong>Perfect Match</strong><br>Create a complete profile and let our curators assess your talents. When your profile is pre-selected for a brief - accept, decline, or make a counteroffer.</p><p><strong>No red tape</strong><br>Secure payments and contracts via the platform with protection for both and at no cost to you</p><p><strong>Support end-to-end</strong><br>Mediation and Humanized support throughout the process.</p><p><strong>Safe proposal</strong><br>In addition to receiving invitations to participate in our curatorships, you can send proposals to your clients and guarantee our intermediation free of risk.</p>',
          summary: 'Rest assured: you can also send proposals to your customers <br>through CREATORS :)',
        },
        termos_1: 'I have read and agree with the',
        termos_2: 'Terms of Use',
        termos_error: 'You must agree with the terms of use to continue without registering',
        continuar: 'Continue',
        cadastrar: 'Register',
        hire: {
          txt_1: 'Hire the perfect Creator or create Squads. We connect creators with brands who want to create authentic conversations with their communities.',
          txt_2: 'We connect creators with brands that need to scale their communication and create conversations with their communities.',
          features: '<p><strong>Perfect Match</strong><br>Access to a curated network with  guarantee of satisfaction. Find the perfect talent or create squads, which leverage communication results</p><p><strong>No red tape</strong><br>Secure payments and contracts via the platform with protection for both parties.</p><p><strong>Support end-to-end</strong><br>Count on our mediation and Humanized support from the briefing to the results report.</p><p><strong>Safe proposal</strong><br>Have you found the talent you need? Consolidate through CREATORS and count on our risk-free intermediation.</p>',
        }
      },
    },
    dash: {
      activities: 'Activities',
      notification: 'Notifications',
      messages: 'Messages',
      load_more: 'Show more',
      initialmsg: 'We connect creators with outstanding brands who need to scale up their communication and create authentic conversations with their communities.',
      proposals: 'Proposals',
      progress_project: 'Projects in progress',
      received_proposals: 'Proposals received',
      received_proposals_s: 'Proposal received',
      accepted_proposals: 'Proposals accepted',
      proposta: 'Approved proposal',
      complete_profile: 'Complete your profile',
      edit_profile: 'Edit your profile',
      enviar: 'Send proposal',
      send: 'Send',
      selecao: 'Selection in progress',
      encerrada: 'Selection ended',
      notyou: 'Unfortunately you were not selected. If you have submitted a proposal, thank you for participating in this selection, good luck in the next ones.',
      aprovacao: 'Briefing under approval',
      curadoria: 'Curatorship in progress',
      escolha: 'Choose a proposal',
      contratacao: 'Hiring in progress',
      contratacao_ended: 'Hiring completed',
      cancelada: 'Canceled',
      cancelar: 'Cancel',
      salvar: 'Save',
      encerrada: 'Closed',
      entrega: 'Delivery',
      verba: 'Budget',
      evaluation_error: "An error has occurred in the evaluation. Try again later.",
      my_projects: 'My projects',
      card_client: {
        txt_1: 'Work now with some of the most renowned creators',
        txt_2: 'in the market',
      },
      iniciar_b: 'Start briefing',
      add_user: 'Add user',
      add_modal: 'Add new users – your team and colleagues can also access your Creators account!',
      full_name: 'Full name',
      email: 'Email',
      convite: 'Send invitation',
      modal_alert_1: 'Oops! Not so fast, ok?',
      modal_alert: 'Before you send a briefing and hire Creators, complete your company’s profile.',
      modal_alert_3: 'You need to complete some information before choosing a professional.',
      modal_alert_4: 'You need to complete your financial information before sending your proposal.',
      close: 'Close',
      complete: 'Edit profile',
      page: 'Page',
      de: 'of',
      previous: 'PREVIOUS',
      next: 'NEXT',
      avaliacao: 'Evaluation performed',
      razao: 'Corporate name',
      cliente: 'Client',
      projeto: 'Project',
      com: 'with',
      conexoes: 'Connections',
      profile: 'Profile',
      renovar: 'Renew contract',
      renovar_txt: 'You can renew the contract by specifying the dates and telling a little more about the project.',
      d_inicio: 'Start',
      d_termino: 'End',
      valor_adicional: 'Additional value R$',
      descricao: 'Describe the project a little bit',
      avalie: 'Evaluate the Creator',
      avalie_empresa: 'Evaluate the company',
      experiencia: 'How was your experience?',
      sua_avaliacao: 'Your evaluation:',
      avaliar: 'Evaluate',
      deu_errado: {
        errado: 'What went wrong?',
        comunicacao: 'Communication',
        pontualidade: 'Punctuality',
        qualidade: 'Quality',
      },
      status: 'Status',
      pagamento: 'Payment',
      valor: 'Value',
      nota: 'Upload invoice',
      n_arquivo: 'File name',
      vencimento: 'Due date',
      anexo_b: 'Select or drag attachments from the briefing',
      boleto: 'Access payment slip',
      pagamento_1: 'In progress',
      pagamento_3: 'Completed',
      contrato: 'Contract',
      para: 'for',
      cancelar_contrato: 'Cancel contract',
      visualizar_contrato: 'View my contract',
      alert_contrato: 'Are you sure you want to cancel the contract with',
      alert_contrato_2: 'This action can’t be undone',
      dados: 'Required information',
      selecionar_c: 'Select Creator',
      selecionar_renewal: 'Approve Creator',
      b_aprovado: 'Briefing approved',
      empresa: 'Company',
      usuario: 'User',
      equipamento: {
        equipamento: 'Equipment',
        proprio: 'Own equipment',
        fornecido: 'Supplied equipment',
        alugado: 'Rented equipment',
        equipamento_p: 'Own equipment',
        equipamento_f: 'Equipment will be provided',
        equipamento_a: 'I wish to rent discounted equipment (SP and POA)',
      },
      n_divulgado: 'Not disclosed',
      inicio: 'Start',
      termino: 'End',
      software: 'Specific software',
      tipo_acesso: {
        tipo: 'Access type',
        email: 'Email',
        sede: 'Headquarters',
      },
      anexos: 'Attachments',
      habilidades: 'Skills',
      enviado: 'sent',
      recebido: 'received',
      profile_2: {
        since: 'Registration on',
        creator_since: 'Creator since',
        area_trans: 'Copied to clipboard',
        area_trans_tip: 'Click the link to copy to clipboard',
        professional_background_edit_tip: 'Upload an image for your background with 1920 x 1080 dimensions in JPG or GIF.',
        professional_link_edit_tip: 'This link is the public URL of your Creators profile. This is how clients view your profile through our platform. Feel free to share this page and use it as a portfolio.',
        professional_community_tip: 'This number shows how many clients added you as a favourite.',
        added_community: 'Member added to your Community',
        removed_community: 'Member removed from your Community',
        add_to_community: 'Add to your Community',
        remove_from_community: 'Remove from your Community',
        project_participation: 'Participation in your projects:',
        carregando: 'loading...',
        habilidade: 'What is your main skill?',
        habilidades: 'Choose other additional skills (up to 5)',
        editar: 'Edit',
        excluir: 'Delete',
        up_image: 'Add or drag images in: JPEG, PNG or GIF of up to 5MB',
        project_title: 'Project title',
        project_desc: 'Brief project description',
        publish_projects_tip: "Publish your best projects and increase your chances of approval.",
        publish_posts_tip: "Publish your best posts on Instagram and increase your chances of approval.",
      },
      select: 'Select',
      pf: 'Individual',
      pj: 'Legal entity',
      titular: 'Name of holder',
      banco: 'Bank',
      agencia: 'Branch',
      conta: 'Account',
      b_aprovacao: 'Proposal under approval',
      b_enviado: 'Proposal was sent',
      b_rejeitado: 'Proposal rejected',
      m_ideia: 'I changed my mind',
      b_send_proposal: 'Submit your proposal',
      b_minha_proposta: 'My proposal for this project is:',
      b_minha_proposta_2: 'Place a value on the proposal',
      b_minha_proposta_3: 'Yes, I am interested and available for the requested period',
      visualizar: 'View',
      recusar: 'Refuse',
      aceitar: 'Accept',
      historico: {
        titulo: 'History',
        total: 'Total hirings',
        total_movi: 'Total handled',
        ticket: 'Average ticket',
        finalizados: 'Projects concluded',
      },
      settings: {
        titulo: 'To ensure you connect to the right jobs, complete your profile by adding as much information as you can, and remember: use objective tags and write an awesome mini-bio!',
        habilidade: 'Define your main skill – what do you do best?',
        habilidades: 'Choose up to five additional skills – what are the other talents you can demonstrate?',
        perfil: 'Profile',
        foto: 'Choose a profile photo or drag it here',
        minibio: 'Mini-bio – a brief text introducing you',
        dados: 'Personal information',
        nome: 'First name',
        sobrenome: 'Last name',
        phone: 'Phone number',
        nascimento: 'Date of birth',
        cep: 'CEP (Zip Code)',
        country: 'Country',
        state: 'State',
        city: 'City',
        logradouro: 'Adress',
        numero: 'Number',
        complemento: 'Complement',
        dados_bancarios: 'Bank information',
        excluir: 'Delete profile',
        logout: 'Log out',
        salvando: 'Saving',
        warning: 'Attention',
        warning_2: 'Are you sure you want to delete your profile?',
        warning_3: 'this action cannot be undone!',
        cliente: {
          titulo: 'Check out your company information and remember: to hire Creators, all information must be completed.',
          empresa: 'Company information',
          empresa_local: 'Where is your company?',
          fantasia: 'Fictitious name',
          logo: 'Company logo - select or drag a file',
          responsavel: 'Name of person in charge',
          responsavel_email: 'E-mail of person in charge',
          boleto: {
            titulo: 'Bank slip (boleto)',
            txt_1: 'On the same day the hiring is approved, you will receive a bank slip (boleto) due in 35 days. Its amount includes compensation of the professional + Creators fee.',
            txt_2: 'For subscribers of the Community or Business plan, the Creators monthly fee is billed on the 20th of the current month, due on the 5th of the following month. Learn about our plans.',
            txt_3: 'know about our plans',
            txt_4: 'For more information, refer to the',
          },
          plano: 'Creators Plan',
          minha_conta: 'Admin of the Creators account',
          email_login: 'Login email',
          excluir_conta: 'Delete account',
          excluir_empresa: 'Remove company',
          warning: 'Are you sure you want to delete your Creators account?',
          warning_empresa: "Are you sure you want to delete your company's Creators account?",
          mudar_plano: 'Do you want to change your plan?',
          mudar_plano_2: 'Talk to us!',
          currency: 'Billing currencies (click to change)',
        },
      },
      briefing: {
        confirmar: 'Confirm hiring of',
        anotacao: 'Selected Creators',
        previa: 'Contract preview',
        aprovar: 'Approve',
        total_investimento: 'Total investment',
        escolha_pro: 'Select the professionals you want',
      },
      company: {
        equipe: 'Team',
        add: 'Add user',
        contratacoes: 'Hirings',
        plan: 'Plan',
        config: 'Settings',
        see_history: 'View history',
        profile: {
          login: 'Login as',
          remover_user: 'Remove user',
          remover: 'Remove',
          excluir: 'Are you sure you want to remove?',
        },
        newmembers: {
          titulo: 'New Creators',
          titulo_2: 'Invite a professional you trust to become a Creator',
          encontrar: 'Find Creators',
          filtrar: 'Filter by skill',
          nome: 'Name',
          ver_perfil: 'See profile',
          adicionar: 'Add',
          remover: 'Remove',
        },
      },
      community: {
        titulo: 'Community',
        notamember: {
          titulo: 'Subscribe now to the Community or Business plans to work with Creators top professionals and enjoy exclusive benefits.',
          txt_1: 'Free access',
          txt_2: '1 shortlists/month',
          txt_2_tip: 'Abertura de briefing/curadoria via plataforma com shortlist em 24h. Incluso análise e edição de briefing para garantir que esteja direcionado aos perfis corretos',
          txt_3_tip: 'Pagamento integrado - vencimento 30 dias após aprovação do profissional contratado.',
          txt_4: '15% fee',
          txt_4_tip: 'Taxa de curadoria, intermediação e processamento de pagamento. A taxa é descontada no input de verba, ou seja, a proposta final que você recebe do profissional já tem a taxa carregada.',
          txt_5: 'U$350/month',
          txt_6: 'Unlimited shortlists',
          txt_6_tip: 'Abertura de briefing/curadoria via plataforma com shortlist em 24h. Incluso análise e edição de briefing para garantir que esteja direcionado aos perfis corretos.',
          txt_7: '20 recruiting sessions',
          txt_7_tip: 'Suporte de especialistas em recrutamento de talentos criativos, mapeamento de perfil, shortlist D+2, follows e mediação da contratação.',
          txt_8_tip: 'Crie sua lista de profissionais favoritos para contato direto e contratações futuras.',
          txt_9_tip: 'Pagamento integrado - vencimento 30 dias após aprovação do profissional contratado.',
          txt_10: '15% fee',
          txt_11: 'Contact us',
          txt_12: 'unlimited recruiting sessions',
          txt_12_tip: 'Suporte de especialistas em recrutamento de talentos criativos, mapeamento de perfil, shortlist D+1, follows e mediação da contratação.',
          txt_13: '25% fee',
          txt_novo_1: 'Mediation and Support <br> from end-to-end',
          txt_novo_1_tip: 'Unique ritual to connect and humanize contractor and creator around the mission to be developed. If you are not 100% satisfied with the match, we will provide another at no additional cost.',
          txt_novo_2: 'Smart Contract',
          assinar: 'I want to subscribe',
        },
      },
      ver_projeto: 'View project',
    },
    newbriefing: {
      type: 'What kind of talent are you looking for?',
      creator: 'Create authentic conversations with your audience through squads of influencers according with your communication goals.',
      freelancer: 'Hire independent talent for your communication projects. Designers, Copywriters, Motions, Strategists, 3Ds, Illustrators and 40+ creative economy specialties.',
      title: 'Let’s find the ideal creator for your project! Define your search',
      about: 'About the project',
      project: {
        title: 'Project title. Exemple: To increase the Community Engagement. ',
        predescription: 'Tell us about your project and expectations with the delivery of the Creator you are looking for. <strong>Inspire. Engage. Specify. Commit.</strong>',
        description: 'Tell us about the project and your expectations about the Creator you’re looking for. Inspire. Engage. Please be specific and Commit.',
        placeholder: 'Example: CREATORS is the most trusted network of creators. And we want to strengthen the community spirit through relevant content. For this we look for a copywriter passionate about communities to create 2 weekly articles of 800 words / each.',
        changesCompleted: 'Sucessfully applied changes'
      },
      files: 'Select or drag briefing attachments',
      profile: 'About the professional you need.',
      mainspeciality: 'What should be the Creator’s main skill?',
      specialities: 'Select other 3 additional tags or personal aspects',
      about_hiring: 'About the hiring',
      budget: 'What is your budget for this hiring?',
      sugested: 'Recommended budget',
      worktype: {
        title: 'Format',
        inloco: 'In-house',
        remote: 'Remote',
        mixed: 'Mixed',
      },
      address: 'Address',
      modal: {
        title: 'Oops! Not so fast, ok?',
        text: 'Before you send a briefing and hire Creators, complete your company’s profile.',
        close: 'Close',
        edit: 'Edit profile',
        exceeded: 'your company has exceeded the limit of 1 open briefings at Creators this month. Sign up for one of our plans now and get unlimited access to the Creators platform in addition to exclusive benefits! If you want to follow the OPEN plan, wait for the turn of the month to open new briefings.',
        plans: 'Conhecer planos',
      },
      send: 'Submit',
      sending: 'Submitting',
    },
    professional_card: {
      view: 'See profile',
      select: 'Select',
    },
    chat: {
      info: 'Complete a contract through CREATORS and guarantee 24-hour Support, Secure Contract and Payment via Platform, Legal Support and risk-free <a href="/documents/Termos_de_Uso_Creators.pdf" target="_blank" style="text-decoration: underline;">Code of Conduct</a>.',
    }
  }
}

export default new VueI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages,
});
