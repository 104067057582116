<template>
  <div class="c-card-project-selection-time">

    <!-- Card project -->
    <div class="card-white rounded card-project">
      <h5>Curadoria em andamento</h5>
      <h3>Arquitetura da informação</h3>
      <div class="tag-time">
        <h3 id="timer"></h3>
      </div>
      <div class="column-2">
        <p class="large">Entrega</p>
        <p class="large">Verba</p>
      </div>
      <div class="column-2">
        <h5 class="grey">28/11/2019</h5>
        <h5 class="purple">R$ 16.000,00</h5>
      </div>
      <a href="" class="btn btn-primary sm rounded d-lg-none">Ver projeto</a>
    </div>


  </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
