<template>
  <div class="c-card-client-profile">

    <!-- Card client profile -->
    <div class="card-white rounded card-project bigger">
      <div class="d-lg-flex">
        <div class="p-0 col-lg-2">
          <div class="box-thumb-profile ml-0 p-0 position-relative">
            <img v-if="company.logo" :src="'/' + company.logo" alt="Card image" class="thumb-profile">
            <img v-else src="/images/avatar.jpg" class="thumb-profile">
            <router-link to="/dashboard/client/settings" class="btn-icons primary edit-btn" v-if="!admin">
              <i class="ico-edit-light"></i>
            </router-link>
          </div>
        </div>
        <!-- <div class="text-center mb-3 d-lg-none d-block">
          <div class="d-inline-block">
            <i class="ico-star"></i>
          </div>
          <div class="d-inline-block">
            <h5 class=""> 5.0</h5>
          </div>
        </div> -->
        <div class="col-12 col-lg-10 d-flex flex-wrap">

          <div class="order-2 order-lg-1 col-lg-5 col-6 pl-0">
            <h5>{{ $t("dash.profile_2.since") }}</h5>
            <p class="pb-3">{{ formatDate(company.created_at) }}</p>
            <h5>{{ $t("newbriefing.address") }}</h5>
            <div class="pb-3" v-if="company.address && company.address[0]">
              <p>{{ company.address[0].street }}, {{ company.address[0].number }} {{ company.address[0].complement }}</p>
              <p>{{ company.address[0].city ? company.address[0].city.name : '' }}, Brasil</p>
              <p>{{ company.address[0].cep }}</p>
            </div>
            <h5>Site</h5>
            <a :href="company.site" target="_blank"><p class="underline pb-3">{{ company.site }}</p></a>
          </div>
          <div class="order-3 order-lg-2 col-lg-4 col-6 pr-0">
            <h5>CNPJ</h5>
            <p class="pb-3">{{ company.cnpj }}</p>
            <h5>Phone</h5>
            <p class="pb-3">{{ company.phone }}</p>
            <h5>{{ $t("dash.company.contratacoes") }}</h5>
            <p class="pb-3">{{ (company.hirings) ? company.hirings.length : '' }}</p>
          </div>
          <div class="order-1 order-lg-3 d-none d-lg-block col-lg-3">

            <div class="row">
              <div class="col-12">

                <div v-if="admin">
                  <h5 class="pb-3 pr-3 float-left">{{ $t("dash.community.titulo") }}</h5>
                  <label class="switch float-right" for="check-community">
                    <input type="checkbox" id="check-community" v-model="company.community_flag" @click="changeCommunity"/>
                    <div class="slider round"></div>
                  </label>
                </div>

                <div v-else>
                  <h5 class="pr-3">{{ $t("dash.company.plan") }}</h5>
                  <p v-if="company.plan == 1" class="pb-3 pr-3">Open</p>
                  <p v-if="company.plan == 2" class="pb-3 pr-3">Community</p>
                  <p v-if="company.plan == 3 || company.plan == 6" class="pb-3 pr-3">Enterprise</p>
                </div>
                <!-- <div class="d-inline-block float-right">
                  <i class="ico-star mt-0"></i>
                </div>
                <div class="d-inline-block float-right">
                  <h5 class="mt-1 ml-2"> 5.0</h5>
                </div> -->

              </div>
            </div>

            <div class="row">
              <div class="col-12">

                <div v-if="admin" class="mt-3">
                  <h5 class="py-3">Plan <span v-if="company.saved">(salvo)</span></h5>
                  <select class="px-2 py-1 form-control" v-model="company.plan" @change="changePlan">
                    <option value="1">Open</option>
                    <option value="2">Community</option>
                    <option value="3">Business - 25%</option>
                    <option value="6">Business - 30%</option>
                    <option value="5">0% Fee</option>
                  </select>
                </div>

              </div>
            </div>


          </div>

        </div>
        <div v-if="!admin" class="d-lg-none">
          <router-link :to="'/dashboard/client/settings/'" class="btn btn-primary mb-3">{{ $t("dash.company.config") }}</router-link>
          <router-link :to="'/dashboard/client/historic/'"><div class="purple underline text-center">{{ $t("dash.company.see_history") }}</div></router-link>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: ['company', 'admin'],
    mounted() {
      console.log('Component mounted.')
    },
    methods: {
      formatDate: function (date) {
        return moment(date).format('DD/MM/YYYY');
      },

      changeCommunity() {
        this.$emit('changeCommunity');
      },

      changePlan() {
        console.log(this.company.plan);
        this.$emit('changePlan');
      },

    }
  }
</script>

<style src="./card-client-profile.scss" lang="scss"></style>
