<template>
  <div class="c-b-evaluation">
    <div class="wrapper-timeline b-evaluation">
      <div class="progress-box">
        <p>{{ date }}</p>
        <h2>{{ $t("dash.avaliacao") }}</h2>
      </div>
      <div class="card-white rounded your-evaluation-box">
        <h3 class="box-padding">{{ $t("dash.sua_avaliacao") }}</h3>
        <div class="evaluation-stars box-padding">
          <div class="rating-briefing my-2">
            <i
              class="ico-rating-evaluated"
              :class="{ 'evaluated-checked': review.total > 0 }"
            ></i>
            <i
              class="ico-rating-evaluated"
              :class="{ 'evaluated-checked': review.total > 1 }"
            ></i>
            <i
              class="ico-rating-evaluated"
              :class="{ 'evaluated-checked': review.total > 2 }"
            ></i>
            <i
              class="ico-rating-evaluated"
              :class="{ 'evaluated-checked': review.total > 3 }"
            ></i>
            <i
              class="ico-rating-evaluated"
              :class="{ 'evaluated-checked': review.total > 4 }"
            ></i>
          </div>
        </div>
        <div class="evaluation-bad box-padding" v-if="review.total < 4">
          <h3>{{ $t("dash.deu_errado.errado") }}</h3>
          <div class="problems-box">
            <p
              class="tag mr-lg-3 default-cursor"
              :class="{ selected: review.communication < 5 }"
            >
              {{ $t("dash.deu_errado.comunicacao") }}
            </p>
            <p
              class="tag mr-lg-3 default-cursor"
              :class="{ selected: review.ponctuality < 5 }"
            >
              {{ $t("dash.deu_errado.pontualidade") }}
            </p>
            <p
              class="tag mr-lg-3 default-cursor"
              :class="{ selected: review.quality < 5 }"
            >
              {{ $t("dash.deu_errado.qualidade") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    date: {
      type: String,
      default: moment().format("DD/MM/YYYY"),
    },
    review: {
      type: Object,
      default: {
        total: 5,
        communication: 5,
        ponctuality: 5,
        quality: 5,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.your-evaluation-box {
  display: flex;
  flex-direction: column;
  .evaluation-stars {
    display: flex;
    justify-content: flex-start;
  }
  .evaluation-bad {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .problems-box {
      display: flex;
      width: 100%;
    }
  }
}

.box-padding {
  padding: 0 16px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
}
</style>
