import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const params = location.search.slice(1).split('&').reduce((acc, s) => {
  const [k, v] = s.split('=')
  return Object.assign(acc, {[k]: v})
}, {})

if (params.me) {
  localStorage.setItem('role', params.role)
  localStorage.setItem('token', params.token)
  localStorage.setItem('me', decodeURIComponent(params.me))
}

export default new Vuex.Store({

  state: {
    //
    status: '',
    role: localStorage.getItem('role'),
    token: localStorage.getItem('token'),
    me: JSON.parse(localStorage.getItem('me') || '{}'),
    company: (localStorage.getItem('company') != 'undefined') ? JSON.parse(localStorage.getItem('company') || '{}') : '{}',
    locale: localStorage.getItem('locale') || 'pt',
    notifications: [],
    currencies: {
      eur: 5.20,
      usd: 4.80,
    }
  },

  getters: {
    avatar: state => {
      return (state.me.avatar) ? '/' + state.me.avatar : '/images/avatar.jpg';
    },
    auth: state => {
      return state.token != '';
    }
  },

  mutations: {
    //
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    },
    setMe(state, user) {
      // save user
      state.me = user;
      localStorage.setItem('me', JSON.stringify(user));
      // save role
      if (user == '') state.role = '';
      if (user.admin) state.role = 'admin';
      if (!user.admin && user.company_id) state.role = 'client';
      if (!user.admin && !user.company_id) state.role = 'professional';
      localStorage.setItem('role', state.role);
    },
    setCompany(state, company) {
      // save user
      state.company = company;
      localStorage.setItem('company', JSON.stringify(company));
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem('locale', locale);
    },
    //
    async setExchange(state) {
      let exchange = await axios.get('/api/v1/currencies');
      state.currencies.usd = exchange.data.data.USD_BRL;
      state.currencies.eur = exchange.data.data.EUR_BRL;
      return true;
    },
  },

  actions: {
    //
  }

})
