<template>
  <div class="c-b-initial">
    <div class="wrapper-timeline b-initial">
      <!-- <div class="progress-box">
        <p>{{ formatDate(hiring.created_at) }}</p>
        <h2>Briefing #{{ hiring.id }}-{{ formatYear(hiring.created_at) }} {{ title }}</h2>
      </div> -->
      <div class="box-proposal card-white rounded">
        <div class="col-12 mb-lg-3 px-0 px-lg-3">
          <h2>#{{ hiring.id }}-{{ formatYear(hiring.created_at) }}</h2>
        </div>

        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3" v-if="company">
          <h5 class="f-mobile">{{ $t("dash.empresa") }}</h5>
          <p class="txt-briefing">{{ company.nome_fantasia ? company.nome_fantasia : company.razao }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3" v-else>
          <h5 class="f-mobile">{{ $t("dash.empresa") }}</h5>
          <p class="txt-briefing">{{ hiring.company.nome_fantasia ? hiring.company.nome_fantasia : hiring.company.razao }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <h5 class="f-mobile">{{ $t("dash.usuario") }}</h5>
          <p class="txt-briefing">{{ hiring.user.name }} {{ hiring.user.last_name !== 'null' ? hiring.user.last_name : '' }}</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div v-if="!editor.company_computer">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.equipamento.equipamento") }}</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.company_computer = true"></i>
            <p class="txt-briefing" v-if="hiring.company_computer === 0">{{ $t("dash.equipamento.proprio") }}</p>
            <p class="txt-briefing" v-if="hiring.company_computer === 1">{{ $t("dash.equipamento.fornecido") }}</p>
            <p class="txt-briefing" v-if="hiring.company_computer === 2">{{ $t("dash.equipamento.alugado") }}</p>
          </div>
          <div v-else class="form-group has-float-label">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.equipamento.equipamento") }}</h5>
            <i class="icons ico-check d-inline-block" @click="editComputer()"></i>
            <select class="form-control" :id="'equipment'+hiring.id" v-model="hiring.company_computer">
              <option value="no">{{ $t("dash.equipamento.equipamento_p") }}</option>
              <option value="yes">{{ $t("dash.equipamento.equipamento_f") }}</option>
              <option value="maybe">{{ $t("dash.equipamento.equipamento_a") }}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div v-if="!editor.value">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.verba") }}</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.value = true"></i>
            <p class="purple">{{ hiring.reveal_tag ? toCurrency(value) : $t("dash.n_divulgado") }}</p>
          </div>
          <div v-else class="form-group has-float-label">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.verba") }}</h5>
            <i class="icons ico-check d-inline-block" @click="editValue()"></i>
            <input type="text" class="form-control" :id="'budget'+hiring.id" v-model="hiring.value" v-money="money" aria-describedby="emailHelp" placeholder=" ">
          </div>
        </div>

        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div v-if="!editor.date_start">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.inicio") }}</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.date_start = true"></i>
            <p class="txt-briefing">{{ formatDate(hiring.date_start) }}</p>
          </div>
          <div v-else class="form-group has-float-label">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.inicio") }}</h5>
            <i class="icons ico-check d-inline-block" @click="editDateStart()"></i>
            <input type="date" class="form-control form-date" :id="'date-start'+hiring.id" v-model="hiring.date_start" aria-describedby="emailHelp" placeholder=" ">
            <i class="icons ico-calendar-dark pointer-events"></i>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div v-if="!editor.date_end">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.termino") }}</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.date_end = true"></i>
            <p class="txt-briefing">{{ formatDate(hiring.date_end) }}</p>
          </div>
          <div v-else class="form-group has-float-label">
            <h5 class="f-mobile d-inline-block">{{ $t("dash.termino") }}</h5>
            <i class="icons ico-check d-inline-block" @click="editDateEnd()"></i>
            <input type="date" class="form-control form-date" :id="'date-end'+hiring.id" v-model="hiring.date_end" aria-describedby="emailHelp" placeholder=" ">
            <i class="icons ico-calendar-dark pointer-events"></i>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div>
            <h5 class="f-mobile d-inline-block">{{ $t("dash.software") }}</h5>
            <p class="txt-briefing">{{ hiring.acs_software ? hiring.softwareslist : 'Nenhum'}}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-lg-3  px-0 px-lg-3">
          <div>
            <h5 class="f-mobile d-inline-block">{{ $t("dash.tipo_acesso.tipo") }}</h5>
            <p class="txt-briefing" v-if="hiring.acs_email && hiring.acs_physical">{{ $t("dash.tipo_acesso.email") }}. {{ $t("dash.tipo_acesso.sede") }}.</p>
            <p class="txt-briefing" v-else-if="hiring.acs_email">{{ $t("dash.tipo_acesso.email") }}.</p>
            <p class="txt-briefing" v-else-if="hiring.acs_physical">{{ $t("dash.tipo_acesso.sede") }}.</p>
          </div>
        </div>

        <div class="row w-100">
          <div class="col-lg-3" v-if="!editor.brand">
            <h5 class="d-inline">Marca do cliente</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.brand = true"></i>
            <p>{{ hiring.brand }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Marca do cliente</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.brand, 'brand')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.brand" placeholder=" "></div>
          </div>

          <div class="col-lg-3" v-if="!editor.audience">
            <h5 class="d-inline">Audiência/público</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.audience = true"></i>
            <p>{{ hiring.audience }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Audiência/público</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.audience, 'audience')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.audience" placeholder=" "></div>
          </div>

          <div class="col-lg-3" v-if="!editor.channels">
            <h5 class="d-inline">Canais preferenciais</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.channels = true"></i>
            <p>{{ hiring.channels }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Canais preferenciais</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.channels, 'channels')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.channels" placeholder=" "></div>
          </div>

          <div class="col-lg-3" v-if="!editor.team">
            <h5 class="d-inline">Número de criadores</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.team = true"></i>
            <p>{{ hiring.team }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Número de criadores</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.team, 'team')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.team" placeholder=" "></div>
          </div>

          <div class="col-lg-3" v-if="!editor.size">
            <h5 class="d-inline">Porte dos criadores</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.size = true"></i>
            <p>{{ hiring.size }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Porte dos criadores</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.size, 'size')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.size" placeholder=" "></div>
          </div>

          <div class="col-lg-3" v-if="!editor.frequency">
            <h5 class="d-inline">Frequência de postagem</h5>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.frequency = true"></i>
            <p>{{ hiring.frequency }}</p>
          </div>
          <div class="col-lg-3" v-else>
            <h5 class="d-inline">Frequência de postagem</h5>
            <i class="icons ico-check d-inline-block" @click="update(hiring.frequency, 'frequency')"></i>
            <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.frequency" placeholder=" "></div>
          </div>

        </div>

        <div class="col-lg-6 px-0 px-lg-3">
          <div v-if="!editor.tasks">
            <h3 class="d-inline-block">Briefing</h3>
            <i v-if="owner && hiring.status <= 3" class="ico-edit-dark" @click="editor.tasks = true"></i>
            <p class="black" v-html="hiringTasks"></p>
          </div>
          <div v-else class="form-group has-float-label">
            <h3 class="d-inline-block">Briefing</h3>
            <i class="icons ico-check d-inline-block" @click="editTasks()"></i>
            <textarea name="name" v-model="hiring.tasks" :id="'tasks'+hiring.id" class="form-control" rows="5" cols="80" placeholder=" "></textarea>
          </div>
        </div>

        <!-- TODO: Arquivos do brief -->
        <div class="col-lg-6  px-0 px-lg-3" v-if="hiring.files && hiring.files.length">
          <h3>{{ $t("dash.anexos") }}</h3>
          <div class="d-flex flex-wrap btn-anexos">
            <a v-for="file in hiring.files" :key="file.name" :href="file.url" class="btn btn-outline-primary sm btn-center-icons" target="_blank">
              <i class="icons ico-file"></i> {{ file.name }}
            </a>
          </div>
        </div>

        <div class="col-lg-6 px-0 px-lg-3 mt-4 pt-1 mt-lg-0 pt-lg-0">
          <div v-if="!editor.specialities">
            <div>
              <h3 class="d-inline-block">{{ $t("dash.habilidades") }}</h3>
              <i v-if="owner && hiring.status < 2" class="ico-edit-dark" @click="editor.specialities = true"></i>
            </div>
            <p class="tag mr-lg-2 mr-1 mb-3" v-for="speciality in hiring.specialities" :key="speciality.pt">{{ speciality.pt }}</p>
          </div>
          <div v-else class="form-group has-float-label">
            <h3 class="d-inline-block">{{ $t("dash.habilidades") }}</h3>
            <i class="icons ico-check d-inline-block" @click="editSpecialities()"></i>
            <input
              type="text"
              class="form-control"
              id="complementares"
              placeholder=" "
              onkeyup="this.setAttribute('value', this.value);"
              list="complementares-list"
              autocomplete="off"
              v-model.lazy="formSpecialities.specialitiesInput"
              @change="validateListComplementares"
            />
            <datalist id="complementares-list">
              <option v-for="speciality in specialities">{{ speciality.pt }}</option>
            </datalist>
            <div class="invalid-feedback d-block" v-if="errors.specialities && errors.specialities.length">
              <small>{{ errors.specialities[0] }}</small>
            </div>
            <div class="my-3">
              <span class="tag mr-lg-2 mr-1 mb-3" v-for="s in formSpecialities.specialities">
                {{ s.pt }}
                <i class="ico-close grey" v-on:click="removeSpeciality(s)"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 px-0 px-lg-3 mt-4 pt-1 mt-lg-0 pt-lg-0">
          <!--  -->
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ['hiring', 'value', 'title', 'specialities', 'owner', 'company'],

    data () {
      return {
        me: this.$store.state.me,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
        },
        editor: {
          date_start: false,
          date_end: false,
          value: false,
          company_computer: false,
          worktype: false,
          tasks: false,
          specialities: false,
          brand: false,
          audience: false,
          channels: false,
          team: false,
          size: false,
          frequency: false,
        },
        formSpecialities: {
          specialities: this.hiring.specialities,
          specialitiesInput: '',
        },
        errors: {
          specialities: [],
        },
      }
    },
    mounted() {
    },

    computed: {
      hiringTasks() {
        return this.hiring.tasks.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
    },

    watch: {
      hiring() {
        this.formSpecialities.specialities = this.hiring.specialities;
      },
    },
    methods: {
      formatDate: function (date) {
        return moment(date).format('DD/MM/YYYY');
      },

      formatYear: function (date) {
        return moment(date).format('YYYY');
      },

      toCurrency(val) {
        let value = val * 1;
        let currency = "BRL";
        if ((this.owner && this.hiring.company.currency == "eur") || (!this.owner && this.me.currency == "eur")) {
          value = val / this.$store.state.currencies.eur;
          currency = "EUR";
        }
        if ((this.owner && this.hiring.company.currency == "usd") || (!this.owner && this.me.currency == "usd")) {
          value = val / this.$store.state.currencies.usd;
          currency = "USD";
        }
        return (value/100).toLocaleString("pt-BR", {style: "currency", currency: currency, minimumFractionDigits: 2});
      },

      update(value, field) {
        this.editor[field] = false;
        var data = {id: this.hiring.id};
        data[field] = value;
        this.$emit('editHiring', data);
      },

      editValue() {
        this.hiring.value = parseInt(this.hiring.value.replace(/\D/g,''));
        this.editor.value = false;
        var data = {id: this.hiring.id, value: this.hiring.value};
        this.$emit('editHiring', data);
      },

      editDateStart() {
        this.editor.date_start = false;
        var data = {id: this.hiring.id, date_start: this.hiring.date_start};
        this.$emit('editHiring', data);
      },

      editDateEnd() {
        this.editor.date_end = false;
        var data = {id: this.hiring.id, date_end: this.hiring.date_end};
        this.$emit('editHiring', data);
      },

      editComputer() {
        this.editor.company_computer = false;
        var data = {id: this.hiring.id, company_computer: this.hiring.company_computer};
        switch (this.hiring.company_computer) {
          case 'no':
            this.hiring.company_computer = 0;
            break;
          case 'yes':
            this.hiring.company_computer = 1;
            break;
          case 'maybe':
            this.hiring.company_computer = 2;
            break;
          default: this.hiring.company_computer = 0;
        }
        this.$emit('editHiring', data);
      },

      editWorktype() {
        this.editor.worktype = false;
        var data = {id: this.hiring.id, worktype: this.hiring.inloco};
        switch (this.hiring.inloco) {
          case 'remoto':
            this.hiring.inloco = 0;
            break;
          case 'inloco':
            this.hiring.inloco = 1;
            break;
          case 'misto':
            this.hiring.inloco = 2;
            break;
          default: this.hiring.inloco = 0;
        }
        this.$emit('editHiring', data);
      },

      editTasks() {
        this.editor.tasks = false;
        var data = {id: this.hiring.id, tasks: this.hiring.tasks};
        this.$emit('editHiring', data);
      },

      editSpecialities() {
        if (this.formSpecialities.specialities.length < 2) {this.errors.specialities.push("Escolha no mínimo 2 habilidades."); return;}
        else this.errors.specialities = [];
        let specialities = [];
        for (let i = 0; i < this.formSpecialities.specialities.length; i++) {
          specialities.push(this.formSpecialities.specialities[i].id);
        }
        var data = {id: this.hiring.id, specialities: specialities};
        this.editor.specialities = false;
        this.hiring.specialities = this.formSpecialities.specialities;
        this.$emit('editHiring', data);
      },

      validateListComplementares: function(e) {
        let text = this.formSpecialities.specialitiesInput;
        let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
        let double = this.formSpecialities.specialities.map(function(e) { return e.pt; }).indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          let selected = this.specialities[index];
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.formSpecialities.specialities.push(selected);
          this.formSpecialities.specialitiesInput = '';
        }
      },
      removeSpeciality: function(s) {
        // remove habilidade do briefing
        let index = this.formSpecialities.specialities.indexOf(s);
        if (index > -1) {
          this.formSpecialities.specialities.splice(index, 1);
        }
      },
    },
  }
</script>

<!-- <style lang="scss" src="./home.scss"></style> -->
