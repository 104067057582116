import './bootstrap';
import Vue from 'vue';
import Vuex from 'vuex';
import VueAxios from 'vue-axios';
import axios from 'axios';
import Routes from './routes.js';
import App from './layouts/app';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueAuthenticate from 'vue-authenticate'
import money from 'v-money'
import store from './store.js'
import Flickity from 'vue-flickity';
// import * as hiringvalue from './services/hiringvalue';
import i18n from './i18n';
import VueGtag from 'vue-gtag';
import VueToast from 'vue-toast-notification';
import TextareaAutosize from 'vue-textarea-autosize';

// style
import './sass/style.scss';

Vue.use(Vuex)
Vue.use(Vuelidate)
Vue.use(VueAwesomeSwiper)
Vue.use(VueTheMask)
Vue.use(money, { precision: 4 })
Vue.use(VueAxios, axios)
Vue.use(TextareaAutosize)
Vue.use(VueAuthenticate, {
  baseUrl: 'https://creators.llc',
  // baseUrl: 'https://dev.creators.llc',
  providers: {
    facebook: {
      // clientId: '232507472284560',
      clientId: '421947708731526',
      redirectUri: 'https://creators.llc/auth/facebook/callback',
      // redirectUri: 'https://dev.creators.llc/auth/facebook/callback',
    },
    google: {
      clientId: '667882323038-t4nf626nr7t1ci18jgsjqe1bfad7v2tl.apps.googleusercontent.com',
      redirectUri: 'https://creators.llc/auth/google/callback',
      // redirectUri: 'https://dev.creators.llc/auth/google/callback',
    },
  }
})
Vue.use(VueGtag, {
  config: { id: 'UA-90265209-1' }
})
Vue.use(VueToast, {
  position: 'top-left'
});

// menu
Vue.component('c-menu', require('./components/menus/menu.vue').default);

// footer
Vue.component('c-footer', require('./sections/footer/footer.vue').default);

// box de notificação do chat
Vue.component('c-box-chat', require('./components/boxes/box-chat.vue').default);

// welcome home
Vue.component('hero-home', require('./sections/hero/home.vue').default);

// formulários
Vue.component('c-forms', require('./sections/forms/forms.vue').default);

// Modal
Vue.component('c-modal', require('./components/modal/modal.vue').default);

// Home components
Vue.component('home-button', require('./components/home/Button.vue').default);
Vue.component('home-checkbox', require('./components/home/Checkbox.vue').default);
Vue.component('home-chip', require('./components/home/Chip.vue').default);
Vue.component('home-header', require('./components/home/Header.vue').default);
Vue.component('home-input', require('./components/home/Input.vue').default);
Vue.component('home-modal', require('./components/home/Modal.vue').default);
Vue.component('home-select', require('./components/home/Select.vue').default);
Vue.component('home-skill', require('./components/home/Skill.vue').default);
Vue.component('language-selector', require('./components/home/LanguageSelector.vue').default);

// Cards
Vue.component('c-card-user', require('./components/card/card-user.vue').default);
Vue.component('c-card-project-hiring', require('./components/card/card-project-hiring.vue').default);
Vue.component('c-card-project-select-proposal', require('./components/card/card-project-select-proposal.vue').default);
Vue.component('c-card-project-selection-time', require('./components/card/card-project-selection-time.vue').default);
Vue.component('c-card-evaluation', require('./components/card/card-evaluation.vue').default);
Vue.component('c-card-briefing-client', require('./components/card/card-briefing-client.vue').default);
Vue.component('c-card-briefing-entrega', require('./components/card/card-briefing-entrega.vue').default);
Vue.component('c-card-briefing-money', require('./components/card/card-briefing-money.vue').default);
Vue.component('c-card-briefing-project', require('./components/card/card-briefing-project.vue').default);
Vue.component('c-card-briefing-selected-professional', require('./components/card/card-briefing-selected-professional.vue').default);
Vue.component('c-card-client-dash', require('./components/card/card-client-dash.vue').default);
Vue.component('c-card-client-user', require('./components/card/card-client-user.vue').default);
Vue.component('c-card-client-profile', require('./components/card/card-client-profile.vue').default);
Vue.component('c-card-client-clean-dash', require('./components/card/card-client-clean-dash.vue').default);
Vue.component('c-card-professional', require('./components/card/card-professional.vue').default);
Vue.component('c-card-professional-new-briefing', require('./components/card/card-professional-new-briefing.vue').default);
Vue.component('c-card-professional-community', require('./components/card/card-professional-community.vue').default);
Vue.component('c-card-company-list', require('./components/card/card-company-list.vue').default);
Vue.component('c-card-admin-user', require('./components/card/card-admin-user.vue').default);
Vue.component('c-card-admin-hiring', require('./components/card/card-admin-hiring.vue').default);
Vue.component('c-card-admin-mural', require('./components/card/card-admin-mural.vue').default);
Vue.component('c-card-admin-professional', require('./components/card/card-admin-professional.vue').default);

// Modal
Vue.component('c-modal', require('./components/modal/modal.vue').default);

// timeline briefing
Vue.component('c-b-initial', require('./components/timeline/b-initial.vue').default);
Vue.component('c-b-send-proposal', require('./components/timeline/b-send-proposal.vue').default);
Vue.component('c-b-proposal', require('./components/timeline/b-proposal.vue').default);
Vue.component('c-b-admin-approved', require('./components/timeline/b-admin-approved.vue').default);
Vue.component('c-b-pending', require('./components/timeline/b-pending.vue').default);
Vue.component('c-b-payment', require('./components/timeline/b-payment.vue').default);
Vue.component('c-b-evaluation', require('./components/timeline/b-evaluation.vue').default);
Vue.component('c-b-your-evaluation', require('./components/timeline/b-your-evaluation.vue').default);
Vue.component('c-b-approved', require('./components/timeline/b-approved.vue').default);
Vue.component('c-b-accept-briefing', require('./components/timeline/b-accept-briefing.vue').default);
Vue.component('c-b-select-professional', require('./components/timeline/b-select-professional.vue').default);
Vue.component('c-b-confirm-professional', require('./components/timeline/b-confirm-professional.vue').default);
Vue.component('c-b-professional-selected', require('./components/timeline/b-professional-selected.vue').default);
Vue.component('c-b-renew', require('./components/timeline/b-renew.vue').default);

// Profile
Vue.component('social-info', require('./components/profile/social-info.vue').default);
Vue.component('social-cards', require('./components/profile/social-cards.vue').default);
Vue.component('user-rating', require('./components/profile/user-rating.vue').default);

// Home components
Vue.component('home-button', require('./components/home/Button.vue').default);
Vue.component('home-checkbox', require('./components/home/Checkbox.vue').default);
Vue.component('home-chip', require('./components/home/Chip.vue').default);
Vue.component('home-header', require('./components/home/Header.vue').default);
Vue.component('home-input', require('./components/home/Input.vue').default);
Vue.component('home-modal', require('./components/home/Modal.vue').default);
Vue.component('home-select', require('./components/home/Select.vue').default);
Vue.component('home-skill', require('./components/home/Skill.vue').default);
Vue.component('language-selector', require('./components/home/LanguageSelector.vue').default);

Vue.component('flickity', Flickity);

const app = new Vue({
  el: '#home',
  store,
  i18n,
  router: Routes,
  render: h => h(App),
});

export default app;
