<template>
  <div class="skill-container">
    <span class="skill-name"> {{ value }} </span>
    <img src="/images/close.svg" class="close-button" @click="handleClick" />
  </div>
</template>

<script>
export default {
  name: 'Skill',
  props: {
    value: {
      type: String,
      required: false,
      default: 'GGWP'
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('remove', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.skill-container {
  margin: 0 10px 10px 0;
  display: flex;
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid #7f7f7f;
  .skill-name {
    padding: 8px 14px;
    font-size: 16px;
    color: #7f7f7f;
  }
  .close-button {
    padding: 8px;
    color: #7f7f7f;
    font-size: 16px;
  }
}
</style>
