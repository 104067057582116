<template>
  <div class="c-box-chat">

    <!-- Card Chat -->
    <div class="box-chat mt-3 rounded cursor-pointer" @click="sendParent">
      <div class="px-0 mr-lg-4 pr-lg-2">
        <!-- <router-link :to="notification.url"> -->
          <i class="ico-chat icons"></i>
        <!-- </router-link> -->
      </div>
      <div class="col-lg-8 col-10 offset-1 offset-lg-0 px-0">
        <h6>{{ notification.title }}</h6>
        <span class="d-lg-none">{{ notification.createdAt }}</span>
        <h6 class="grey" v-if="notification.desc.length > 97">{{ notification.desc.substring(0, 97) + '...' }}</h6>
        <h6 class="grey" v-else>{{ notification.desc }}</h6>

      </div>
      <div class="col-lg-3 text-right my-auto p-0 d-lg-block d-none">
        <span>{{ notification.createdAt }}</span>
      </div>
    </div>

  </div>
</template>

<script>
    export default {
      props: ['notification'],
      mounted() {
      },
      methods: {
        sendParent() {
          this.$emit('markRead', this.notification);
        },
      },
    }
</script>
