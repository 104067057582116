<template>
  <div class="c-card-evaluation">

    <!-- Card avaliação -->
    <div class="card-white card-project rounded done">

      <h5 v-if="owner">Avaliar Creator</h5>
      <h5 v-else>Avaliar Empresa</h5>

      <router-link :to="(owner ? '/dashboard/client/briefing/' : '/dashboard/professional/briefing/') + hiring.id" class="box-thumbs d-block my-2" data-dismiss="modal" aria-label="Close">

        <div v-if="owner">
          <div class="row align-items-center mb-1">
            <div class="col-1 my-2">
              <img v-if="provider.avatar" :src="'/' + provider.avatar" class="thumb-project">
              <img v-else src="/images/avatar.jpg" class="thumb-project">
            </div>
            <div class="col-10">
              <h5 v-if="status == 1" class="ml-1">{{ provider.name }} {{ provider.last_name !== 'null' ? provider.last_name : '' }} - #{{ hiring.id }}</h5>
              <h5 v-if="status == 2" class="ml-1">Aguarde...</h5>
              <h5 v-if="status == 3" class="ml-1">Avaliação enviada com sucesso!</h5>
            </div>
          </div>
        </div>

        <div v-if="!owner">
          <div class="row align-items-center mb-1">
            <div class="col-1 my-2">
              <img v-if="company && company.logo" :src="'/' + company.logo" class="thumb-project">
              <img v-else src="/images/avatar.jpg" class="thumb-project">
            </div>
            <div class="col-10">
              <h5 v-if="status == 1" class="ml-1">{{ company ? (company.nome_fantasia ? company.nome_fantasia : company.razao) : '' }} - #{{ hiring.id }}</h5>
              <h5 v-if="status == 2" class="ml-1">Aguarde...</h5>
              <h5 v-if="status == 3" class="ml-1">Avaliação enviada com sucesso!</h5>
            </div>
          </div>
        </div>

      </router-link>

      <div class="rating-project text-center mb-2">
        <i class="ico-rating" :class="{'active': form.total > 0}" v-on:click="form.total = 1"></i>
        <i class="ico-rating" :class="{'active': form.total > 1}" v-on:click="form.total = 2"></i>
        <i class="ico-rating" :class="{'active': form.total > 2}" v-on:click="form.total = 3"></i>
        <i class="ico-rating" :class="{'active': form.total > 3}" v-on:click="form.total = 4"></i>
        <i class="ico-rating" :class="{'active': form.total > 4}" v-on:click="form.total = 5"></i>
        <i class="ico-send-review" v-on:click="evaluate"></i>
      </div>

      <div v-if="form.total && form.total < 4">
        <h3>O que deu errado?</h3>
        <p
          class="tag mr-lg-3 mb-2"
          :class="{'selected': form.communication < 5}"
          @click="form.communication = form.communication < 5 ? 5 : 1"
          >Comunicação</p>
        <p
          class="tag mr-lg-3 mb-2"
          :class="{'selected': form.punctuality < 5}"
          @click="form.punctuality = form.punctuality < 5 ? 5 : 1"
          >Pontualidade</p>
        <p
          class="tag mr-lg-3 mb-2"
          :class="{'selected': form.quality < 5}"
          @click="form.quality = form.quality < 5 ? 5 : 1"
          >Qualidade</p>
        <!-- <p class="tag mr-lg-3">Falta de informação</p> -->
      </div>

    </div>

  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    props: ['hiring', 'owner', 'status'],
    data() {
      return {
        date: moment().format('DD/MM/YYYY'),
        form: {
          total: 0,
          communication: 5,
          punctuality: 5,
          quality: 5,
        },
      }
    },
    mounted() {
      console.log('Component mounted.')
    },
    methods: {
      evaluate() {
        let _this = this;
        if (this.status == 1 && this.form.total > 0) {
          this.form.hiring = this.hiring.id;
          this.$emit("evaluate", this.form);
          setTimeout(() => { _this.form.total = 0; }, 2300);
        }
      },
    },
    computed: {
      provider() {
        if (this.hiring && this.hiring.provider) return this.hiring.provider;
        return {};
      },
      company() {
        if (this.hiring && this.hiring.company) return this.hiring.company;
        return {};
      },
    },
  }
</script>
