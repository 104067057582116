<template>
  <div class="c-card-user">
    <!-- Card User -->
    <div class="card-white card-user text-center rounded">
      <div class="col-12 text-center">
        <img :src="avatar" alt="Card image" class="thumb-user mb-3" />
      </div>
      <div class="text-center w-100">

        <h3 class="name" :class="{ skeleton: syncing }" v-if="(user.profile && user.profile.perfil) || user.name">
          {{
            user.profile && user.profile.perfil
              ? user.profile.perfil
              : user.name
          }}
        </h3>
        <h3 class="name" :class="{ skeleton: syncing }" v-else>-</h3>

        <p v-if="averageReviews * 1" class="large">{{ averageReviews }}</p>
        <i v-if="averageReviews * 1" class="ico-star"></i>
      </div>
      <div class="text-left mt-lg-3">
        <h3>{{ received }}</h3>
        <p class="large">{{ $t("dash.received_proposals") }}</p>
        <br />
        <h3>{{ accepted }}</h3>
        <p class="large">{{ $t("dash.accepted_proposals") }}</p>
      </div>
      <hr class="separator" />
      <div
        class="text-left my-lg-auto chart-profile"
        data-toggle="tooltip"
        data-placement="top"
        title="Percentual atualizado a cada 24 horas"
      >
        <div class="user-progress">
          <loading-progress
            :progress="user.completeness / 100"
            size="58"
            rotate
            fillDuration="2"
            rotationDuration="1"
          />
          <h4 class="purple no-italic">
            {{ user.completeness ? user.completeness : 0 }}%
          </h4>
        </div>
        <router-link to="/dashboard/professional/settings">
          <p class="purple" v-if="user.completeness < 100">{{ $t("dash.complete_profile") }}</p>
          <p class="purple" v-if="user.completeness == 100">{{ $t("dash.edit_profile") }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";

Vue.use(VueProgress, {
  // defaultShape: 'circle',
});

export default {
  props: ["user", "received", "accepted", "syncing"],

  data() {
    return {
      // progress: .25,
    };
  },

  mounted() {
    //
    $('[data-toggle="tooltip"]').tooltip();
  },

  beforeDestroy() {
    $('[data-toggle="tooltip"], .tooltip').tooltip("hide");
    $('.tooltip').hide();
  },

  computed: {
    avatar() {
      return this.user.avatar ? "/" + this.user.avatar : "/images/avatar.jpg";
    },

    averageReviews() {
      var sum = 0;
      var ammount = 0;
      this.user.received_reviews.forEach((review) => {
        sum += review.total;
        ammount++;
      });
      if (ammount > 0) return (sum / ammount).toFixed(1);
      return 0;
    },
  },
};
</script>
