<template>
  <div class="lang-container no-print">
    <select
      :value="lang"
      class="desktop lang-selector"
      :class="
        color === 'white' ? 'white white-underline' : 'black black-underline'
      "
      @change="handleLang"
    >
      <option value="pt">Por(BR)</option>
      <option value="en">Eng(US)</option>
    </select>
    <select
      :value="lang"
      class="mobile lang-selector"
      :class="color === 'white' ? 'white' : 'black'"
      @change="handleLang"
    >
      <option value="pt">PT</option>
      <option value="en">EN</option>
    </select>
    <img
      :src="color === 'white' ? '/images/lang.svg' : '/images/lang_black.svg'"
    />
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  props: {
    color: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      lang: this.$store.state.locale,
    };
  },
  methods: {
    handleLang(e) {
      this.lang = e.target.value;
      this.$root.$i18n.locale = e.target.value;
      this.$store.commit("setLocale", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-container {
  z-index: 100;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  padding: 20px 40px;
  img {
    @media screen and (max-width: 768px) {
      display: none;
    }
    top: calc(50% - 12px);
    position: absolute;
    right: 110px;
  }
  .desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
    display: unset;
  }
  .mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: unset;
    }
  }
  .lang-selector {
    -webkit-appearance: none;
    border-radius: 0;
    background-color: transparent;
    border: 0;
    height: 42px;
    border-bottom: 2px solid;
    option {
      color: black;
    }
    padding: 0 5px 0 35px;
    @media screen and (max-width: 768px) {
      padding: 0 5px;
    }
    transition: all 0.2s ease-in-out;
    outline: none;
    &:focus,
    &.filled {
      outline: transparent;
      border-bottom: 2px solid #B59DFF;
    }
  }
}

.black {
  color: black;
}

.black-underline {
  border-bottom-color: black;
}

.white {
  color: white;
}

.white-underline {
  border-bottom-color: white;
}
</style>
