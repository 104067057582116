<template>
  <div class="b-select-professional">
    <div class="b-select-professional">
      <div class="row">
        <div class="col">
          <div class="text-right mb-3">
            <span class="select" v-if="providers.filter((p) => p.pivot.status == 2 && p.pivot.visibility == 1).length != ids.length" @click="selectAll">Selecionar todos</span>
            <span class="select" v-else @click="deselectAll">Desselecionar todos</span>
          </div>
        </div>
      </div>
      <div class="progress-box">
        <!--<p>{{ date }}</p>
        <h2 v-if="hiring.renewal_parents.length == 0">{{ $t("dash.selecionar_c") }}</h2>
        <h2 v-if="hiring.renewal_parents.length > 0">{{ $t("dash.selecionar_renewal") }}</h2>
       -->
      </div>
      <div class="row" v-if="hiring.renewal_parents.length == 0">
        <div
          class="col-lg-4 select-professional-animate"
          v-for="provider in providers.filter((p) => p.pivot.status == 2 && p.pivot.visibility == 1)"
          :key="provider.id"
          :class="ids.indexOf(provider.id) > -1 ? 'card-not-selected' : ''"
        >
          <c-card-professional
            :user="provider"
            :company="hiring.company"
            :plan="hiring.plan"
            :community="partOf(provider.id)"
            @selectOne="select(provider)"
          ></c-card-professional>
        </div>
      </div>
      <div class="row" v-if="hiring.renewal_parents.length > 0">
        <div
          class="col-lg-4 select-professional-animate"
          v-for="provider in providers"
          :key="provider.id"
          :class="ids.indexOf(provider.id) > -1 ? 'card-not-selected' : ''"
        >
          <c-card-professional
            :ids="ids"
            :user="provider"
            :company="hiring.company"
            :plan="hiring.plan"
            :community="partOf(provider.id)"
            @selectOne="select(provider)"
          ></c-card-professional>
        </div>
      </div>
    </div>

    <div class="select-bottom" v-if="selected.length">
      <div>
        <div class="avatar-flex">
          <div class="img-avatar">
            <img
              :src="(user.avatar) ? '/' + user.avatar : '/images/avatar.jpg'"
              class="thumb-project"
              v-for="user in selected.slice(0, 3)"
            />
          </div>
          <h3>{{ selected.length }} {{ $t("dash.briefing.anotacao") }}</h3>
        </div>
      </div>
      <div class="align-btn">
        <h3 class="mr-3 sub-grey">
          {{ $t("dash.briefing.total_investimento") }}
        </h3>
        <div class="sum-accumulator" v-if="selected.length">
          {{
            toCurrency(
              costByPlan(
                selected.reduce((acc, user) => acc + user.pivot.value, 0)
              )
            )
          }}
        </div>
        <button
          data-toggle="modal"
          data-target="#exampleModal"
          class="btn btn-outline-primary purple w-auto mr-4 btn-together"
          click="sendCancel"
        >
          {{ $t("dash.cancelar") }}
        </button>

        <div v-if="selected.length">
          <button
            v-if="!syncing"
            type="submit"
            class="btn btn-primary sm-mobile btn-together"
            @click="approve"
          >
            <!-- <router-link :to="{ name: 'client/dashboard' }"> -->
              {{ $t("dash.briefing.aprovar") }}
            <!-- </router-link> -->
          </button>

          <button v-else class="btn btn-primary">
            <div id="loader-btn">
              <div class="dots container">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["hiring", "communityIds", "order", "search"],

  data() {
    return {
      syncing: false,
      date: moment().format("DD/MM/YYYY"),
      selected: [],
      ids: [],
    };
  },

  mounted() {
    console.log("Component mounted.");
  },

  methods: {
    select(user) {
      var remove = this.ids.indexOf(user.id);
      if (remove > -1) {
        this.ids.splice(remove, 1);
        this.selected.splice(remove, 1);
      } else {
        this.ids.push(user.id);
        this.selected.push(user);
      }
    },

    selectAll() {
      this.ids = [];
      this.selected = [];
      let providers = this.hiring.providers.filter((p) => p.pivot.status == 2 && p.pivot.visibility == 1);
      for (var i = 0; i < providers.length; i++) {
        this.ids.push(providers[i].id);
        this.selected.push(providers[i]);
      }
    },

    deselectAll() {
      this.ids = [];
      this.selected = [];
    },

    unselect(user) {
      var remove = this.ids.indexOf(user.id);
      if (remove > -1) {
        this.ids.splice(remove, 1);
        this.selected.splice(remove, 1);
      }
    },

    sendCancel() {
      this.$emit("unselect");
      type: Function, required;
      true;
    },

    unselect(user) {
      var remove = this.ids.indexOf(user.id);
      if (remove > -1) {
        this.ids.splice(remove, 1);
        this.selected.splice(remove, 1);
      }
    },

    approve() {
      this.syncing = true;
      this.$emit("approve", this.ids);
    },

    partOf(userId) {
      let index = this.communityIds.indexOf(userId);
      if (index > -1) return true;
      return false;
    },

    toCurrency(val) {
      let value = val * 1;
      let currency = "BRL";
      if (this.hiring.company && this.hiring.company.currency == "eur") {
        value = val / this.$store.state.currencies.eur;
        currency = "EUR";
      }
      if (this.hiring.company && this.hiring.company.currency == "usd") {
        value = val / this.$store.state.currencies.usd;
        currency = "USD";
      }
      return (value / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
      });
    },

    costByPlan(budget) {
      let value = 0;
      switch (this.hiring.plan) {
        case 1:
          value = budget / 0.85;
          break;
        case 2:
          value = budget / 0.85;
          break;
        case 3:
          value = budget / 0.75;
          break;
        case 4:
          value = budget / 0.8928571429;
          break;
        case 5:
          value = budget;
          break;
        case 6:
          value = budget / 0.7;
          break;
        case 7:
          value = budget / 0.8;
          break;
        default:
          value = budget / 0.85;
      }
      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company && this.hiring.company.id == 159)
        value = value * 1.006;
      return value;
    },
  },

  computed: {
    providers() {
      if (this.search != "") {
        return this.hiring.providers.filter(provider => {
          let name = provider.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          let last = provider.last_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return name || last;
        });
      } else {
        return this.hiring.providers;
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.select {
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
}
.select-bottom {
  background-color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-content: flex-start;
  left: 0px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 180px;
  z-index: 1;

  .align-btn {
    display: flex;
  }

  .sub-grey {
    color: #8b90a0;
    font-size: 16px;
  }
  .sum-accumulator {
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
  }
}

.modal-title {
  font-size: 22px;
  text-align: center;
  margin-top: 120px;
  font-weight: 700;
  margin-right: -33px;
}

.modal-body {
  text-align: center;
  font-size: 15px;
  span {
    text-decoration: underline;
    font-size: 15px;
  }
}
.check {
  text-align: center;
  background: linear-gradient(180deg, #7553e2 0%, #74e7d0 100%);
  margin: 25px 210px 40px 210px;
  position: absolute;
  font-size: 20px;
  padding: 16px;
  border-radius: 12px;
}
.check-1 {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  color: white;
  font-size: 25px;
}
.modal-footer {
  .btn {
    margin-right: 125px;
    margin-left: 123px;
    margin-bottom: 40px;
  }
}
.avatar-flex {
  display: flex;
  align-items: center;
  h3 {
    margin-left: 10px;
  }

  .img-avatar {
    img {
      margin-left: -30px;
      border-radius: 50%;
      flex-wrap: wrap;
    }
  }
}
</style>
