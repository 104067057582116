<template>
  <div>

    <c-menu v-if="showLoggedArea"></c-menu>

    <div :class="{'wrapper-pages': showLoggedArea}">

      <router-view></router-view>

    </div>

    <c-footer v-if="showLoggedArea"></c-footer>

  </div>

</template>

<script>
export default {

  created() {
    this.$root.$i18n.locale = this.$store.state.locale;
    this.$store.commit('setExchange');
  },

  computed: {
    showLoggedArea() {
      if (this.$route.path === '/') return false;
      if (this.$route.path.includes('/c/')) return false;
      return true;
    },
  },
}

</script>
