import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const auth = (to, from, next) => {
    if (store.getters.auth) {
        next();
        return;
    }
    next('/');
}

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('./pages/home/index.vue')
        },
        {
            path: '/dashboard/client',
            name: 'dashboard',
            component: () => import('./pages/client/dashboard.vue')
        },
        {
            path: '/dashboard/client/company',
            // name: 'profile',
            component: () => import('./pages/client/company.vue'),
        },
        {
            path: '/dashboard/client/:company/profile/:id',
            name: 'client/profile',
            component: () => import('./pages/client/profile.vue'),
        },
        {
            path: '/dashboard/client/settings',
            name: 'client/settings',
            component: () => import('./pages/client/settings.vue'),
        },
        {
            path: '/dashboard/client/card',
            name: 'client/card',
            component: () => import('./pages/client/card.vue'),
        },
        {
            path: '/dashboard/professional/settings',
            name: 'settings',
            component: () => import('./pages/professional/settings.vue'),
        },

        {
            path: '/dashboard/notifications',
            name: 'notifications',
            component: () => import('./pages/notifications.vue'),
        },
        {
            path: '/dashboard/professional/profile',
            name: 'external-profile',
            component: () => import('./pages/professional/profile/index.vue'),
        },
        {
            path: '/dashboard/professional/perfil',
            name: 'perfil-leo',
            component: () => import('./pages/professional/perfil_leo/index.vue'),
        },
        {
            path: '/dashboard/professional/historic',
            name: 'historic',
            component: () => import('./pages/professional/historic.vue'),
        },
        {
            path: '/dashboard/client/historic',
            name: 'client/historic',
            component: () => import('./pages/client/historic.vue'),
        },

        {
            path: '/dashboard/client/new-briefing',
            name: 'client/new-briefing',
            component: () => import('./pages/client/new-briefing.vue'),
        },

        // {
        //     path: '/dashboard/components/',
        //     name: 'dashboard/components',
        //     component: ComponentsPage,
        // },

        {
            path: '/dashboard/contract/:id',
            name: 'dashboard/contract',
            component: () => import('./pages/contract.vue'),
        },
        {
            path: '/dashboard/professional/',
            name: 'dashboard/professional',
            component: () => import('./pages/professional/dashboard.vue'),
        },
        {
            path: '/dashboard/professional/briefing/:id',
            name: 'dashboard/professional/briefing',
            component: () => import('./pages/professional/briefing.vue'),
        },
        {
            path: '/d/p/b/:id',
            name: 'd/p/b',
            component: () => import('./pages/professional/briefing.vue'),
        },
        {
            path: '/dashboard/professional/new-briefing',
            name: 'dashboard/professional/new-briefing',
            component: () => import('./pages/professional/new-briefing.vue'),
        },
        {
            path: '/dashboard/professional/complete-briefing',
            name: 'dashboard/professional/complete-briefing',
            component: () => import('./pages/professional/complete-briefing.vue'),
        },
        {
            path: '/dashboard/client/briefing/:id',
            name: 'dashboard/client/briefing',
            component: () => import('./pages/client/briefing.vue'),
        },
        {
            path: '/dashboard/client/squads/:id',
            name: 'dashboard/client/squads',
            component: () => import('./pages/client/squads.vue'),
        },
        {
            path: '/dashboard/client/results/:id',
            name: 'dashboard/client/results',
            component: () => import('./pages/client/results.vue'),
        },
        {
            path: '/dashboard/client/briefing/:id/select',
            name: 'client/select',
            component: () => import('./pages/client/select.vue'),
        },
        {
            path: '/dashboard/professional/briefing/:id/chat/',
            name: 'dashboard/professional/chat',
            component: () => import('./pages/professional/chat.vue'),
        },
        {
            path: '/dashboard/client/briefing/:hiring/chat/:provider',
            name: 'dashboard/client/chat',
            component: () => import('./pages/client/chat.vue'),
        },
        {
            path: '/dashboard/client/mycommunity/',
            name: 'dashboard/client/mycommunity/',
            component: () => import('./pages/client/mycommunity.vue'),
        },
        {
            path: '/dashboard/client/newmembers/',
            name: 'dashboard/client/newmembers/',
            component: () => import('./pages/client/newmembers.vue'),
        },
        {
            path: '/dashboard/client/mycommunity-notamember',
            name: 'dashboard/client/mycommunity-notamember',
            component: () => import('./pages/client/mycommunity-notamember.vue'),
        },
        {
            path: '/dashboard/client/pricing',
            name: 'dashboard/client/pricing',
            component: () => import('./pages/client/pricing.vue'),
        },
        {
            path: '/dashboard/admin',
            name: 'dashboard/admin/',
            component: () => import('./pages/admin/dashboard.vue'),
        },
        {
            path: '/dashboard/admin/cms',
            name: 'dashboard/admin/cms/',
            component: () => import('./pages/admin/cms.vue'),
        },
        {
            path: '/dashboard/admin/growth',
            name: 'dashboard/admin/growth',
            component: () => import('./pages/admin/growth.vue'),

        },
        {
            path: '/dashboard/admin/engagements',
            name: 'dashboard/admin/engagements',
            component: () => import('./pages/admin/engagements.vue')
        },
        {
            path: '/dashboard/admin/finance',
            name: 'dashboard/admin/finance',
            component: () => import('./pages/admin/finance.vue')
        },
        {
            path: '/dashboard/admin/users',
            name: 'dashboard/admin/users',
            component: () => import('./pages/admin/users.vue'),
        },
        {
            path: '/dashboard/admin/users-professionals',
            name: 'dashboard/admin/users-professionals',
            component: () => import('./pages/admin/users-professionals.vue'),
        },
        {
            path: '/dashboard/admin/users-professionals-active',
            name: 'dashboard/admin/users-professionals-active',
            component: () => import('./pages/admin/users-professionals-active.vue'),
        },
        {
            path: '/dashboard/admin/users/:id',
            name: 'dashboard/admin/users-profile',
            component: () => import('./pages/admin/users-profile.vue'),
        },
        {
            path: '/dashboard/admin/enterprise-profile/:id',
            name: 'dashboard/admin/enterprise-profile',
            component: () => import('./pages/admin/enterprise-profile.vue'),
        },
        {
            path: '/dashboard/admin/company/:company/profile/:id',
            name: 'admin/client/profile',
            component: () => import('./pages/client/profile.vue'),
        },
        {
            path: '/dashboard/admin/enterprise-member-profile',
            name: 'dashboard/admin/enterprise-member-profile',
            component: () => import('./pages/admin/enterprise-member-profile.vue'),
        },
        {
            path: '/dashboard/admin/settings',
            name: 'dashboard/admin/settings',
            component: () => import('./pages/admin/settings.vue'),
        },
        // {
        //     path: '/sample/login',
        //     name: 'login',
        //     component: () => import('./pages/login.vue'),
        // },
        {
            path: '/c/:id/:name',
            name: 'external-profile-by-id',
            component: () => import('./pages/external-profile/index.vue'),
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

router.beforeEach((to, from, next) => {
    let TOKEN = store.state.token;
    let me = store.state.me;
    if (to.fullPath.includes('/dashboard/') || to.fullPath.includes('/d/p/')) {
        if (!TOKEN || !me || !me.id) {
            let home = '/?openlogin=true';
            if (to.fullPath.includes('/briefing/')) {
              home += '&briefing=' + to.fullPath.split("/").at(-1);
            }
            next(home);
        }
    }
    next();
});

export default router;
