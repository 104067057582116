<template>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-12 my-5">

          <h3>Formulário de exemplo</h3>

          <form>
            <div class="form-group has-float-label">
              <input type="email" class="form-control" id="" aria-describedby="emailHelp" placeholder="   " onkeyup="this.setAttribute('value', this.value);">
              <label for="exampleInputEmail1">Email address</label>
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>
            <div class="form-group has-float-label">
              <input type="password" class="form-control" id="" placeholder="    " onkeyup="this.setAttribute('value', this.value);">
              <i class="ico-eye" @click="showPassword"></i>
              <label for="exampleInputPassword1">Password</label>
            </div>

            <!-- select -->
            <div class="form-group has-float-label select-group">
              <select class="form-control" id="exampleFormControlSelect1">
                <option selected>São Paulo</option>
                <option>Campinas</option>
                <option>Rio de Janeiro</option>
                <option>Curitiba</option>
                <option>Florianópolis</option>
                <option>Salvador</option>
              </select>
              <label for="exampleFormControlSelect1">Cidade</label>
            </div>

            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <span class="checkmark"></span>
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>

            <div class="form-group form-check">
              <input type="radio" class="form-check-input" id="exampleCheck3">
              <span class="checkmark radiomark"></span>
              <label class="form-check-label" for="exampleCheck3">Check me out</label>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </form>

        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h3>Card padrão do boostrap</h3>
        </div>

        <div class="col-4">
          <div class="card">
            <img src="http://lorempixel.com/300/300/" class="card-img-top" alt="">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>

</template>

<script>
export default {
  mounted() {
    console.log('Component mounted.')
  }
}
</script>

<style lang="scss" src="./forms.scss"></style>
