<template>
  <div class="c-b-proposal">

    <div class="wrapper-timeline b-proposal">
      <div class="progress-box">
        <p>{{ formatDate(pivot.updated_at) }}</p>
        <h2>{{ $t("dash.b_enviado") }}</h2>
      </div>
      <div class="box-proposal card-white rounded">
        <div class="col-12 px-0 px-lg-3">
          <h3 v-if="pivot.status == 2">{{ $t("dash.valor") }} - {{ toCurrency(pivot.value) }}</h3>
          <h3 v-else>{{ $t("dash.b_rejeitado") }}</h3>
          <div v-if="status == 2 || status == 3" @click="changeProposal" class="purple underline text-left" style="cursor: pointer;">{{ $t("dash.m_ideia") }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    props: ['pivot', 'status'],
    data() {
      return {
        me: this.$store.state.me,
      }
    },
    mounted() {
      console.log('Component mounted.')
    },
    methods: {

      toCurrency(val) {
        let value = val * 1;
        let currency = "BRL";
        if (this.me.currency == "eur") {
          value = val / this.$store.state.currencies.eur;
          currency = "EUR";
        }
        if (this.me.currency == "usd") {
          value = val / this.$store.state.currencies.usd;
          currency = "USD";
        }
        return (value/100).toLocaleString("pt-BR", { style: "currency", currency: currency, minimumFractionDigits: 2 });
      },

      formatDate: function (date) {
        return moment(date).format('DD/MM/YYYY');
      },

      changeProposal() {
        this.$emit('changeProposal');
      },
    },
  }
</script>
