<template>

  <div class="c-card-company-list">
    <h3 class="card-title my-3 w-100" v-on:click="company.active = !company.active" :class="{'active': company.active}">
      <div class="card-title-text my-lg-auto mb-4 d-flex">
        <router-link :to="'/dashboard/admin/enterprise-profile/' + company.id">
          <img v-if="company.logo" :src="'/' + company.logo" alt="Card image" class="thumb-user mr-2 hover-opacity">
          <img v-else src="/images/avatar.jpg" class="thumb-user mr-2 hover-opacity">
        </router-link>
        <div class="d-inline-block vertical-align-middle">
          <router-link :to="'/dashboard/admin/enterprise-profile/' + company.id">
            <h5 class="m-0 hover-opacity">{{ company.nome_fantasia ? company.nome_fantasia : company.razao }}</h5>
          </router-link>
          <!-- <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green"></i> -->
        </div>
      </div>
      <div class="card-text-header col-lg-3 col-5 px-0" v-if="!company.active">
        <h5>Área de atuação</h5>
        <p>{{ company.area }}</p>
      </div>
      <div class="card-text-header col-lg-3 col-5 px-0" v-if="!company.active">
        <h5>Cidade</h5>
        <p>{{ (company.address && company.address[0].city) ? company.address[0].city.name : '-' }}</p>
      </div>
      <div v-if="company.community_flag" class="card-text-header ico-community col-lg-3 col-2" :class="company.active ? 'offset-lg-6 offset-10' : ''">
        <i class="icons ico-user-purple"></i>
      </div>
    </h3>

    <div v-bind:class="{'collapse-on': true, 'show': company.active }">

      <div class="d-flex flex-wrap box-description">
        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile">Área de atuação</h5>
          <p class="txt-briefing">{{ company.area }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile">Cidade</h5>
          <p class="txt-briefing">{{ (company.address && company.address[0].city) ? company.address[0].city.name : '-' }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile">País</h5>
          <p class="txt-briefing">{{ (company.address && company.address[0].country) ? company.address[0].country.name : '-' }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <!-- <i class="ico-star-green selected"></i>
          <h5 class="f-mobile d-inline-block ml-3 pl-2">4,2 estrelas</h5> -->
        </div>

        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">CNPJ</h5>
          <p>{{ company.cnpj }}</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">Projetos ativos</h5>
          <p>{{ company.hirings.length }} projetos</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4  px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">ID</h5>
          <p>{{ company.id }}</p>
        </div>

        <div class="col-lg-3 mb-lg-4 px-0 pr-lg-3 my-lg-auto my-4">
          <router-link class="btn btn-primary fit-content float-right m-0" :to="{ path: '/dashboard/admin/enterprise-profile/' + company.id }">
            Ver perfil
          </router-link>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
  export default {
    props: ['company'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
