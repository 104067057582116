<template>
  <header>
    <div class="container d-flex">
      <div class="logo">
        <img src="/images/C.png" alt="Title first letter" />
        <div class="hide">
          <img src="/images/reators.png" alt="Title rest of the word" />
        </div>
      </div>
      <nav ref="buttons" class="buttons-container">
        <Button
          v-if="!TOKEN || !me"
          type="secondary"
          @click.native="handleModal('be')"
        >
          {{ $t("home.be") }}
          <span class="only-desktop">Creators</span>
        </Button>
        <Button
          v-if="!TOKEN || !me"
          type="secondary"
          @click.native="handleModal('hire')"
        >
          {{ $t("home.hire") }}
          <span class="only-desktop">Creators</span>
        </Button>
        <Button
          v-if="!TOKEN || !me"
          type="primary"
          @click.native="handleModal('login')"
          >Login</Button
        >

        <Button v-if="TOKEN && me" type="primary" @click.native="dashboard()">Dashboard</Button>

        <div class="lang-container">
          <select
            :value="lang"
            class="desktop lang-selector"
            @change="handleLang"
          >
            <option value="pt">Por(BR)</option>
            <option value="en">Eng(US)</option>
          </select>
          <select
            :value="lang"
            class="mobile lang-selector"
            @change="handleLang"
          >
            <option value="pt">PT-BR</option>
            <option value="en">EN</option>
          </select>
          <img src="/images/lang.svg" />
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { TweenMax } from "gsap";
import Button from "./Button";

export default {
  components: {
    Button,
  },
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      role: this.$store.state.role,
      lang: this.$store.state.locale,
    };
  },
  mounted() {
    const { buttons } = this.$refs;

    TweenMax.staggerFromTo(
      buttons.children,
      0.5,
      {
        opacity: 0,
        y: -10,
      },
      {
        opacity: 1,
        y: 0,
      },
      0.2
    );
  },
  methods: {
    dashboard() {
      if (this.me.admin) {
        // window.location = "/dashboard/admin";
        this.$router.push("/dashboard/admin");
      } else if (this.me.company_id) {
        if (!this.me.cpf || this.me.cpf == "") {
          // window.location = "/dashboard/client/settings";
          this.$router.push("/dashboard/client/settings");
        } else {
          // window.location = "/dashboard/client";
          this.$router.push("/dashboard/client");
        }
      } else {
        // window.location = "/dashboard/professional";
        this.$router.push("/dashboard/professional");
      }
    },
    handleLang(value) {
      this.lang = value.target.value;
      this.$root.$i18n.locale = value.target.value;
      this.$store.commit("setLocale", value.target.value);
    },
    handleModal(type) {
      this.$gtag.event(`${type}-creators`);
      this.$emit("open-modal", type);
    },
    handleChange(field, value) {
      switch (typeof this[field]) {
        case "object":
          if (!this[field].find((e) => e === value)) {
            this.complementary.push(value);
            this.complementary = this.complementary.filter((e) => e !== "");
          }
          break;
        default:
          this[field] = value;
          break;
      }
      this.$v[field] &&
        this.$v[field].$params.required &&
        this.$v[field].$touch();
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), transparent);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  // padding: 70px 160px 0px;
  padding-top:70px;
  margin-bottom: 200px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  @media screen and (max-width: 1020px) {
    padding: 20px 20px;
  }
  .container {
     display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
  }
  .logo {
    flex-grow: 2;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    left: 40px;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 1024px) {
      width: calc(100% - 430px);
    }
    @media screen and (max-width: 768px) {
      width: calc(100% - 320px);
    }
    .hide {
      width: 162px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      img {
        height: 25px;
      }
      @media screen and (max-width: 768px) {
        width: 0;
        transform: translateX(-30%);
      }
    }
    img {
      height: 30px;
    }
  }
  .buttons-container {
    display: flex;
    button {
      margin-right: 10px;
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
    }
  }
  nav {
    .lang-container {
      .desktop {
        @media screen and (max-width: 768px) {
          display: none;
        }
        display: unset;
      }
      .mobile {
        display: none;
        @media screen and (max-width: 768px) {
          display: unset;
        }
      }
      display: flex;
      align-content: center;
      position: relative;
      img {
        @media screen and (max-width: 768px) {
          display: none;
        }
        top: calc(50% - 12px);
        position: absolute;
      }
      .lang-selector {
        -webkit-appearance: none;
        border-radius: 0;
        background-color: transparent;
        color: white;
        border: 0;
        border-bottom: 2px solid white;
        option {
          color: black;
        }
        padding: 0 5px 0 35px;
        @media screen and (max-width: 768px) {
          padding: 0 5px;
        }
        transition: all 0.2s ease-in-out;
        outline: none;
        &:focus,
        &.filled {
          outline: transparent;
          border-bottom: 2px solid #B59DFF;
        }
      }
    }
    button {
      opacity: 0;
      overflow: hidden;
      .only-desktop {
        font-size: 16px;
        display: inline-block;
        padding-left: 2px;
        transition: all 0.3s ease-in-out;
        @media screen and (max-width: 768px) {
          opacity: 0;
          padding-left: 0;
          transform: translateX(-50%);
          width: 0;
        }
      }
    }
  }
}
</style>
