<template>
  <div class="c-card-professional">
    <div class="card bg-dark text-white wrapper-card-img mb-3">
      <!-- tem que carregar os trabalhos, se não tiver nenhum pode ser o avatar -->
      <img
        v-if="user.avatar"
        :src="'/' + user.avatar"
        alt="Card image"
        class="card-img"
      />
      <img v-else src="/images/avatar.jpg" class="card-img" />
      <div class="card-img-overlay">
        <div class="clearfix">
          <h5 class="float-left">
            {{
              user.profile && user.profile.perfil
                ? user.profile.perfil
                : user.name
            }}
          </h5>
          <span v-if="averageReviews">
            <div class="same-line">
              <i class="ico-star-white"></i>
              <h5 class="float-top rating-text">{{ averageReviews }}</h5>
            </div>
          </span>
        </div>

        <div class="description-profile">
          <p class="tag transparent mb-3">{{ user.profile.major }}</p>
          <!-- <p class="tag transparent mb-3 mr-2" v-for="speciality in user.specialities">
            {{ speciality.pt }}
          </p> -->
        </div>

        <div v-if="role == 'admin' && user.pivot">
          <p class="purple cursor-pointer mb-3" v-if="user.pivot.visibility == 1" @click="changeVisibility(user, 2)"><small>Remover visibilidade</small></p>
          <p class="purple cursor-pointer mb-3" v-if="user.pivot.visibility == 2" @click="changeVisibility(user, 1)"><small>Não visível</small></p>
        </div>

        <!-- <p v-if="role == 'admin'" class="white">{{ user.origin == "creative-x" ? "Creative-x" : "" }}</p> -->

        <div v-if="role == 'admin' && working">
          <h5 class="tag transparent mb-3">Contratado</h5>
        </div>

        <div
          class="
            see-profile
            text-center
            d-flex
            flex-wrap
            justify-content-center
            align-items-center
            w-100
          "
        >
          <a
            v-if="role != 'admin'"
            :href="'/c/' + user.id + '/' + user.name.split(' ')[0]"
            target="_blank"
            class="btn p-5 mt-4 white"
            >{{ $t("professional_card.view") }}</a
          >
          <a
            v-else
            :href="'/dashboard/admin/users/' + user.id"
            target="_blank"
            class="btn p-5 mt-4 white w-fit-content"
            >{{ $t("professional_card.view") }}</a
          >
        </div>

        <div class="content-bottom">
          <div class="row align-items-center">
            <div class="col" v-if="role == 'admin' && user.pivot && user.pivot.status">
              <h5 class="w-auto" :class="`tag ${user.pivot.status == 2 ? 'accepted' : user.pivot.status == 3 ? 'rejected' : 'transparent'}`">
                {{ user.pivot.status == 1 ? "Pendente" : user.pivot.status == 2 ? "Aceito" : "Recusado" }}
              </h5>
            </div>
            <div class="col">
              <div class="text-right">
                <router-link v-if="user.pivot" :to="`/dashboard/client/briefing/${user.pivot.hiring_id}/chat/${user.id}`" class="btn-icons primary">
                  <i class="icons ico-chat-light"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col" v-if="role != 'admin' && user.pivot && user.pivot.status == 2">
              <div class="w-fit-content">
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" :id="'select-user-'+ user.id" @click="selectFromList" />
                  <span class="checkmark"></span>
                  <label class="form-check-label select-info" :for="'select-user-'+ user.id">Selecionar</label>
                </div>
              </div>
            </div>
            <div class="col" v-if="role == 'admin' && !user.pivot">
              <div class="btn btn-outline-primary sm w-fit-content float-right" @click="removeFromList">X</div>
            </div>
            <div class="col">
              <h5 class="text-right money" v-if="user.pivot && user.pivot.value">
                {{ toCurrency(costByPlan(user.pivot.value)) }}
              </h5>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  export default {
    props: ["user", "company", "community", "plan"],

    data() {
      return {
        role: this.$store.state.role,
        selectedProvider: {
          exists: false,
        },
      }
    },

    mounted() {
    },

    methods: {
      toCurrency(val) {
        let value = val * 1;
        let currency = "BRL";
        if (this.company && this.company.currency == "eur") {
          value = val / this.$store.state.currencies.eur;
          currency = "EUR";
        }
        if (this.company && this.company.currency == "usd") {
          value = val / this.$store.state.currencies.usd;
          currency = "USD";
        }
        return (value / 100).toLocaleString("pt-BR", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
        });
      },

      costByPlan(budget) {
        let value = 0;
        switch (this.plan) {
          case 1:
            value = budget / 0.85;
            break;
          case 2:
            value = budget / 0.85;
            break;
          case 3:
            value = budget / 0.75;
            break;
          case 4:
            value = budget / 0.8928571429;
            break;
          case 5:
            value = budget;
            break;
          case 6:
            value = budget / 0.70;
            break;
          case 7:
            value = budget / 0.80;
            break;
          default:
            value = budget / 0.85;
        }
        // Pernod Ricard tem 0,6% sobre o valor final
        if (this.company && this.company.id == 159) value = value * 1.006;
        return value;
      },

      selectFromList() {
        this.$emit("selectOne", this.user);
      },

      removeFromList() {
        this.$emit("removeOne", this.user.id);
      },

      changeVisibility(user, visibility) {
        let data = {hiring: user.pivot.hiring_id, user: user.id, visibility: visibility};
        this.$emit('changeVisibility', data);
      },

    },

    computed: {
      averageReviews() {
        var sum = 0;
        var ammount = 0;
        if (this.user.received_reviews)
          this.user.received_reviews.forEach((review) => {
            sum += review.total;
            ammount++;
          });
        if (ammount > 0) return (sum / ammount).toFixed(1);
        return 0;
      },
      working() {
        let working = false;
        if (this.user.hirings) {
          for (var i = 0; i < this.user.hirings.length; i++) {
            if (
              moment(this.user.hirings[i].date_start) < moment() &&
              moment(this.user.hirings[i].date_end) > moment()
            ) {
              if (this.user.id == this.user.hirings[i].provider_id) {
                working = true;
              }
            }
          }
        }
        return working;
      },
    },
  }
</script>


<style lang="scss" scoped>
.tag.accepted {
  background-color: #7553e2;
  color: white;
  border: none;
  font-size: 12px;
}

.tag.rejected {
  background-color: #fd4646;
  color: white;
  border: none;
  font-size: 12px;
}

.card-img-overlay {
  z-index: 1;
}

.bottom-select span {
  font-size: 15px;
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
  font-weight: 700;
}

.money {
  font-size: 14px;
  margin-top: 4px;
}

.same-line {
  display: flex;
  margin-right: 2px;
}

.select-info {
  color: white;
  font-weight: 700;
  font-size: 15px;
  margin-left: 4px;
}
.form-check {
  display: block;
  position: relative;
  // margin-left: -8px;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;

  .checkmark {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 3px;
  }
}

.see-profile a {
  opacity: 0;
  &:hover {
    opacity: 1;
    transition: 0.7s;
    color: #ffffff;
  }
}

</style>
