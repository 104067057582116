<template>
  <div class="c-card-briefing-project">

    <!-- Card briefing project -->
    <div class="card-white rounded card-project bigger d-flex flex-wrap card-professional-admin h-100">
      <div class="col-lg-4 mt-3 mt-lg-0 ml-0 box-thumbs my-2 p-0">
        <img v-if="user.avatar" :src="'/' + user.avatar" alt="Card image" class="thumb-project">
        <img v-else src="/images/avatar.jpg" alt="Card image" class="thumb-project">
      </div>
      <div class="col-lg-8 pr-lg-0 pt-5 pt-lg-0 h-100 d-flex flex-column">
        <div class="">
          <h2>{{ $t("dash.projeto") }} {{ project }}</h2>
          <h3>{{ user.name }}</h3>
          <p>{{ user.phone }}</p>
          <p>{{ user.email }}</p>
          <a v-if="user.profile" :href="user.profile.site" target="_blank">
            <p class="underline">{{ user.profile.site }}</p>
          </a>
        </div>
        <div class="mt-auto">
          <h5 v-if="Object.getOwnPropertyNames(user.network).length" class="pb-3 d-none d-lg-block">
            {{ $t("dash.conexoes") }}</h5>
          <div class="d-lg-flex justify-content-between">
            <div v-if="user.network" class="text-lg-left">
              <a v-if="user.network.behance" :href="'https://www.behance.net/' + user.network.behance" target="_blank">
                <div class="circle-icons terciary d-inline-block">
                  <i class="ico-social ico-behance"></i>
                </div>
              </a>
              <a v-if="user.network.instagram" :href="'https://www.instagram.com/' + user.network.instagram" target="_blank">
                <div class="circle-icons terciary d-inline-block">
                  <i class="ico-social ico-intagram"></i>
                </div>
              </a>
              <a v-if="user.network.youtube" :href="'https://www.youtube.com/' + user.network.youtube" target="_blank">
                <div class="circle-icons terciary d-inline-block">
                  <i class="ico-social ico-youtube"></i>
                </div>
              </a>
              <a v-if="user.network.linkedin" :href="'https://www.linkedin.com/in/' + user.network.linkedin" target="_blank">
                <div class="circle-icons terciary d-inline-block">
                  <i class="ico-social ico-linkedin"></i>
                </div>
              </a>
            </div>

            <div class="col-xl-5 col-sm-6 card-buttons px-0 d-flex flex-wrap float-right">
              <a v-if="url != '#'" :href="url" target="_blank" class="btn-icons primary mr-3"><i class="icons ico-chat-light"></i></a>
              <router-link :to="'/c/' + user.id + '/' + user.name.split(' ')[0]" class="btn btn-primary rounded" target="_blank">{{ $t("dash.profile") }}</router-link>
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['company', 'user', 'project', 'url'],
    mounted() {
      console.log(this.user);
    }
  }
</script>
