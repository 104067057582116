<template>
  <div class="c-card-briefing-money">

    <!-- Card Briefing -->
    <div class="card-white card-briefing rounded">
      <div class="circle-icons terciary">
        <i :class="icon"></i>
      </div>
      <div class="content-text my-auto">
        <p>{{ title }}</p>
        <h5>{{ value }}</h5>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['title', 'value', 'icon'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
