<template>
  <div class="c-b-renew">
    <div class="wrapper-timeline b-send-proposal">
      <div class="progress-box">
        <p>{{ date }}</p>
        <h2>{{ $t("dash.renovar") }}</h2>
      </div>
      <div class="box-proposal card-white rounded">
        <div class="col-12 px-0 px-lg-3">
          <form class="d-flex flex-wrap">

            <div class="col-lg-12 px-0 pb-3">
              <h3>{{ $t("dash.renovar_txt") }}</h3>
            </div>

            <div class="form-group has-float-label px-0 col-lg-3 mr-lg-4">
              <i class="icons ico-calendar-dark pointer-events"></i>
              <input type="date" class="form-control" id="new-date-start" v-model="formData.date_start" aria-describedby="emailHelp" placeholder=" " onkeyup="this.setAttribute('value', this.value);">
              <label for="new-date-start">{{ $t("dash.d_inicio") }}</label>
            </div>

            <div class="form-group has-float-label px-0 col-lg-3 mr-lg-4">
              <i class="icons ico-calendar-dark pointer-events"></i>
              <input type="date" class="form-control" id="new-date-end" v-model="formData.date_end" aria-describedby="emailHelp" placeholder=" " onkeyup="this.setAttribute('value', this.value);">
              <label for="new-date-end">{{ $t("dash.d_termino") }}</label>
            </div>

            <div class="form-group has-float-label px-0 col-lg-3 mr-lg-4">
              <input type="text" class="form-control" id="new-value" v-model="formData.value" v-money="money" aria-describedby="emailHelp" placeholder=" " onkeyup="this.setAttribute('value', this.value);">
              <label for="new-value">{{ $t("dash.valor_adicional") }} ...</label>
            </div>

            <div class="form-group has-float-label px-0 col-lg-6 mb-0">
              <textarea-autosize class="form-control" name="message" id="message" v-model="formData.message" :height="58" :max-height="250" placeholder=" " onkeyup="this.setAttribute('value', this.value);" maxlength="1200" />
              <!-- <label for="message">{{ $t("dash.descricao") }}<div class="caracteres d-inline-block"></div></label> -->
              <label for="message">{{ $t("dash.descricao") }} - Limite de caracteres <div class="caracteres limite-descricao d-inline-block"></div
              ></label>
            </div>

            <div class="submit-buttons col-lg-6 text-right px-0 mt-auto">
              <button type="button" class="btn btn-primary m-0 sm-mobile" @click="sendToParent">{{ $t("dash.send") }}</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import {mask} from 'vue-the-mask';

  export default {
    props: ['hiring'],
    data() {
      return {
        date: moment().format('DD/MM/YYYY'),
        formData: {
          date_start: '',
          date_end: '',
          value: 0,
          message: '',
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
        },
      }
    },

    mounted() {

      // limite
      jQuery(document).on("input", "#message", function () {
        var limite = 1200;
        var caracteresDigitados = jQuery(this).val().length;
        var caracteresRestantes = limite - caracteresDigitados;

        if (caracteresRestantes <= 0) {
          var comentario = jQuery("textarea[name=message]").val();
          jQuery("textarea[name=message]").val(comentario.substr(0, limite));
          jQuery(".caracteres").text("(0)");
        } else {
          jQuery(".caracteres").text("- (" + caracteresRestantes + ")");
        }
        if (jQuery("textarea[name=message]").val() == "") {
          jQuery(".caracteres").text("");
        }
      });

    },

    methods: {
      sendToParent() {
        if (!this.formData.date_start) {alert('Escolha uma data de início'); return;}
        if (!this.formData.date_end) {alert('Escolha uma data de término'); return;}
        if (!parseInt(this.formData.value.replace(/\D/g,''))) {alert('Escolha um valor adicional'); return;}
        if (!this.formData.message) {alert('Descreva a nova proposta'); return;}
        this.$emit("renew", this.formData);
      },
    },
  }
</script>
