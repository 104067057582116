<template>
  <div class="c-b-approved">

    <div class="wrapper-timeline b-approved">
      <div class="progress-box">
        <p>{{ formatDate(hiring.contract_date) }}</p>
        <h2>{{ $t("dash.proposta") }}</h2>
      </div>
      <div class="card-white rounded">
        <div class="col-lg-12 px-0 px-lg-3 ">
          <h6 class="mx-1 d-flex align-items-center"><span class="mr-2"><img src="/images/professionals/icons/ico-check-green.svg" alt="" /></span>{{ $t("dash.proposta") }}</h6>
          
          <div class="d-flex align-items-center">
            <p class="mx-1 project">{{ $t("dash.contrato") }} {{ project }} {{ $t("dash.para") }} {{ hiring.company.nome_fantasia ? hiring.company.nome_fantasia : hiring.company.razao }} foi aprovado</p> <span> <div>
          <a  :href="'/dashboard/contract/' + hiring.id" target="_blank" class=""> 
            <p style="color:#7553E2" class="project">baixar contrato</p>
          </a>
        </div>
      </span>
          </div>
          
         <small style="color:#7553E2" class=" cursor-pointer d-flex align-items-center" data-toggle="modal" data-target="#cancel-contract-modal"> <p class="mx-1 ">Houve um problema? Você ainda pode </p>  {{ $t("dash.cancelar_contrato") }}</small>
          <a :href="'/dashboard/contract/' + hiring.id" target="_blank" class="btn-anexos btn btn-outline-primary sm btn-center-icons d-lg-none">
            <!-- <i class="icons ico-file"></i> {{ $t("dash.visualizar_contrato") }} -->
          </a>
        </div>
      </div>
    </div>

    <c-modal id="cancel-contract-modal" :title='$t("dash.cancelar_contrato")'>
      <div class="modal-body black">
        <p v-if="owner">{{ $t("dash.alert_contrato") }} <strong>{{ hiring.provider.name }}</strong>?<br>{{ $t("dash.alert_contrato_2") }}</p> 
        <p v-else>{{ $t("dash.alert_contrato") }} <strong>{{ hiring.company.nome_fantasia }}</strong>?<br>{{ $t("dash.alert_contrato_2") }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">{{ $t("dash.close") }}</button>
        <button type="button" class="btn btn-primary" @click="giveupContract">{{ $t("dash.cancelar_contrato") }}</button>
      </div>
    </c-modal>

  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    props: ['hiring', 'project', 'owner'],
    mounted() {
      console.log('Component mounted.')
    },
    methods: {
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
      },

      giveupContract: function () {
        this.$emit("giveupContract");
      },
    },

  }
</script>

<style lang="scss">
.project{
 font-size: 1rem;
 font-weight: 500;
 line-height: 1.4em;
}
</style>