<template>
  <div class="c-card-client-user">

    <!-- Card client user -->
    <div class="card-white rounded card-team d-flex d-lg-block justify-content-start">
      <div class="box-thumbs ml-0 p-0 mr-3 mr-lg-0">
        <img v-if="user.avatar" :src="'/' + user.avatar" alt="Card image" class="ml-0 thumb-profile-team">
        <img v-else src="/images/avatar.jpg" alt="" class="ml-0 thumb-profile-team">
      </div>
      <div class="top-border-team d-none d-lg-block"></div>
      <div class="my-auto">
        <a :href="'/dashboard/admin/company/'+ user.company_id +'/profile/' + user.id"><h3 class="black text-lg-center mb-0">{{ user.name }}</h3></a>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['user'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
