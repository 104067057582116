<template>
  <aside
    :class="[
      show && 'opened',
      (opened === 'project' || opened === 'external') && 'able-scroll',
      ((opened === 'project' || opened === 'create-project' || opened === 'delete-project' ) && opened !== 'external') && 'dash'
    ]"
  >

    <div ref="content" class="content">

      <div class="close" @click="handleModal">
        <button>
          <img src="/images/modal/close.svg" />
        </button>
      </div>

      <slot />

    </div>
  </aside>
</template>

<script>
import { TweenMax } from "gsap";

export default {
  name: "Modal",
  props: {
    opened: {
      type: String,
      default: null
    },
    animation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    opened() {
      const doc = document.querySelector("html");
      doc.style.overflow = this.opened ? "hidden" : "auto";
      this.show = !!this.opened;
    },
    animation() {
      const { content } = this.$refs;
      TweenMax.fromTo(
        content,
        0.5,
        {
          opacity: 0
        },
        {
          opacity: 1
        }
      );
    }
  },
  methods: {
    handleModal() {
      const doc = document.querySelector("html");
      doc.style.overflow = "auto";
      this.$emit("close-modal", null);
      this.$emit("scroll", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.dash {
  width: calc(100% - 80px);
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}
aside {
  // font-family: "SF Pro Display";
  z-index: 11;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.95);
  opacity: 0;
  width: 100%;
  padding: 40px 80px;
  height: 100%;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  overflow-y: auto;
  &.opened {
    opacity: 1;
    pointer-events: auto;
  }
  .close {
    position: absolute;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #7f7f7f;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      width: 22px;
      height: 22px;
    }
    button {
      background-color: transparent;
      margin-bottom: 1px;
      border: none;
      &:focus {
        outline: none;
      }
      img {
        transform: translateY(-1px);
      }
    }
    opacity: 0.75;
    &:not(:disabled):hover {
      opacity: 1;
    }
  }
  .content {
    height: calc(100% - 32px);
    max-width: 640px;
    position: relative;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1024px) {
    padding: 16px;
    .close {
      right: 16px;
    }
  }
}
</style>
