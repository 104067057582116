<template>
  <div class="c-b-evaluation">
    <div class="wrapper-timeline b-evaluation">
      <div class="progress-box">
        <p>{{ date }}</p>
        <h2 v-if="owner">{{ $t("dash.avalie") }}</h2>
        <h2 v-else>{{ $t("dash.avalie_empresa") }}</h2>
      </div>
      <div class="card-white rounded">
        <div class="col-lg-12 px-0 px-lg-3">
          <h3>{{ $t("dash.experiencia") }}</h3>
          <div class="rating-briefing my-2">
            <i
              class="ico-rating yellow"
              :class="{ active: form.total > 0 }"
              v-on:click="form.total = 1"
            ></i>
            <i
              class="ico-rating yellow"
              :class="{ active: form.total > 1 }"
              v-on:click="form.total = 2"
            ></i>
            <i
              class="ico-rating yellow"
              :class="{ active: form.total > 2 }"
              v-on:click="form.total = 3"
            ></i>
            <i
              class="ico-rating yellow"
              :class="{ active: form.total > 3 }"
              v-on:click="form.total = 4"
            ></i>
            <i
              class="ico-rating yellow"
              :class="{ active: form.total > 4 }"
              v-on:click="form.total = 5"
            ></i>
          </div>
        </div>
        <div class="col-lg-10 px-0 px-lg-3">
          <div v-if="form.total < 4">
            <h3>{{ $t("dash.deu_errado.errado") }}</h3>
            <p
              class="tag mr-lg-3"
              :class="{ selected: form.communication < 5 }"
              @click="form.communication = form.communication < 5 ? 5 : 1"
            >
              {{ $t("dash.deu_errado.comunicacao") }}
            </p>
            <p
              class="tag mr-lg-3"
              :class="{ selected: form.ponctuality < 5 }"
              @click="form.ponctuality = form.ponctuality < 5 ? 5 : 1"
            >
              {{ $t("dash.deu_errado.pontualidade") }}
            </p>
            <p
              class="tag mr-lg-3"
              :class="{ selected: form.quality < 5 }"
              @click="form.quality = form.quality < 5 ? 5 : 1"
            >
              {{ $t("dash.deu_errado.qualidade") }}
            </p>
            <!-- <p class="tag mr-lg-3">Falta de informação</p> -->
          </div>
        </div>
        <div class="col-lg-2 text-right mt-auto px-0 px-lg-3">
          <button class="btn btn-primary m-0 w-auto" @click="evaluate">
            {{ $t("dash.avaliar") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["owner"],
  data() {
    return {
      date: moment().format("DD/MM/YYYY"),
      form: {
        total: 5,
        communication: 5,
        ponctuality: 5,
        quality: 5,
      },
    };
  },
  methods: {
    evaluate() {
      this.$emit("evaluate", this.form);
    },
  },
};
</script>
