<template>
  <div class="c-b-send-proposal">

    <div class="wrapper-timeline b-send-proposal">
      <div class="progress-box">
        <p>{{ today }}</p>
        <h2>{{ $t("dash.b_send_proposal") }}</h2>
      </div>
      <div class="box-proposal card-white rounded">
        <div class="col-12 px-0 px-lg-3">
          <div class="d-flex flex-wrap">
            <div class="col-lg-5 px-0">
              <div class="form-group has-float-label px-0">
                <input type="text" class="form-control" id="proposta" v-model="formData.value" v-money="money" placeholder=" " onkeyup="this.setAttribute('value', this.value);">
                <label for="proposta">{{ $t("dash.b_minha_proposta") }}</label>
                <div class="invalid-feedback d-block" v-if="errors.value"><small>{{ $t("dash.b_minha_proposta_2") }}</small></div>
              </div>
              <div class="form-group form-check m-0">
                <input type="checkbox" class="form-check-input" id="exampleCheck12" v-model="formData.haveInterest">
                <span class="checkmark"></span>
                <label class="form-check-label" for="exampleCheck12">{{ $t("dash.b_minha_proposta_3") }}.</label>
              </div>
            </div>
            <div class="submit-buttons col-lg-7 text-lg-right px-0 px-lg-0">
              <a :href="'/dashboard/contract/' + hiring + '?professional=' + user.id" target="_blank" class="btn-anexos btn btn-outline-primary w-auto sm btn-center-icons m-0 mb-3 mb-lg-0 mr-3">
                <i class="icons ico-file"></i> {{ $t("dash.visualizar") }} Smart Contract
              </a>
              <button type="button" class="btn btn-outline-primary col-lg-3 m-0 sm-mobile w-auto" @click="refuseProposal">{{ $t("dash.recusar") }}</button>
              <button type="button" class="btn btn-primary col-lg-3 ml-3 sm-mobile w-auto" :disabled="!formData.haveInterest" @click="acceptProposal">{{ $t("dash.aceitar") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: ['hiring', 'user', 'pivot'],
  data() {
    return {
      formData: {
        haveInterest: true,
        value: 0,
      },
      today: "",
      errors: {
        value: false,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
    }
  },
  mounted() {
    if (this.user.currency == 'eur') {
      this.money.prefix = '€ ';
    }
    if (this.user.currency == 'usd') {
      this.money.prefix = '$ ';
    }
    this.today = moment(moment.now()).format('DD/MM/YYYY');
    if (this.pivot.value && this.pivot.value > 0) this.formData.value = this.pivot.value;
  },

  methods: {
    refuseProposal: function () {
      this.formData.decision = 3;
      this.$emit("sendProposal", this.formData);
    },

    acceptProposal: function () {
      this.errors.value = false;
      if (this.formData.value <= 0 || this.formData.value == "R$ 0,00") {
        this.errors.value = true;
        return;
      }
      this.formData.decision = 2;
      this.$emit("sendProposal", this.formData);
    },
  },
}
</script>

<!-- <style lang="scss" src="./home.scss"></style> -->
