<template>
  <div class="c-b-payment">

    <div class="wrapper-timeline b-payment">
      <div class="progress-box">
        <p>{{ formatDate(hiring.date_start) }}</p>
        <h2>{{ $t("dash.pagamento") }}</h2>
      </div>
      <div class="box-payment card-white rounded">

        <div v-if="!showUpload" class="col-lg-3 border-right col-6 px-0 px-lg-3">
          <p class="txt-briefing">{{ $t("dash.status") }}</p>
          <h3>{{ providerInvoice.status == 2 ? $t("dash.pagamento_3") : $t("dash.pagamento_1") }}</h3>
        </div>
        <div v-if="!showUpload" class="col-lg-3 col-6 px-0 px-lg-3">
          <p class="txt-briefing">{{ $t("dash.valor") }}</p>
          <!-- <h3>{{ toCurrency(hiring.approved_value) }}</h3> -->
          <h3 v-if="hiring.provider_id == user.id">{{ hiring.approved_value ? toCurrency(hiring.approved_value) : toCurrency(hiring.value) }} </h3>
          <h3 v-else>{{ hiring.approved_value ? toCurrency(costByPlan(hiring.approved_value, hiring.plan)) : toCurrency(hiring.value) }} </h3>
        </div>
        <div v-if="!showUpload" class="offset-lg-2 col-lg-4 text-right px-0 px-lg-3">
          <a v-if="providerInvoice.id" :href="'/' + providerInvoice.url" download class="btn-anexos btn btn-outline-primary sm btn-center-icons w-auto">
            <i class="icons ico-file"></i>Baixar nota fiscal
          </a>
          <div v-else-if="canUpload" @click="showUpload = !showUpload" class="btn-anexos btn btn-outline-primary sm btn-center-icons w-auto">
            <i class="icons ico-file"></i> {{ $t("dash.nota") }}
          </div>
        </div>

        <div class="d-block w-100">
          <div v-if="showUpload" class="row">
            <div class="col-12">
              <div class="form-group has-float-label">
                <input type="file" id="invoice" ref="invoice" @change="handleInvoiceUpload" class="form-control"/>
                <label for="invoice">{{ $t("dash.anexo_b") }}</label>
              </div>
              <div class="float-right d-flex flex-wrap d-lg-block justify-content-center">
                <button type="button" @click="sendInvoice()" class="btn btn-primary fit-content m-0 ml-lg-3 mb-lg-3 mb-4 mt-lg-4 order-3">{{ $t("dash.send") }}</button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!canUpload && hiring.provider_id != user.id" class="offset-lg-2 col-lg-4 text-right px-0 px-lg-3">
          <a v-if="creatorInvoice.id" :href="'/' + creatorInvoice.url" download class="btn-anexos btn btn-outline-primary sm btn-center-icons w-auto">
            <i class="icons ico-file"></i> {{ creatorInvoice.name }}
          </a>
        </div>

        <div v-if="hiring.billing && hiring.provider_id != user.id" class="offset-lg-2 col-lg-4 text-right px-0 px-lg-3">

            <a v-if="hiring.billing.wpo_boleto" :href="'https://pagar.goboleto.com/?hash=' + hiring.billing.wpo_boleto" class="btn-anexos btn btn-outline-primary sm btn-center-icons w-auto" target="_blank">
                <i class="icons ico-file"></i> {{ $t("dash.boleto") }}
            </a>

            <a v-if="hiring.billing.wpo_pix" :href="'https://pagar.goboleto.com/?hash=' + hiring.billing.wpo_pix" class="btn-anexos btn btn-outline-primary sm btn-center-icons w-auto" target="_blank">
                <i class="icons ico-file"></i> PIX
            </a>

        </div>


      </div>
    </div>

  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    props: ['hiring', 'project', 'user'],
    data() {
      return {
        showUpload: false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
        },
        formInvoice: {
          name: '',
          value: this.hiring.approved_value ? this.toCurrency(this.hiring.approved_value) : 0,
          date: moment().format('YYYY-MM-DD'),
          file: '',
          hiring_id: this.hiring.id,
        },
      }
    },
    mounted() {
      // console.log('Component mounted.');
    },
    computed: {
      status() {
        if (!this.hiring.invoices || !this.hiring.invoices.length) return false;
        this.hiring.invoices.forEach(invoice => {
          if (invoice.status != 2) return false;
        });
        return true;
      },

      providerInvoice() {
        let invoice = {};
        if (this.hiring.invoices.length) {
          let found = this.hiring.invoices.find((e) => {return e.target == 'provider'});
          if (found) invoice = found;
        }
        return invoice;
      },

      creatorInvoice() {
        let invoice = {};
        if (this.hiring.invoices.length) {
          let found = this.hiring.invoices.find((e) => {return e.target == 'nosotros'});
          if (found) invoice = found;
        }
        return invoice;
      },

      canUpload() {
        if (this.user.id != this.hiring.provider_id) return false;
        if (this.providerInvoice.id) return false;
        return true;
      },
    },
    methods: {
      formatDate(date) {
        return moment(date).add(1, 'd').format('DD/MM/YYYY');
      },

      toCurrency(val) {
        let value = val * 1;
        let currency = "BRL";
        if (this.user.id != this.hiring.provider_id && this.hiring.company.currency == "eur") {
          value = val / this.$store.state.currencies.eur;
          currency = "EUR";
        }
        if (this.user.id != this.hiring.provider_id && this.hiring.company.currency == "usd") {
          value = val / this.$store.state.currencies.usd;
          currency = "USD";
        }
        return (value/100).toLocaleString("pt-BR", {style: "currency", currency: currency, minimumFractionDigits: 2});
      },

      costByPlan(value, plan) {
        switch (plan) {
          case 1:
            value = value / 0.85;
            break;
          case 2:
            value = value / 0.85;
            break;
          case 3:
            value = value / 0.75;
            break;
          case 4:
            value = value / 0.8928571429;
            break;
          case 5:
            value = value;
            break;
          case 6:
            value = value / 0.70;
            break;
          case 7:
            value = value / 0.80;
            break;
          default:
          value = value / 0.85;
        }

        // Pernod Ricard tem 0,6% sobre o valor final
        if (this.hiring.company_id == 159) value = value * 1.006;

        return value;
      },

      sendInvoice() {
        this.$emit('sendInvoice', this.formInvoice);
      },

      handleInvoiceUpload(ev) {
        this.formInvoice.file = this.$refs.invoice.files[0];
      },
    },
  }
</script>

<!-- <style lang="scss" src="./home.scss"></style> -->
