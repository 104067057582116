<template>
  <div class="main">
    <div class="board">
      <div class="icon">
        <img src="/images/professionals/icons/thumbs-up.svg" alt="" />
      </div>
      <div>
        <span class="value">{{ network.youtube_likes }}</span>
        <span class="name">Média de likes</span>
      </div>
    </div>
    <div class="board">
      <div class="icon">
        <img src="/images/professionals/icons/eye-password.svg" alt="" />
      </div>
      <div>
        <span class="value">{{ network.youtube_views }}</span>
        <span class="name">Média de views</span>
      </div>
    </div>
    <div class="board">
      <div class="icon">
        <img src="/images/professionals/icons/chat-baloon.svg" alt="" />
      </div>
      <div>
        <span class="value">{{ network.youtube_comments }}</span>
        <span class="name">Média de comentários</span>
      </div>
    </div>
    <div class="board">
      <div class="icon">
        <img src="/images/professionals/icons/users.svg" alt="" />
      </div>
      <div>
        <span class="value">{{ network.youtube_engagement }}%</span>
        <span class="name">Engajamento</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'network', 'current'],
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
  background: #ffffff;
  border-radius: 16px;
  padding: 32px 50px;

  .board {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .icon {
      flex-shrink: 0;
      margin-bottom: 8px;
    }

    & > div {
      display: flex;
      flex-direction: column;

      .value {
        font-family: SF Pro Display;
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 8px;
      }

      .name {
        font-family: SF Pro Display;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main {
    width: 360px;
    background: none;
    flex-direction: column;
    padding: 0;

    .board {
      padding: 32px;
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #f0f0f0;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
