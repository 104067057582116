<template>
  <div class="c-b-pending">

    <div class="wrapper-timeline b-proposal">
      <div class="progress-box">
        <p>{{ date }}</p>
        <h2>Creator contratado/a</h2>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['date'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
