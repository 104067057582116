<template>
  <div class="c-card-client-clean-dash">

    <!-- Card avaliação -->
    <div class="card-white card-project rounded gradient-bg">
    <p class="h2">Trabalhe agora com alguns dos creators <i class="emoji-star"></i> mais reconhecidos do mercado.</p>
    <router-link to="/dashboard/client/new-briefing" class="btn btn-primary">Iniciar briefing</router-link>
    <a href="#" class="btn btn-outline-primary" data-toggle="modal" data-target="#add-new-member-modal">Adicionar usuário</a>
    </div>

    <c-modal id="add-new-member-modal" title="Adicione novos usuários – sua equipe e colegas também podem acessar sua conta Creators">

      <div class="modal-body black">

          <div class="row">

            <div class="col-lg-6">
              <div class="form-group has-float-label">
                <input type="text" class="form-control" v-bind:class="{'is-invalid':newUser.errorName}" id="newuser-name" placeholder=" " onkeyup="this.setAttribute('value', this.value);" v-model="newUser.name" @change="newUser.errorName = false">
                <label for="newuser-name">Nome completo</label>
                <div v-if="newUser.errorName" class="invalid-feedback d-block"><small>{{ newUser.errorName }}</small></div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group has-float-label">
                <input type="text" class="form-control" v-bind:class="{'is-invalid':newUser.errorEmail}" id="newuser-email" placeholder=" " onkeyup="this.setAttribute('value', this.value);" v-model="newUser.email" @change="newUser.errorEmail = false">
                <label for="newuser-email">E-mail</label>
                <div class="invalid-feedback d-block" v-if="newUser.errorEmail"><small>{{ newUser.errorEmail }}</small></div>
              </div>
            </div>

            <div class="col-lg-3 offset-lg-9">
              <button type="button" class="btn btn-primary fit-content float-right" v-on:click="addUserToCompany">Enviar convite</button>
            </div>

          </div>

      </div>
      <div class="modal-footer">
      </div>

    </c-modal>

  </div>
</template>

<script>
import Axios from 'axios';

export default {
  data() {
    return {
      company: {
      },
      newUser: {
        name: '',
        email: '',
        errorName: false,
        errorEmail: false,
      }
    }
  },

  mounted() {
    let _this = this;

    const TOKEN = localStorage.getItem('token');
    const COMPANY = {id: 1};

    axios
      .get('/api/v1/companies/' + COMPANY.id, {
        headers: {
          Authorization: 'Bearer ' + TOKEN,
        }
      })
      .then(response => {
        _this.company = response.data.data;
    });

  },

  methods: {
    addUserToCompany: function() {

      let _this = this;

      const TOKEN = this.$store.state.token;

      let data = {
        name: _this.newUser.name,
        email: _this.newUser.email,
      }
      // valida os dados do funcionário
      if (data.name == '') {
        _this.newUser.errorName = 'Campo obrigatório.';
      } else {
        _this.newUser.errorName = false;
      }
      if (data.email == '') {
        _this.newUser.errorEmail = 'Campo obrigatório.';
      } else {
        _this.newUser.errorEmail = false;
      }
      if (_this.newUser.errorName || _this.newUser.errorEmail) {
        return;
      }
      // manda os dados do novo funcionário da empresa
      axios
        .post('/api/v1/companies/' + _this.company.id + '/add-user', data, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            // deu erro, avisa quais foram os erros
            if (typeof response.data.error.name != 'undefined') _this.newUser.errorName = response.data.error.name.join(' ');
            if (typeof response.data.error.email != 'undefined') _this.newUser.errorEmail = response.data.error.email.join(' ');
          } else {
            // deu certo, atualiza a lista da equipe da empresa
            axios
              .get('/api/v1/companies/' + _this.company.id, {
                headers: {
                  Authorization: 'Bearer ' + TOKEN,
                }
              })
              .then(response => {
                _this.company = response.data.data;
            });
            // tudo certo, fecha o modal
            $('#add-new-member-modal').modal('hide');

          }
      });

    }
  }
}
</script>
