<template>
  <button :type="submit ? 'submit' : 'button'" :class="type" v-bind="$props">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    submit: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true,
      default: "default"
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 20px;
  &:focus {
    outline: none;
  }
  &.primary {
    background-color: #7053d9;
  }
  &.primary-light {
    background-color: #B59DFF;
  }
  &.secondary {
    background-color: #373737;
  }
}
</style>
