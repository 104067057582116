<template>
  <div class="c-card-admin-user">
    <h3 class="card-title my-3 w-100" :class="{ active: user.active }" v-on:click="user.active = !user.active">
      <div class="col-lg-1">
        <a :href="'/dashboard/admin/users/' + user.id" target="_blank">
          <img
            v-if="user.avatar"
            :src="'/' + user.avatar"
            alt="Card image"
            class="thumb-user mr-2 hover-opacity"
          />
          <img
            v-else
            src="/images/avatar.jpg"
            class="thumb-user mr-2 hover-opacity"
          />
        </a>
      </div>
      <div class="card-title-text my-lg-auto mb-3 d-flex">

        <div class="d-inline-block vertical-align-middle ml-2">
          <a :href="'/dashboard/admin/users/' + user.id" target="_blank">
            <h5 class="m-0 hover-opacity" v-if="user.last_name">{{ user.name + " " + user.last_name }}</h5>
            <h5 class="m-0 hover-opacity" v-else>{{ user.name }}</h5>
            <p style="font-weight: normal;">{{ (user.profile.perfil) ? user.profile.perfil : '' }}</p>
          </a>
          <!-- <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green selected"></i>
          <i class="ico-star-green"></i> -->
        </div>

        <a :href="'//creators.llc/c/' + user.id + '/' + ((user.name) ? user.name.split(' ')[0] : 'Creator')" class="float-right purple" style="line-height: 0.9em;" target="_blank">
          <i class="fas fa-link ml-2" style="font-size: 0.7em;" aria-hidden="true"></i>
        </a>

      </div>
      <div
        class="card-text-header col-lg-3 col-6"
        v-if="!user.active"
      >
        <h5>Habilidade principal</h5>
        <p>{{ user.profile.major }}</p>
      </div>
      <div
        class="card-text-header col-lg-3 col-6"
        v-if="!user.active"
      >
        <h5>Cidade</h5>
        <p>
          {{
            user.profile.city ? user.profile.city.name : user.profile.city_open
          }}
        </p>
      </div>
    </h3>

    <div v-bind:class="{ collapse: true, show: user.active }">
      <div class="d-flex flex-wrap box-description">

        <div class="col-12 mb-3 p-0 card-text-header txt-login">
          <p @click="loginAsUser" class="black underline d-none d-lg-block">
            Logar como usuário
          </p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="">Status do perfil</h5>
          <div class="row">
            <div class=" col-2">
              <input 
                class="ml-2 offscreen" type="checkbox" :id="user.id" 
                v-model="editor.statusProfile" 
                @change="editStatusProfile(user.id)"
              >
              <label :for="user.id" class="switch"></label>
            </div>            
            <div class="col-9">
              <p class="" v-if="editor.statusProfile == 1">Ativo</p>
              <p class="" v-if="editor.statusProfile == 0">Inativo</p>
            </div>
          </div>
      </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5>Creator desde</h5>
          <p class="">{{ formatDate(user.created_at) }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">E-mail</h5>
          <a class="d-inline-block d-lg-block" href="#"
            ><p class="underline"><a :href="'mailto:' + user.email">{{ user.email }}</a></p></a
          >
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">CPF</h5>
          <p class="d-inline-block d-lg-block pb-3">{{ user.profile.cpf }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="">Site</h5>
          <p class="mb-0">
            <a :href="user.profile.site" target="_blank">{{ user.profile.site }}</a>
          </p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">Telefone</h5>
          <p class="mb-0 d-inline-block d-lg-block" v-if="user.profile.phone"><a :href="'https://api.whatsapp.com/send?phone='  + user.profile.phone.replace(/[\s\+\-\(\)]/g, '') " target="_blank">{{ user.profile.phone }}</a></p>
          <p v-else>&nbsp;</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">RG</h5>
          <p class="mb-0 d-inline-block d-lg-block">{{ user.profile.rg }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">CNPJ</h5>
          <p class="mb-0 d-inline-block d-lg-block">{{ user.legal.cnpj }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="pr-2 pr-lg-0 d-inline-block d-lg-block">Origem</h5>
          <p class="mb-0 d-inline-block d-lg-block">{{ (user.origin) ? user.origin.charAt(0).toUpperCase() + user.origin.slice(1) : 'Creators' }}</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile">Habilidade principal</h5>
          <p class="txt-briefing">{{ user.profile.major }}</p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile">Estado</h5>
          <p class="txt-briefing">
            {{ user.profile.state_open ? user.profile.state_open : "-" }}
          </p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile">Curador</h5>
          <p class="txt-briefing">
            {{ user.profile.curator_code ? user.profile.curator_code : "-" }}
          </p>
        </div>
        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">Status</h5>
          <p v-if="user.status_id == 1">Aguardando</p>
          <p v-if="user.status_id == 2">Aprovado</p>
          <p v-if="user.status_id == 3">Reprovado</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">Projetos ativos</h5>
          <p>{{ user.selected_hirings.length }} projetos</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">Cidade</h5>
          <p>
            {{
              user.profile.city
                ? user.profile.city.name
                : user.profile.city_open
            }}
          </p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile d-inline-block">ID</h5>
          <p>#{{ user.id }}</p>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <i v-if="averageReviews * 1" class="ico-star-green selected"></i>
          <h5
            v-if="averageReviews * 1"
            class="f-mobile d-inline-block ml-3 pl-2"
          >
            {{ averageReviews }} estrelas
          </h5>
        </div>

        <div class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile">Redes Socias</h5>
          <div v-if="user.network">
            <a
              v-if="user.network.behance"
              :href="'https://www.behance.net/' + user.network.behance"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="icons ico-behance"></i>
              </div>
            </a>
            <a
              v-if="user.network.instagram"
              :href="'https://www.instagram.com/' + user.network.instagram"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="icons ico-intagram"></i>
              </div>
            </a>
            <a
              v-if="user.network.youtube"
              :href="'https://www.youtube.com/' + user.network.youtube"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="icons ico-youtube"></i>
              </div>
            </a>
            <a
              v-if="user.network.linkedin"
              :href="'https://www.linkedin.com/in/' + user.network.linkedin"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="icons ico-linkedin"></i>
              </div>
            </a>
            <a
              v-if="user.network.vimeo"
              :href="'https://www.vimeo.com/' + user.network.vimeo"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="fab fa-vimeo social-media-icon"></i>
              </div>
            </a>
            <a
              v-if="user.network.tiktok"
              :href="'https://www.tiktok.com/@' + user.network.tiktok"
              target="_blank"
            >
              <div class="circle-icons terciary mr-2">
                <i class="icons ico-tiktok"></i>
              </div>
            </a>

            <!-- <a v-if="network.vimeo" :href="'https://www.vimeo.com/' + network.vimeo" target="_blank" class="vimeo mr-3">
              <i class="fab fa-vimeo social-media-icon"></i>
            </a> -->
          </div>
        </div>

        <div class="col-12 mb-lg-4 mb-3 px-0 pr-lg-3">

          <h5 class="pb-3 d-inline-block">Habilidades</h5>
          <i class="ico-edit-dark ml-3" @click="openEditor()"></i>

          <div class="text-center text-lg-left" v-if="!editor.specialities">
            <div class="mb-2 mr-2 d-inline-block d-lg-block">
              <p class="tag selected">{{ user.profile.major }}</p>
            </div>

            <div
              class="mb-2 mr-2 d-inline-block"
              v-for="speciality in user.specialities"
              :key="speciality.id"
            >
              <p class="tag" v-if="speciality.pt != user.profile.major">{{ speciality.pt }}</p>
            </div>
          </div>

          <div v-else>

            <div class="form-group has-float-label">
              <input
                type="text"
                class="form-control"
                id="major"
                placeholder=" "
                onkeyup="this.setAttribute('value', this.value);"
                list="major-list"
                autocomplete="off"
                v-model.lazy="formSpecialities.majorInput"
                @change="validateList"
              />
              <label for="major">Habilidade principal</label>
              <datalist id="major-list">
                <option v-for="speciality in specialities" :key="speciality.id">
                  {{ speciality.pt }}
                </option>
              </datalist>
            </div>
            <p>
              <span class="tag mb-2">{{ formSpecialities.major.pt }}</span>
            </p>

            <div class="form-group has-float-label">
              <input
                type="text"
                class="form-control"
                id="complementares"
                placeholder=" "
                onkeyup="this.setAttribute('value', this.value);"
                list="complementares-list"
                autocomplete="off"
                v-model.lazy="formSpecialities.specialitiesInput"
                @change="validateListComplementares"
              />
              <label for="complementares">Habilidades</label>
              <datalist id="complementares-list">
                <option v-for="speciality in specialities" :key="speciality.id">
                  {{ speciality.pt }}
                </option>
              </datalist>
              <div
                class="invalid-feedback d-block"
                v-if="errors.specialities && errors.specialities.length"
              >
                <small>{{ errors.specialities[0] }}</small>
              </div>
              <div class="my-3">
                <span
                  class="tag mr-2"
                  v-for="s in formSpecialities.specialities"
                  :key="s.id"
                >
                  {{ s.pt }}
                  <i
                    class="ico-close grey"
                    v-on:click="removeSpeciality(s)"
                  ></i>
                </span>
              </div>

            </div>

            <i class="icons ico-check d-inline-block" @click="editSpecialities()"></i>

          </div>


        </div>

        <div v-if="user.admin" class="col-lg-3 col-6 mb-lg-4 mb-3 px-0 pr-lg-3">
          <h5 class="f-mobile">Avaliado por</h5>
          <p>
            {{ user.admin.name }}
            <br>
            Em {{ formatDate(user.approved_at) }}
          </p>
        </div>

        <div class="col-12 px-0" v-if="!user.admin">
          <div class="row">
            <div class="col-lg-8">
              <div
                class="form-group has-float-label p-0"
                v-if="!user.feedback.sent"
              >
                <input
                  :id="'feedback-text' + user.id"
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  v-model="formFeedback.body"
                  placeholder=" "
                  onkeyup="this.setAttribute('value', this.value);"
                />
                <label :for="'feedback-text' + user.id">Escreva aqui um feedback</label>
              </div>
              <div class="p-0" v-else><p>Feedback foi enviado!</p></div>
            </div>
            <div class="col-lg-4">
              <button
                @click="sendFeedback()"
                class="btn btn-outline-primary fit-content float-right m-0 mr-1 d-inline-block"
              >
                {{
                  sync && !user.feedback.sent
                    ? "Enviando ..."
                    : "Enviar feedback"
                }}
              </button>
            </div>

            <div class="col-lg-12" style="font-size: 0.9em">
              <div v-for="feedback in user.comments">
                <p class="mt-2">Data: {{ feedback.created_at }}</p>
                <p class="mb-2">{{ feedback.comment }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 text-right mb-3">
              <button
                v-if="user.status_id != 2"
                @click="approve"
                class="btn btn-primary fit-content float-right m-0 mr-3 d-inline-block"
              >
                Aprovar
              </button>
              <button
                v-if="user.status_id != 3"
                @click="repprove"
                class="btn btn-outline-primary fit-content float-right m-0 mr-1 d-inline-block"
              >
                Reprovar
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 px-0 d-flex flex-wrap" v-else>
          <div class="col-lg-6 p-0"></div>

          <div
            class="col-lg-6 d-lg-block d-flex flex-wrap justify-content-center px-0 pb-2 mb-4 mb-lg-0"
          >
            <button
              @click="demote"
              class="btn btn-primary fit-content float-right m-0 mr-3 d-inline-block"
            >
              Remover privilégio
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .switch{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px; 
    background: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
  }
  .switch::after{
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background: #fff;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }
  input[type='checkbox']:checked + .switch::after{
    transform: translateX(20px);
  }
  input[type='checkbox']:checked + .switch{
    background: #7553e2;
  }
  .offscreen{
    position: absolute;
    left: -9999px;
  }
</style>
<script>
import moment from "moment";
export default {
  props: ["user"],

  data() {
    return {
      sync: false,
      formFeedback: {
        user_id: this.user.id,
        body: "",
      },
      specialities: [],
      editor: {
        specialities: false,
        statusProfile: this.user.profile_status
      },
      formSpecialities: {
        major: {id: null, pt: ""},
        majorInput: "",
        specialities: [],
        specialitiesInput: "",
      },
      errors: {
        specialities: [],
      },
    };
  },

  mounted() {
    axios
    .get('/api/v1/specialities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.specialities = response.data.data;
      }
    });
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    approve() {
      this.$emit("approveUser", this.user.id);
    },

    repprove() {
      this.$emit("repproveUser", this.user.id);
    },

    demote() {
      this.$emit("demote", this.user.id);
    },

    loginAsUser() {
      this.$emit("loginAsUser", this.user.id);
    },

    openEditor() {
      this.formSpecialities.major.pt = this.user.profile.major;
      this.formSpecialities.specialities = this.user.specialities.filter(s => s.pt != this.user.profile.major);
      this.editor.specialities = true;
    },

    editSpecialities() {
      if (this.formSpecialities.specialities.length < 1) {
        this.errors.specialities.push("Escolha no mínimo 1 habilidade complementar.");
        return;
      } else this.errors.specialities = [];
      let specialities = [];
      for (let i = 0; i < this.formSpecialities.specialities.length; i++) {
        specialities.push(this.formSpecialities.specialities[i].id);
      }
      // if (this.formSpecialities.major.id) specialities.push(this.formSpecialities.major.id);
      var data = { id: this.user.id, specialities: specialities, mainspeciality: (this.formSpecialities.major) ? this.formSpecialities.major.pt : '' };
      this.editor.specialities = false;
      this.$emit("updateSpecialities", data);
    },

    validateList(e) {
      let text = this.formSpecialities.majorInput;
      let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        let selected = this.specialities[index];
        this.formSpecialities.major = selected;
        this.formSpecialities.majorInput = "";
      }
    },

    validateListComplementares(e) {
      let text = this.formSpecialities.specialitiesInput;
      let index = this.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      let double = this.formSpecialities.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        let selected = this.specialities[index];
        // não está ainda na lista selecionada de habilidades
        if (double < 0) this.formSpecialities.specialities.push(selected);
        this.formSpecialities.specialitiesInput = "";
      }
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.formSpecialities.specialities.indexOf(s);
      if (index > -1) {
        this.formSpecialities.specialities.splice(index, 1);
      }
    },

    sendFeedback() {
      let _this = this;
      _this.sync = true;
      if (!this.formFeedback.body.length) {
        alert("Texto de feedback obrigatório");
        return;
      }
      this.$emit("sendFeedback", this.formFeedback);
      this.formFeedback.body = "";
      setTimeout(() => {
        _this.sync = false;
      }, 600);
    },
    editStatusProfile(id){
      if(this.user.profile_status == 1){
        this.user.profile_status = 0
      } else if(this.user.profile_status == 0){
        this.user.profile_status = 1
      }
      var data = { user_id: this.user.id, profile_status: this.user.profile_status};

      this.$emit("changeStatusProfile", data);
    }
  },

  computed: {
    averageReviews() {
      var sum = 0;
      var ammount = 0;
      this.user.received_reviews.forEach((review) => {
        sum += review.total;
        ammount++;
      });
      if (ammount > 0) return (sum / ammount).toFixed(1);
      return 0;
    },
  },
};
</script>
