<template>
  <div class="container">
    <input
      ref="input"
      v-model.lazy="inputValue"
      type="text"
      :class="value.length && 'filled'"
      placeholder=" "
      list="list"
      autocomplete="off"
      @change="validateOption"
    />
    <label :for="name">{{ label }}</label>
    <datalist id="list">
      <option v-for="option in options" :key="option" :name="name" :value="option">{{ option }}</option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'Single'
    },
    value: {
      type: [String, Array],
      required: false,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inputValue: ""
    };
  },
  methods: {
    validateOption(e) {
      const { input } = this.$refs
      if (this.options.find(element => element === e.target.value)) {
        this.$emit("change", this.name, e.target.value);
        this.inputValue = e.target.value
        this.type === "Single" && input.blur()
      } else {
        this.$emit("invalid", this.name);
        this.inputValue = ""
        //Emitir erro para ser apresentado da mesma forma que os outros no compoenente pai
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 50px;
  width: 100%;
  position: relative;
  input {
    border-bottom: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    position: absolute;
    padding: 10px 0 5px 0;
    width: 100%;
    background-color: transparent;
    font-size: 1.1em;
    transition: all 0.2s ease-in-out;
    color: #ddd;
    &:focus,
    &.filled {
      outline: transparent;
      border-bottom: 2px solid #B59DFF;
      + label {
        transform: translateY(-20px);
        font-size: 0.8em;
        color: #B59DFF;
      }
    }
    option {
      color: black;
    }
  }
  label {
    white-space: nowrap;
    position: absolute;
    color: #ffffff;
    padding: 5px 0 10px 0;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }
}
</style>
