<template>
  <div class="c-card-project-hiring">

    <!-- Card project -->
    <div class="card-white rounded card-project">
      <div v-if="!owner">
        <p class="large" v-if="hiring.pivot && !hiring.pivot.value && hiring.status <= 3">{{ $t("dash.enviar") }}</p>
        <p class="large" v-if="hiring.pivot && hiring.pivot.value && hiring.status <= 3">{{ $t("dash.selecao") }}</p>
      </div>
      <div v-else>
        <p class="large" v-if="hiring.status == 1">{{ $t("dash.aprovacao") }}</p>
        <p class="large" v-if="hiring.status == 2">{{ $t("dash.curadoria") }}</p>
        <p class="large" v-if="hiring.status == 3">{{ $t("dash.escolha") }}</p>
      </div>

      <p class="large" v-if="hiring.status == 4 && !done">{{ $t("dash.contratacao") }}</p>
      <p class="large" v-if="hiring.status == 4 && done">{{ $t("dash.contratacao_ended") }}</p>
      <p class="large" v-if="hiring.status == 5">{{ $t("dash.cancelada") }}</p>
      <p class="large" v-if="hiring.status == 6">{{ $t("dash.encerrada") }}</p>
      <p class="large" v-if="hiring.status == 7">{{ $t("dash.encerrada") }}</p>

      <!-- <h3>Brand Logo</h3> -->

      <router-link :to="(owner ? '/dashboard/client/briefing/' : '/dashboard/professional/briefing/') + hiring.id" class="box-thumbs d-block my-2">

        <div v-if="owner && hiring.status < 3">
          <div class="row align-items-center">
            <div class="col-12 py-3 mb-1">
              <p>#{{ hiring.id }} - {{ (hiring.specialities && hiring.specialities[0]) ? hiring.specialities[0].en : '' }}</p>
            </div>
          </div>
        </div>

        <div v-if="owner && hiring.status == 3">
          <div class="row align-items-center">
            <div class="ml-3 py-1">
              <img v-for="provider in firstThree" :src="provider.avatar ? '/' + provider.avatar : '/images/avatar.jpg'" class="thumb-project" :key="provider.id">
            </div>
            <div class="col-9">
              <h5 class="ml-1 d-inline-block">{{ $t("dash.received_proposals") }}</h5>
              <p class="ml-1">#{{ hiring.id }} - {{ (hiring.specialities && hiring.specialities[0]) ? hiring.specialities[0].en : '' }}</p>
            </div>
          </div>
        </div>

        <div v-if="!owner">
          <div class="row align-items-center">
            <div class="col-1 py-1">
              <img v-if="hiring.company && hiring.company.logo" :src="'/' + hiring.company.logo" class="thumb-project">
              <img v-else src="/images/avatar.jpg" class="thumb-project">
            </div>
            <div class="col-10">
              <h5 class="ml-1">{{ hiring.company ? (hiring.company.nome_fantasia ? hiring.company.nome_fantasia : hiring.company.razao) : '' }}</h5>
              <p>#{{ hiring.id }} - {{ (hiring.specialities && hiring.specialities[0]) ? hiring.specialities[0].en : '' }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="hiring.status == 4 && hiring.provider_id && !hiring.squad_id">
          <div class="row align-items-center">
            <div class="col-1 py-1">
              <img v-if="hiring.provider.avatar" :src="'/' + hiring.provider.avatar" class="thumb-project">
              <img v-else src="/images/avatar.jpg" class="thumb-project">
            </div>
            <div class="col-10">
              <h5 class="ml-1">{{ hiring.provider.name }} {{ (hiring.provider.last_name && hiring.provider.last_name !== 'null') ? hiring.provider.last_name : '' }}</h5>
              <p>#{{ hiring.id }} - {{ (hiring.specialities && hiring.specialities[0]) ? hiring.specialities[0].en : '' }}</p>
            </div>
          </div>
        </div>

      </router-link>

      <div class="column-2" v-if="!hiring.squad_id">
        <p class="large">{{ $t("dash.entrega") }}</p>
        <p class="large" v-if="hiring.status <= 3">Budget</p>
        <p class="large" v-if="hiring.status >= 4">{{ $t("dash.verba") }}</p>
      </div>
      <div class="column-2" v-if="!hiring.squad_id">
        <h5 class="grey">{{ formatDate(hiring.date_end) }}</h5>
        <h5 v-if="hiring.status <= 3" class="purple">
          {{ toCurrency((!owner ? (valueByPlan(hiring.value, hiring.plan)) : hiring.value)) }}
        </h5>
        <h5 v-if="hiring.status >= 4" class="purple">
          {{ toCurrency((owner ? (costByPlan(hiring.approved_value, hiring.plan)) : hiring.approved_value)) }}
        </h5>
      </div>

      <router-link :to="(owner ? '/dashboard/client/briefing/' : '/dashboard/professional/briefing/') + hiring.id" class="btn btn-primary sm rounded d-lg-none">{{ $t("dash.ver_projeto") }}</router-link>

    </div>

  </div>
</template>

<script>

  import moment from 'moment';

  export default {
    props: ['hiring', 'owner'],
    data() {
      return {
        me: this.$store.state.me,
      }
    },
    mounted() {
      // console.log(this.hiring);
    },
    methods: {
      formatDate: function (date) {
        return moment(date).format('DD/MM/YYYY');
      },

      toCurrency(val) {
        let value = val * 1;
        let currency = "BRL";
        if ((this.owner && this.hiring.company.currency == "eur") || (!this.owner && this.me.currency == "eur")) {
          value = val / this.$store.state.currencies.eur;
          currency = "EUR";
        }
        if (this.owner && this.hiring.company.currency == "usd" || (!this.owner && this.me.currency == "usd")) {
          value = val / this.$store.state.currencies.usd;
          currency = "USD";
        }
        return (value/100).toLocaleString("pt-BR", {style: "currency", currency: currency, minimumFractionDigits: 2});
      },

      valueByPlan(budget, plan) {
        let value = 0;
        switch (plan) {
          case 1:
            value = budget * 0.85;
            break;
          case 2:
            value = budget * 0.85;
            break;
          case 3:
            value = budget * 0.75;
            break;
          case 4:
            value = budget * 0.8928571429;
            break;
          case 5:
            value = budget;
            break;
          case 6:
            value = budget * 0.70;
            break;
          case 7:
            value = budget * 0.80;
            break;
          default:
          value = budget * 0.85;
        }
        return value;
      },

      costByPlan(value, plan) {
        switch (plan) {
          case 1:
            value = value / 0.85;
            break;
          case 2:
            value = value / 0.85;
            break;
          case 3:
            value = value / 0.75;
            break;
          case 4:
            value = value / 0.8928571429;
            break;
          case 5:
            value = value;
            break;
          case 6:
            value = value / 0.70;
            break;
          case 7:
            value = value / 0.80;
            break;
          default:
          value = value / 0.85;
        }
        return value;
      },

    },

    computed: {

      done() {
        return (moment(this.hiring.date_end) < moment())
      },

      firstThree() {
        var three = [];
        if (this.hiring.providers) {
          if (this.hiring.providers.length >= 3) three = [
            this.hiring.providers[0],
            this.hiring.providers[1],
            this.hiring.providers[2],
          ];
          if (this.hiring.providers.length == 2) three = [
            this.hiring.providers[0],
            this.hiring.providers[1],
          ];
          if (this.hiring.providers.length == 1) three = [
            this.hiring.providers[0],
          ];
        }
        return three;
      },

    },
  }
</script>
