<template>
  <div class="c-card-admin-professional">
    <div class="card-white rounded card-project bigger card-professional-admin">
      <div class="d-lg-flex">
        <div class="p-0 col-lg-2">
          <div class="box-thumb-profile ml-0 p-0 position-relative">
            <img
              v-if="user.avatar"
              :src="'/' + user.avatar"
              alt="Card image"
              class="thumb-profile"
            />
            <img v-else src="/images/avatar.jpg" alt="" class="thumb-profile" />
          </div>
        </div>
        <!-- mobile avaluation and community -->
        <div class="mb-3 d-lg-none d-block">
          <div class="d-flex justify-content-center">
            <div v-if="averageReviews * 1" class="mr-3">
              <div class="d-inline-block">
                <i class="ico-star"></i>
              </div>
              <div class="d-inline-block">
                <h5 class="">{{ averageReviews }}</h5>
              </div>
            </div>
            <div class="">
              <div v-if="communities" class="d-lg-none d-block">
                <div class="d-inline-block">
                  <i class="icons ico-user-purple"></i>
                </div>
                <div class="d-inline-block">
                  <h5 class="">{{ communities }}</h5>
                </div>
              </div>
            </div>
          </div>
          <h5 v-if="user.status_id == 1" class="d-flex justify-content-center">
            Aguardando
          </h5>
          <h5 v-if="user.status_id == 2" class="d-flex justify-content-center">
            Aprovado
          </h5>
          <h5 v-if="user.status_id == 3" class="d-flex justify-content-center">
            Reprovado
          </h5>
        </div>

        <div class="col-12 col-lg-10 d-flex flex-wrap px-0">
          <div class="col-lg-10 px-0">
            <div class="row">
              <div class="col-lg-4 col-12 pr-0">
                <div class="text-center text-lg-left">
                  <h5 class="pb-3">
                    Creator desde {{ formatDate(user.created_at) }}
                  </h5>
                  <h5 class="pb-3 d-inline-block">
                    {{
                      user.profile.city_id
                        ? user.profile.city.name
                        : user.profile.city_open
                    }}, Brasil
                  </h5>
                  <!--  mobile -->
                  <h5 class="mb-0 d-block d-lg-none">-</h5>
                </div>
                <div class="w-100 pr-2 d-block d-lg-none">
                  <hr />
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    E-mail
                  </h5>
                  <a class="d-inline-block d-lg-block" href="#"
                    ><p class="underline pb-3"><a :href="'mailto:' + user.email" target="_blank">{{ user.email }}</a></p></a
                  >
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    CPF
                  </h5>
                  <p class="d-inline-block d-lg-block pb-3">
                    {{ user.profile.cpf }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-12 pr-0">
                <div>
                  <h5 class="pb-3 d-none d-lg-block">Site</h5>
                  <p class="pb-3 mb-0 d-none d-lg-block">
                    <a :href="user.profile.site" target="_blank">{{ user.profile.site }}</a>
                  </p>
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    Telefone
                  </h5>
                  <p class="pb-3 mb-0 d-inline-block d-lg-block" v-if="user.profile.phone">
                    <a :href="'https://api.whatsapp.com/send?phone=' + user.profile.phone.replace(/[\s\+\-\(\)]/g, '') " target="_blank">{{ user.profile.phone }}</a>
                  </p>
                  <p v-else>&nbsp;</p>
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    RG
                  </h5>
                  <p class="pb-3 mb-0 d-inline-block d-lg-block">
                    {{ user.profile.rg }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-12 pr-0">
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    Código de curador
                  </h5>
                  <p class="pb-3 mb-0 d-inline-block d-lg-block">
                    {{
                      user.profile.curator_code
                        ? user.profile.curator_code
                        : "-"
                    }}
                  </p>
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    Data de nascimento
                  </h5>
                  <p class="pb-3 mb-0 d-inline-block d-lg-block">
                    {{ formatDate(user.profile.nascimento) }}
                  </p>
                </div>
                <div>
                  <h5 class="pb-lg-3 pr-2 pr-lg-0 d-inline-block d-lg-block">
                    CNPJ
                  </h5>
                  <p class="pb-3 mb-0 d-inline-block d-lg-block">
                    {{ (user.legal) ? user.legal.cnpj : '-' }}
                  </p>
                </div>
              </div>
              <div class="col-12 d-block d-lg-none">
                <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="">
              <div v-if="averageReviews * 1" class="d-none d-lg-block">
                <div class="d-inline-block float-right">
                  <h5 class="mt-1 ml-2">{{ averageReviews }}</h5>
                </div>
                <div class="d-inline-block float-right">
                  <i class="ico-star mt-0"></i>
                </div>
              </div>
              <div v-if="communities" class="d-none d-lg-block">
                <div class="d-inline-block float-right">
                  <h5 class="mt-1 ml-2">{{ communities }}</h5>
                </div>
                <div class="d-inline-block float-right">
                  <i class="icons ico-user-purple mt-0"></i>
                </div>
              </div>
            </div>
            <h5
              v-if="user.status_id == 1"
              class="d-none d-lg-block float-right"
            >
              Aguardando
            </h5>
            <h5
              v-if="user.status_id == 2"
              class="d-none d-lg-block float-right"
            >
              Aprovado
            </h5>
            <h5
              v-if="user.status_id == 3"
              class="d-none d-lg-block float-right"
            >
              Reprovado
            </h5>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-5 col-12">
          <h5 class="pb-3 d-none d-lg-block">Conexões</h5>
          <div v-if="user.network" class="text-center text-lg-left">
            <a
              v-if="user.network.behance"
              :href="'https://www.behance.net/' + user.network.behance"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-behance"></i>
              </div>
            </a>
            <a
              v-if="user.network.instagram"
              :href="'https://www.instagram.com/' + user.network.instagram"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-intagram"></i>
              </div>
            </a>
            <a
              v-if="user.network.youtube"
              :href="'https://www.youtube.com/' + user.network.youtube"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-youtube"></i>
              </div>
            </a>
            <a
              v-if="user.network.vimeo"
              :href="'https://www.vimeo.com/' + user.network.vimeo"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-vimeo"></i>
              </div>
            </a>

            <a
              v-if="user.network.tiktok"
              :href="'https://www.tiktok.com/@' + user.network.tiktok"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-tiktok"></i>
              </div>
            </a>

            <a
              v-if="user.network.twitch"
              :href="'https://www.twitch.tv/' + user.network.twitch"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-twitch"></i>
              </div>
            </a>
            <a
              v-if="user.network.linkedin"
              :href="'https://www.linkedin.com/in/' + user.network.linkedin"
              target="_blank"
            >
              <div class="circle-icons terciary d-inline-block">
                <i class="ico-social ico-linkedin"></i>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 d-lg-none d-block">
          <hr />
        </div>
        <div class="col-lg-7 col-12 pl-lg-0">
          <div class="d-none d-lg-block">
            <h5 class="pb-3 d-inline-block">Habilidades</h5>
            <i class="ico-edit-dark ml-3" @click="openEditor()"></i>
          </div>


          <div class="text-center text-lg-left" v-if="!editor.specialities">
            <div class="mr-2 d-inline-block d-lg-block">
              <p class="tag selected">{{ user.profile.major }}</p>
            </div>

            <div
              class="mr-2 d-inline-block"
              v-for="speciality in user.specialities"
              :key="speciality.id"
            >
              <p class="tag" v-if="speciality.pt != user.profile.major">{{ speciality.pt }}</p>
            </div>
          </div>

          <div v-else>

            <div class="form-group has-float-label">
              <input
                type="text"
                class="form-control"
                id="major"
                placeholder=" "
                onkeyup="this.setAttribute('value', this.value);"
                list="major-list"
                autocomplete="off"
                v-model.lazy="formSpecialities.majorInput"
                @change="validateList"
              />
              <label for="major">Habilidade principal</label>
              <datalist id="major-list">
                <option v-for="speciality in specialities" :key="speciality.id">
                  {{ speciality.pt }}
                </option>
              </datalist>
            </div>
            <p>
              {{ formSpecialities.major.pt }}
            </p>

            <div class="form-group has-float-label">
              <input
                type="text"
                class="form-control"
                id="complementares"
                placeholder=" "
                onkeyup="this.setAttribute('value', this.value);"
                list="complementares-list"
                autocomplete="off"
                v-model.lazy="formSpecialities.specialitiesInput"
                @change="validateListComplementares"
              />
              <label for="complementares">Habilidades</label>
              <datalist id="complementares-list">
                <option v-for="speciality in specialities" :key="speciality.id">
                  {{ speciality.pt }}
                </option>
              </datalist>
              <div
                class="invalid-feedback d-block"
                v-if="errors.specialities && errors.specialities.length"
              >
                <small>{{ errors.specialities[0] }}</small>
              </div>
              <div class="my-3">
                <span
                  class="tag mr-2"
                  v-for="s in formSpecialities.specialities"
                  :key="s.id"
                >
                  {{ s.pt }}
                  <i
                    class="ico-close grey"
                    v-on:click="removeSpeciality(s)"
                  ></i>
                </span>
              </div>

            </div>

            <i class="icons ico-check d-inline-block" @click="editSpecialities()"></i>

          </div>


        </div>
        <div class="col-12 d-lg-none d-block">
          <hr />
        </div>
        <div class="col-12 my-3">
          <h4>"{{ user.profile.bio }}"</h4>
          <div class="text-center d-block d-lg-none">
            <button
              type="submit"
              @click="loginAsUser"
              class="btn btn-primary w-auto"
            >
              Logar como
            </button>
          </div>
        </div>

        <!-- só se o profissional nao ainda nao foi aprovado -->
        <div class="col-12">
          <div class="row">
            <div class="col-lg-8">
              <div
                class="form-group has-float-label p-0"
                v-if="!user.feedback.sent"
              >
                <input
                  id="comment-body"
                  type="text"
                  class="form-control"
                  v-model="comment.body"
                  placeholder=" "
                  onkeyup="this.setAttribute('value', this.value);"
                />
                <label for="comment-body">Escreva aqui um feedback</label>
              </div>
              <div class="p-0" v-else><p>Feedback foi enviado!</p></div>
            </div>
            <div class="col-lg-4">
              <button
                @click="sendComment"
                class="btn btn-outline-primary fit-content float-right m-0 mr-1 d-inline-block"
              >
                {{
                  sync && !user.feedback.sent
                    ? "Enviando ..."
                    : "Enviar feedback"
                }}
              </button>
            </div>

            <div class="col-lg-12" style="font-size: 0.9em">
              <div v-for="feedback in user.comments">
                <p class="mt-2">Data: {{ feedback.created_at }}</p>
                <p class="mb-2">{{ feedback.comment }}</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-lg-12 d-lg-block d-flex flex-wrap justify-content-center px-0 mb-4 mb-lg-0"
            >
              <button
                @click="sendApprove"
                v-if="user.status_id != 2"
                class="btn btn-primary fit-content float-right m-0 mr-3 d-inline-block"
              >
                Aprovar
              </button>
              <button
                @click="sendReprove"
                v-if="user.status_id != 3"
                class="btn btn-outline-primary fit-content float-right m-0 mr-1 d-inline-block"
              >
                Reprovar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["user"],
  data() {
    return {
      sync: false,
      comment: {
        body: "",
      },
      specialities: [],
      editor: {
        specialities: false,
      },
      formSpecialities: {
        major: {id: null, pt: ""},
        majorInput: "",
        specialities: [],
        specialitiesInput: "",
      },
      errors: {
        specialities: [],
      },
    };
  },

  mounted() {

    axios
    .get('/api/v1/specialities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.specialities = response.data.data;
      }
    });
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    loginAsUser() {
      this.$emit("loginAsUser");
    },

    sendApprove() {
      this.$emit("approveUser", 1);
    },

    sendReprove() {
      this.$emit("approveUser", 2);
    },

    sendComment() {
      let _this = this;
      if (!this.comment.body) alert("Você deve escrever um feedback");
      else {
        _this.sync = true;
        _this.$emit("sendComment", _this.comment);
        _this.comment.body = "";
      }
    },

    openEditor() {
      this.formSpecialities.major.pt = this.user.profile.major;
      this.formSpecialities.specialities = this.user.specialities;
      this.editor.specialities = true;
    },

    editSpecialities() {
      if (this.formSpecialities.specialities.length < 1) {
        this.errors.specialities.push("Escolha no mínimo 1 habilidade complementar.");
        return;
      } else this.errors.specialities = [];
      let specialities = [];
      for (let i = 0; i < this.formSpecialities.specialities.length; i++) {
        specialities.push(this.formSpecialities.specialities[i].id);
      }
      // if (this.formSpecialities.major.id) specialities.push(this.formSpecialities.major.id);
      var data = { id: this.user.id, specialities: specialities, mainspeciality: (this.formSpecialities.major) ? this.formSpecialities.major.pt : '' };
      this.editor.specialities = false;
      this.$emit("updateSpecialities", data);
    },

    validateList(e) {
      let text = this.formSpecialities.majorInput;
      let index = this.specialities.map(function (e) { return e.pt; }).indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        let selected = this.specialities[index];
        this.formSpecialities.major = selected;
        this.formSpecialities.majorInput = "";
      }
    },

    validateListComplementares(e) {
      let text = this.formSpecialities.specialitiesInput;
      let index = this.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      let double = this.formSpecialities.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        let selected = this.specialities[index];
        // não está ainda na lista selecionada de habilidades
        if (double < 0) this.formSpecialities.specialities.push(selected);
        this.formSpecialities.specialitiesInput = "";
      }
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.formSpecialities.specialities.indexOf(s);
      if (index > -1) {
        this.formSpecialities.specialities.splice(index, 1);
      }
    },

  },

  computed: {
    averageReviews() {
      var sum = 0;
      var ammount = 0;
      this.user.received_reviews.forEach((review) => {
        sum += review.total;
        ammount++;
      });
      if (ammount > 0) return (sum / ammount).toFixed(1);
      return 0;
    },

    communities() {
      return this.user.communities.length;
    },
  },
};
</script>
