<template>
  <div class="input-container">
    <input
    v-if="mask"
    v-mask="mask"
    :name="name"
    :type="type"
    :value="value"
    :class="{ filled: value }"
    :autocomplete="autocomplete"
    @keyup="handleChange"
    @focus="handleChange"
    />
    <input
    v-else
    :name="name"
    :type="type"
    :maxlength="maxlength"
    v-model="value"
    :class="{ filled: value }"
    :autocomplete="autocomplete"
    @keyup="handleChange"
    @focus="handleChange"
    />
    <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    mask: {
      type: [String, Array],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    maxlength: {
      type: String,
      required: false,
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', this.name, e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>

.input-container .custom-input input:-webkit-autofill ~ label /* <<<< Add these */
{
  transform: translateY(-32px);
  font-size: 1em;
  color: #B59DFF;
  position: absolute;
  left: 0;
  font-weight: 400;
  padding: 10px 0;
  top: 0;
  pointer-events: none;
}


.input-container {
  position: relative;
  height: 50px;
  input {
    position: absolute;
    left: 0;
    top: 0;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ddd;
    transition: all 0.2s ease-in-out;
    padding: 10px 0 5px 0;
    color: #ffffff;
    width: 100%;
    font-size: 1.25em;
    &:-webkit-autofill {
      transition-delay: 99999s;
      -webkit-transition-delay: 99999s;
    }
    &:focus,
    &.filled {
      outline: none;
      border-bottom: 2px solid #B59DFF;
      + label {
        transform: translateY(-32px);
        font-size: 1em;
        color: #B59DFF;
      }
    }
  }
  label {
    font-size: 1.25em;
    transition: all 0.2s ease-in-out;
    position: absolute;
    left: 0;
    font-weight: normal;
    padding: 10px 0;
    top: 0;
    color: #ffffff;
    pointer-events: none;
  }
}
</style>
