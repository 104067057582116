<template>
  <div class="c-card-admin-mural">
    <div class="card my-4">
      <div class="card-body py-0">

        <h3 class="card-title mural-title my-3 collapse" data-toggle="collapse" data-target="#mural" aria-expanded="false" aria-controls="mural">
          Mural
        </h3>

        <div id="mural" class="collapse">
          <form>
            <div class="row">
              <div class="col-4">
                <div class="form-group has-float-label">
                  <input type="text" class="form-control" id="to" placeholder="   " onkeyup="this.setAttribute('value', this.value);">
                  <label for="to">Enviar para</label>
                </div>
              </div>
              <div class="col-8">
                <a href="#" class="btn btn-sm sm btn-outline-primary w-auto mt-1 mb-2">Mario Andrade <i class="purple ico-close float-none"></i></a>
              </div>

              <div class="col-8">
                <div class="form-group">
                  <textarea class="form-control" name="name" rows="3" cols="80" placeholder="Mensagem"></textarea>
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <input type="button" class="btn btn-primary float-right" value="Enviar">
                </div>
              </div>
            </div>

          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
      mounted() {
          console.log('Component mounted.')
      }
  }
</script>

<style lang="css" scoped>
</style>
