<template>
  <div class="c-card-briefing-project">

    <!-- Card briefing project -->
    <div class="card-white rounded card-project bigger d-flex flex-wrap">
      <div class="col-lg-4 ml-0 box-thumbs my-2 p-0">
        <img v-if="company.logo" :src="'/' + company.logo" alt="Card image" class="thumb-project">
        <img v-else src="/images/avatar.jpg" alt="Card image" class="thumb-project">
      </div>
      <div class="col-lg-8 pr-lg-0 pt-50 pt-lg-0">
        <h2>{{ $t("dash.projeto") }} {{ project }} {{ $t("dash.para") }} {{ company.nome_fantasia }}</h2>
        <h3>{{ user.name }}</h3>
        <p>{{ status == 4 ? user.phone : '' }}</p>
        <p>{{ status == 4 ? user.email : '' }}</p>
        <a :href="company.site ? company.site : '#'" target="_blank">
          <p class="underline">{{ company.site ? company.site : '-' }}</p>
        </a>

        <div class="card-buttons px-0">
          <p><a v-if="url != '#'" :href="url" target="_blank" class="mr-3"> <span class="btn-icons primary"><i class="icons ico-chat-light"></i></span> <small class="pl-2">FALE COM O CLIENTE</small></a></p>
          <!-- <p><a v-if="url != '#'" :href="url" target="_blank" class="mr-3"><small class="pr-2">FALE COM O CLIENTE</small> <span class="btn-icons primary"><i class="icons ico-chat-light"></i></span></a></p> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['company', 'user', 'project', 'url', 'status'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
