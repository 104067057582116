<template>
  <div class="c-card-briefing-entrega">

    <!-- Card Briefing -->
    <div class="card-white card-briefing rounded">
      <div class="circle-icons terciary">
        <i class="ico-calendar"></i>
      </div>
      <div class="content-text my-auto">
        <p>{{ $t("dash.entrega") }}</p>
        <h5>{{ date }}</h5>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    props: ['date'],
    mounted() {
      console.log('Component mounted.')
    }
  }
</script>
