<template>
  <div class="c-card-professional-community">
    <div class="card bg-dark text-white border-0 wrapper-card-img mb-3">
      <img
        v-if="user.avatar"
        :src="'/' + user.avatar"
        alt="Card image"
        class="card-img"
      />
      <img v-else src="/images/avatar.jpg" class="card-img" />
      <div class="card-img-overlay">
        <h5 class="float-left">
          {{ user.name }}
          {{
            user.last_name && user.last_name !== "null" ? user.last_name : ""
          }}
        </h5>
        <h5 v-if="averageReviews * 1" class="float-right rating-text">
          {{ averageReviews }}
        </h5>
        <i v-if="averageReviews * 1" class="ico-star-white"></i>
        <div
          class="text-center d-flex flex-wrap justify-content-center align-items-center w-100 h-85"
        >
          <router-link
            :to="'/c/' + user.id + '/' + user.name.split(' ')[0]"
            target="_blank"
            class="btn p-0 m-0 white w-fit-content"
            >{{ $t("dash.company.newmembers.ver_perfil") }}</router-link
          >
        </div>
        <div class="content-bottom">
          <div v-if="user.profile && user.profile.major" class="box-tags">
            <p class="tag transparent mb-3">{{ user.profile.major }}</p>
          </div>
          <!-- <a href="#" class="btn-icons primary mr-3 mb-0"><i class="icons ico-chat-light"></i></a> -->

          <div
            v-if="!outside"
            @click="removeUser"
            class="w-fit-content delete-circle"
          >
            x
          </div>
          <!-- float-right -->
          <div
            v-else
            @click="addUser"
            class="btn btn-primary sm w-fit-content btn-select-professional"
          >
            {{ $t("dash.company.newmembers.adicionar") }}
          </div>
          <!-- float-right -->
          <!-- <a class="btn btn-primary w-fit-content mt-0 btn-select-professional">Selecionar</a> -->
          <div
            v-if="user.pivot && user.pivot.community_id"
            class="transparent-circle mt-3"
          >
            <i class="icons ico-community"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "outside"],
  mounted() {
    console.log("Component mounted.");
  },

  methods: {
    toCurrency(val) {
      return (val / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      });
    },

    removeUser() {
      this.$emit("removeMember", this.user);
    },

    addUser() {
      this.$emit("addMember", this.user);
    },
  },

  computed: {
    averageReviews() {
      var sum = 0;
      var ammount = 0;
      this.user.received_reviews.forEach((review) => {
        sum += review.total;
        ammount++;
      });
      if (ammount > 0) return (sum / ammount).toFixed(1);
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-circle {
  background-color: rgba(127, 127, 127, 0.5);
  color: #fff;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-top: 16px;
}

.delete-circle:hover {
  background-color: #7553e2;
  cursor: pointer;
}
</style>
