<template>
  <div class="c-card-admin-hiring">
    <div class="card" v-bind:id="'card-number-' + hiring.id">
      <div class="card-body py-0">
        <div
          v-on:click="toggleItem()"
          class="card-title my-3 collapsed"
          :class="{ active: hiring.open }"
        >

          <div class="card-text-header col-lg-3 col-6 px-1 px-lg-0">
            <h5>#{{ hiring.id }}</h5>
            {{ hiring.title }}
          </div>

          <div class="card-text-header col-lg-3 col-6 px-1 px-lg-0" v-if="!hiring.open">
            <h5>
              {{
                hiring.company && hiring.company.nome_fantasia
                  ? hiring.company.nome_fantasia
                  : hiring.company && hiring.company.razao
              }}
            </h5>
            <p>
              {{ hiring.user ? hiring.user.name : "" }}
              {{
                hiring.user && hiring.user.last_name !== "null"
                  ? hiring.user.last_name
                  : ""
              }}
            </p>
          </div>

          <div class="card-text-header col-lg-3 col-6 px-1 px-lg-0" v-if="!hiring.open">
            <h5>Creator</h5>
            <p v-if="hiring.provider">
              {{
                hiring.provider.profile && hiring.provider.profile.perfil
                  ? hiring.provider.profile.perfil
                  : hiring.provider.name + ' ' + hiring.provider.last_name
              }}
            </p>
            <p v-else>-</p>
          </div>

          <div class="card-text-header col-lg-3 col-6 px-1 px-lg-0" v-if="!hiring.open">
            <div v-if="hiring.status != 4">
              <h5>Solicitado em</h5>
              <p>{{ formatDate(hiring.created_at) }}</p>
            </div>
            <div v-else>
              <h5>Aprovado em</h5>
              <p>{{ formatDate(hiring.contract_date) }}</p>
            </div>
          </div>

        </div>

        <div v-bind:class="{ collapse: true, show: hiring.open }">

          <!-- d-flex flex-wrap box-description -->
          <div class="row">
            <div class="col-lg-3 col-12 mb-lg-4 mt-3 mt-lg-0" v-if="!editor.title">
              <h5 class="f-mobile d-inline-block">Título</h5>
                <i v-show="hiring.open" class="ico-edit-dark" @click="editor.title = true"></i>
                <p class="txt-briefing">
                  {{ hiring.title ? hiring.title : "-"}}
                </p>
            </div>
            <div class="col-lg-3 col-12 mb-lg-4 mt-3 mt-lg-0" v-else>
              <h5>Título</h5>
              <input type="text" class="form-control p-3 d-inline-block" style="width: 200px; height:13px;" :id="'title' + hiring.id" v-model="hiring.title" placeholder=" ">
              <label :for="'title' + hiring.id"></label>
              <i class="icons ico-check d-inline-block" @click="editTitle()"></i>
            </div>

            <div class="col-lg-3 col-6 mb-lg-4 mt-3 mt-lg-0">
              <h5 class="f-mobile">Empresa</h5>
              <a :href="'/dashboard/admin/enterprise-profile/' + hiring.company.id" target="_blank">
                <p class="txt-briefing">
                  {{
                    hiring.company && hiring.company.nome_fantasia
                      ? hiring.company.nome_fantasia
                      : hiring.company && hiring.company.razao
                  }}<br>
                  {{ hiring.company.cnpj }}
                </p>
              </a>
            </div>
            <div class="col-lg-3 col-6 mb-lg-4 mt-3 mt-lg-0" v-if="!editor.user">
              <h5 class="f-mobile d-inline-block">Usuário</h5>
              <i class="ico-edit-dark" @click="editor.user = true"></i>
              <a :href="'/dashboard/admin/company/' + hiring.company.id + '/profile/' + hiring.user.id" target="_blank">
                <p class="txt-briefing">
                  {{ hiring.user ? hiring.user.name : "" }}
                  {{
                    hiring.user && hiring.user.last_name !== "null"
                      ? hiring.user.last_name
                      : ""
                  }}
                </p>
              </a>
            </div>
             <div v-else class="col-lg-3 col-6 mb-lg-4 mt-3 mt-lg-0">
                <h5 class="f-mobile  d-inline">Usuário</h5>
                <i class="icons ico-check d-inline-block" @click="editUser()"></i>
                <div class="form-group">
                  <input type="text" class="form-control p-1" :id="'user' + hiring.id" v-model="hiring.user_id" placeholder=" ">
                </div>
            </div>            
            <div class="col-lg-3 col-6 mb-lg-4 mt-3 mt-lg-0">
              <h5 class="f-mobile">Solicitado em</h5>
              <p class="txt-briefing">{{ formatDate(hiring.created_at) }}</p>
            </div>
            <div
              class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0"
            >
              <div v-if="!editor.value">
                <h5 class="f-mobile d-inline-block">Orçamento</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.value = true"></i> -->
                <i class="ico-edit-dark" @click="editor.value = true"></i>
                <p class="purple">{{ toCurrency(hiring.value) }}</p>
              </div>
              <div v-else class="form-group has-float-label">
                <input
                  type="text"
                  class="form-control"
                  :id="'budget' + hiring.id"
                  v-model="hiring.value"
                  v-money="money"
                  aria-describedby="emailHelp"
                  placeholder=" "
                />
                <label :for="'budget' + hiring.id">Orçamento</label>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editValue()"
                ></i>
              </div>
            </div>

            <div class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0">
              <div v-if="!editor.date_start">
                <h5 class="f-mobile d-inline-block">Início</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.date_start = true"></i> -->
                <i
                  class="ico-edit-dark"
                  @click="editor.date_start = true"
                ></i>
                <p class="txt-briefing">{{ formatDate(hiring.date_start) }}</p>
              </div>
              <div v-else class="form-group has-float-label">
                <input
                  type="date"
                  class="form-control form-date"
                  :id="'date-start' + hiring.id"
                  v-model="hiring.date_start"
                  aria-describedby="emailHelp"
                  placeholder=" "
                />
                <label :for="'date-start' + hiring.id">Início</label>
                <i class="icons ico-calendar-dark pointer-events"></i>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editDateStart()"
                ></i>
              </div>
            </div>
            <div
              class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0"
            >
              <div v-if="!editor.date_end">
                <h5 class="f-mobile d-inline-block">Término</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.date_end = true"></i> -->
                <i
                  class="ico-edit-dark"
                  @click="editor.date_end = true"
                ></i>
                <p class="txt-briefing">{{ formatDate(hiring.date_end) }}</p>
              </div>
              <div v-else class="form-group has-float-label">
                <input
                  type="date"
                  class="form-control form-date"
                  :id="'date-end' + hiring.id"
                  v-model="hiring.date_end"
                  aria-describedby="emailHelp"
                  placeholder=" "
                />
                <label :for="'date-end' + hiring.id">Término</label>
                <i class="icons ico-calendar-dark pointer-events"></i>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editDateEnd()"
                ></i>
              </div>
            </div>
            <div
              class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0"
            >
              <div v-if="!editor.company_computer">
                <h5 class="f-mobile d-inline-block">Equipamento</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.company_computer = true"></i> -->
                <i
                  class="ico-edit-dark"
                  @click="editor.company_computer = true"
                ></i>
                <p class="txt-briefing" v-if="hiring.company_computer === 0">
                  Próprio
                </p>
                <p class="txt-briefing" v-if="hiring.company_computer === 1">
                  Fornecido
                </p>
                <p class="txt-briefing" v-if="hiring.company_computer === 2">
                  Alugado
                </p>
              </div>
              <div v-else class="form-group has-float-label">
                <select
                  class="form-control"
                  :id="'equipment' + hiring.id"
                  v-model="hiring.company_computer"
                >
                  <option value="no">Equipamento próprio</option>
                  <option value="yes">Equipamento será fornecido</option>
                  <option value="maybe">
                    Desejo alugar equipamento com desconto (SP e POA)
                  </option>
                </select>
                <label :for="'equipment' + hiring.id">Equipamento</label>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editComputer()"
                ></i>
              </div>
            </div>
            <div
              class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0"
            >
              <div v-if="!editor.worktype">
                <h5 class="f-mobile d-inline-block">Trabalho</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.worktype = true"></i> -->
                <i
                  class="ico-edit-dark"
                  @click="editor.worktype = true"
                ></i>
                <p class="txt-briefing" v-if="hiring.inloco === 0">Remoto</p>
                <p class="txt-briefing" v-if="hiring.inloco === 1">Presencial</p>
                <p class="txt-briefing" v-if="hiring.inloco === 2">Híbrido</p>
              </div>
              <div v-else class="form-group has-float-label">
                <select
                  class="form-control"
                  :id="'inlocotype' + hiring.id"
                  v-model="hiring.inloco"
                >
                  <option value="inloco">Presencial</option>
                  <option value="remote">Remoto</option>
                  <option value="misto">Híbrido</option>
                </select>
                <label :for="'inlocotype' + hiring.id">Trabalho</label>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editWorktype()"
                ></i>
              </div>
            </div>

            <!-- <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.obs">
              <h5 class="d-inline">Observações (só no admin)</h5>
              <i class="ico-edit-dark" @click="editor.obs = true"></i>
              <p>{{ hiring.obs }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Observações (só no admin)</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.obs, 'obs')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.obs" placeholder=" "></div>
            </div> -->

          </div>

          <div class="row">

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.brand">
              <h5 class="d-inline">Marca do cliente</h5>
              <i class="ico-edit-dark" @click="editor.brand = true"></i>
              <p>{{ hiring.brand }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Marca do cliente</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.brand, 'brand')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.brand" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.audience">
              <h5 class="d-inline">Audiência/público</h5>
              <i class="ico-edit-dark" @click="editor.audience = true"></i>
              <p>{{ hiring.audience }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Audiência/público</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.audience, 'audience')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.audience" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.channels">
              <h5 class="d-inline">Canais preferenciais</h5>
              <i class="ico-edit-dark" @click="editor.channels = true"></i>
              <p>{{ hiring.channels }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Canais preferenciais</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.channels, 'channels')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.channels" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.team">
              <h5 class="d-inline">Número de criadores</h5>
              <i class="ico-edit-dark" @click="editor.team = true"></i>
              <p>{{ hiring.team }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Número de criadores</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.team, 'team')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.team" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.size">
              <h5 class="d-inline">Porte dos criadores</h5>
              <i class="ico-edit-dark" @click="editor.size = true"></i>
              <p>{{ hiring.size }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Porte dos criadores</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.size, 'size')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.size" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4" v-if="!editor.frequency">
              <h5 class="d-inline">Frequência de postagem</h5>
              <i class="ico-edit-dark" @click="editor.frequency = true"></i>
              <p>{{ hiring.frequency }}</p>
            </div>
            <div class="col-6 col-lg-3 mb-lg-4" v-else>
              <h5 class="d-inline">Frequência de postagem</h5>
              <i class="icons ico-check d-inline-block" @click="update(hiring.frequency, 'frequency')"></i>
              <div class="form-group"><input type="text" class="form-control p-1" v-model="hiring.frequency" placeholder=" "></div>
            </div>

            <div class="col-6 col-lg-3 mb-lg-4">
              <h5 class="d-inline">Link de compartilhamento</h5>
              <p>{{ 'https://creators.llc/dashboard/professional/briefing/' + hiring.id }}</p>
            </div>

          </div>

          <div class="row" v-if="hiring.status == 5">
            <div class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0">
              <div>
                <h5 class="f-mobile d-inline-block">Cancelado</h5>
                <p class="txt-briefing">{{ formatDate(hiring.cancel_at) }}</p>
              </div>
            </div>

            <div class="col-lg-3 col-6 mb-lg-4 group-input mt-3 mt-lg-0">
              <div>
                <h5 class="f-mobile d-inline-block">Motivo</h5>
                <p class="txt-briefing">{{ (hiring.motive) ? hiring.motive.name : '-' }}</p>
              </div>
            </div>
          </div>

          <div class="row mb-lg-4">
              <div class="col-lg-3 mt-4 pt-1 mt-lg-0 group-input" >
              <div v-if="!editor.plan">
                <h5 class="d-inline-block">Plans</h5>
                <i class="ico-edit-dark" @click="editor.plan = true"></i>
                <p class="txt-briefing" v-if="hiring.plan === 1">Open 15%</p>
                <p class="txt-briefing" v-if="hiring.plan === 2">Community 15%</p>
                <p class="txt-briefing" v-if="hiring.plan === 7">Enterprise 20%</p>
                <p class="txt-briefing" v-if="hiring.plan === 3">Enterprise 25%</p>
                <p class="txt-briefing" v-if="hiring.plan === 6">Enterprise 30% (padrão)</p>
                <p class="txt-briefing" v-if="hiring.plan === 4">Creators pay 12%</p>
                <p class="txt-briefing" v-if="hiring.plan === 5">Zero free 0%</p>
              </div>
              <div class="form-group has-float-label" v-else>
                <select
                  class="form-control"
                  :id="'plan' + hiring.id"
                  v-model="hiring.plan"
                >
                  <option value="1">Open 15%</option>
                  <option value="2">Community 15%</option>
                  <option value="7">Enterprise 20%</option>
                  <option value="3">Enterprise 25%</option>
                  <option value="6">Enterprise 30% (padrão)</option>
                  <option value="4">Creators pay 12%</option>
                  <option value="5">Zero free 0%</option>
                </select>
                <label :for="'plan' + hiring.id">Plans</label>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editPlan()"
                ></i>
              </div>
            <div>
            </div>
            

            </div>
            <div class="col-lg-3 mt-4 pt-1 mt-lg-0 group-input">
                <div v-if="!editor.status">
                  <h5 class="d-inline">Status</h5>
                  <i class="ico-edit-dark" @click="editor.status = true"></i>
                  <p class="txt-briefing" v-if="hiring.status == 1">Novo</p>
                  <p class="txt-briefing" v-if="hiring.status == 2">Aberto para propostas</p>
                  <p class="txt-briefing" v-if="hiring.status == 3">Liberado para cliente</p>
                  <p class="txt-briefing" v-if="hiring.status == 4">Aprovado</p>
                  <p class="txt-briefing" v-if="hiring.status == 5">Cancelado/Encerrado</p>
                </div>
                <div class="form-group has-float-label" v-else>
                  <select class="form-control" :id="'status' + hiring.id" v-model="hiring.status">
                    <option value="1">Novo</option>
                    <option value="2">Aberto para propostas</option>
                    <option value="3">Liberado para cliente</option>
                    <option value="4">Aprovado</option>
                    <option value="5">Cancelado/Encerrado</option>
                  </select>
                  <label :for="'status' + hiring.id">Status</label>
                  <i class="icons ico-check d-inline-block" @click="editStatus()"></i>
                </div>
            </div>
            <!-- <div class="col-lg-6"></div> -->
          </div>

          <div class="row">

            <div class="col-lg-6 mt-4 pt-1 mt-lg-0 group-input">
              <div v-if="!editor.tasks">
                <h5 class="d-inline-block">Briefing</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.tasks = true"></i> -->
                <i class="ico-edit-dark" @click="editor.tasks = true"></i>
                <p class="grey" v-html="hiringTasks"></p>
              </div>
              <div v-else class="form-group has-float-label">
                <textarea
                  name="name"
                  v-model="hiring.tasks"
                  :id="'tasks' + hiring.id"
                  class="form-control"
                  rows="5"
                  cols="80"
                  placeholder=" "
                ></textarea>
                <label :for="'tasks' + hiring.id">Briefing</label>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editTasks()"
                ></i>
              </div>
            </div>

            <div class="col-lg-6 mt-4 pt-1 mt-lg-0 group-input">
              <div v-if="!editor.obs">
                <h5 class="d-inline-block">Observações (só admin)</h5>
                <!-- <i v-if="hiring.status <= 3" class="ico-edit-dark" @click="editor.tasks = true"></i> -->
                <i class="ico-edit-dark" @click="editor.obs = true"></i>
                <p class="grey" v-html="hiring.obs"></p>
              </div>
              <div v-else class="form-group has-float-label">
                <textarea
                  name="name"
                  v-model="hiring.obs"
                  :id="'obs-' + hiring.id"
                  class="form-control"
                  rows="5"
                  cols="80"
                  placeholder=" "
                  maxlength="2000"
                ></textarea>
                <label :for="'obs-' + hiring.id">Observações (só admin)</label>
                <i class="icons ico-check d-inline-block" @click="update(hiring.obs, 'obs')"></i>
              </div>
            </div>

            <div class="col-lg-6 mt-4 pt-1 group-input">

              <!-- Habilidades -->
              <div v-if="!editor.specialities">
                <div>
                  <h5 class="d-inline-block">Habilidades</h5>
                  <!-- <i v-if="hiring.status < 2" class="ico-edit-dark" @click="editor.specialities = true"></i> -->
                  <i
                    class="ico-edit-dark"
                    @click="editor.specialities = true"
                  ></i>
                </div>
                <p
                  class="tag mr-lg-2 mr-1 mb-3"
                  v-for="speciality in hiring.specialities"
                  :key="speciality.id"
                >
                  {{ speciality.pt }}
                </p>
              </div>
              <div v-else class="form-group has-float-label">
                <input
                  type="text"
                  class="form-control"
                  id="complementares"
                  placeholder=" "
                  onkeyup="this.setAttribute('value', this.value);"
                  list="complementares-list"
                  autocomplete="off"
                  v-model.lazy="formSpecialities.specialitiesInput"
                  @change="validateListComplementares"
                />
                <label for="complementares">Outras 3 habilidades</label>
                <datalist id="complementares-list">
                  <option v-for="speciality in specialities" :key="speciality">
                    {{ speciality.pt }}
                  </option>
                </datalist>
                <div
                  class="invalid-feedback d-block"
                  v-if="errors.specialities && errors.specialities.length"
                >
                  <small>{{ errors.specialities[0] }}</small>
                </div>
                <div class="my-3">
                  <span
                    class="tag mr-2"
                    v-for="s in formSpecialities.specialities"
                    :key="s.id"
                  >
                    {{ s.pt }}
                    <i
                      class="ico-close grey"
                      v-on:click="removeSpeciality(s)"
                    ></i>
                  </span>
                </div>
                <i
                  class="icons ico-check d-inline-block"
                  @click="editSpecialities()"
                ></i>
              </div>

              <!-- Habilidades -->
              <div v-if="hiring.contact">
                <h5 class="d-inline-block">Proposta enviada para</h5>
                <p>{{ hiring.contact }}</p>
              </div>

            </div>
            <!-- TODO: Arquivos do brief -->
            <div
              class="col-lg-12 px-0 my-4 py-1"
              v-if="hiring.files && hiring.files.length"
            >
              <h3 class="d-inline-block">Anexos</h3>
              <div class="d-flex flex-wrap btn-anexos">
                <a
                  v-for="file in hiring.files"
                  :key="file.name"
                  :href="file.url"
                  class="btn btn-outline-primary sm btn-center-icons w-auto mr-lg-3"
                  target="_blank"
                >
                  <i class="icons ico-file mr-2"></i> {{ file.name }}
                </a>
              </div>
            </div>

            <div
              class="mt-3"
              v-if="
                hiring.reviews &&
                hiring.reviews.length > 0 &&
                hiring.reviews.filter(review => review.target_id == hiring.user_id)[0]
              "
            >
              <h5 class="f-mobile">Avaliação recebida</h5>
              <div class="evaluation-stars">
                <div class="rating-briefing my-2">
                  <i
                    class="ico-rating-evaluated"
                    :class="{
                      'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.user_id)[0].total > 0,
                    }"
                  ></i>
                  <i
                    class="ico-rating-evaluated"
                    :class="{
                      'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.user_id)[0].total > 1,
                    }"
                  ></i>
                  <i
                    class="ico-rating-evaluated"
                    :class="{
                      'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.user_id)[0].total > 2,
                    }"
                  ></i>
                  <i
                    class="ico-rating-evaluated"
                    :class="{
                      'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.user_id)[0].total > 3,
                    }"
                  ></i>
                  <i
                    class="ico-rating-evaluated"
                    :class="{
                      'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.user_id)[0].total > 4,
                    }"
                  ></i>
                </div>
              </div>
            </div>

            <div class="col-12">
              <hr />
            </div>

            <!-- NOVAS - profissionais pré-selecionados -->

            <div class="col-12" v-show="hiring.status == 1">
              <h3>Creators sugeridos</h3>
              <p v-if="hiring.preselected && hiring.preselected.length > 0">
                {{ hiring.preselected.length || 0 }} resultados encontrados
              </p>

              <flickity
                :ref="'slider-' + hiring.id"
                :options="options"
                class="carousel-professionals my-4 pt-2"
                :id="'slider-' + hiring.id"
              >
                <div
                  class="col-12 text-center"
                  v-if="
                    hiring.preselected &&
                    !hiring.preselected.length &&
                    !hiring.noOne
                  "
                >
                  <sync-loader
                    class="loader-container"
                    :loading="loading"
                    :color="loaderColor"
                    :size="loaderSize"
                  />
                </div>
                <div
                  class="col-12 text-center"
                  v-if="
                    hiring.preselected &&
                    !hiring.preselected.length &&
                    hiring.noOne
                  "
                >
                  <p>Nenhum profissional encontrado pra esta contratação</p>
                </div>
                <div
                  class="col-lg-4 carousel-cell"
                  v-for="preuser in hiring.preselected"
                  :key="preuser.id"
                >
                  <c-card-professional
                    :user="preuser"
                    @removeOne="removeOne"
                  ></c-card-professional>
                </div>
              </flickity>

              <div class="w-100 d-lg-flex flex-wrap">
                <div class="form-group has-float-label col-lg-8 d-flex flex-wrap px-0">
                  <input
                    type="text"
                    class="form-control col-lg-6"
                    :id="'inputAddCreator' + hiring.id"
                    placeholder=" "
                    v-model="inputAddCreator"
                  />
                  <label :for="'inputAddCreator' + hiring.id">
                    Adicionar ID de Creator
                  </label>
                  <button
                    type="button"
                    class="btn btn-outline-primary col-lg-3 m-0 ml-lg-3 mt-3 mt-lg-0 sm"
                    v-on:click="addCreator()"
                  >
                    Adicionar
                  </button>
                </div>
              </div>
              <div class="form-group form-check" v-if="hiring.status == 1">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :id="'checkShowValue' + hiring.id"
                  v-model="formApprove.reveal"
                />
                <span class="checkmark"></span>
                <label
                  class="form-check-label"
                  :for="'checkShowValue' + hiring.id"
                  >Marque aqui caso NÃO deseje que a verba seja divulgada aos
                  profissionais.</label
                >
              </div>
            </div>

            <div
              class="col-12"
              v-if="hiring.status == 2 || hiring.status == 3"
            >
              <h3>Creators pré-selecionados</h3>
              <p>{{  (hiring.providers && hiring.providers.length) || 0 }} resultados encontrados</p>

              <flickity
                :ref="'slider-' + hiring.id"
                :options="options"
                class="carousel-professionals my-4 pt-2"
                :id="'slider-' + hiring.id"
              >
                <div
                  class="col-12 text-center"
                  v-if="hiring.providers && !hiring.providers.length"
                >
                  <p>
                    Ainda nenhum profissional respondeu o convite para esta
                    contratação
                  </p>
                </div>
                <div
                  class="col-lg-4 carousel-cell"
                  v-for="provider in hiring.providers"
                  :key="provider.id"
                >
                  <c-card-professional
                    :user="provider"
                    :plan="hiring.plan"
                    @removeOne="removeOne"
                    @changeVisibility="changeVisibility"
                  ></c-card-professional>
                </div>
              </flickity>

              <div class="w-100 d-lg-flex flex-wrap">
                <div class="form-group has-float-label col-lg-8 d-flex flex-wrap px-0">
                  <input
                    type="text"
                    class="form-control col-lg-6"
                    :id="'invite-' + hiring.id"
                    placeholder=" "
                    v-model="invite"
                  />
                  <label :for="'invite-' + hiring.id">
                    Adicionar ID de Creator
                  </label>
                  <button
                    type="button"
                    class="btn btn-outline-primary col-lg-3 m-0 ml-lg-3 mt-3 mt-lg-0 sm"
                    v-on:click="inviteCreator()"
                  >
                    Convidar
                  </button>
                </div>
              </div>

              <!-- <div class="w-100 d-lg-flex flex-wrap">
            </div> -->
            </div>

            <!-- Em andamento - profissional selecionado -->
            <div
              class="col-12"
              v-if="hiring.provider && hiring.status > 3"
            >
              <h3>Creator contratado/a</h3>
              <div class="mt-4 mb-3">
                <div class="row">
                  <div class="col-12 mb-4 pb-2">
                    <img
                      v-if="hiring.provider.avatar"
                      :src="'/' + hiring.provider.avatar"
                      alt="Card image"
                      class="thumb-user mr-2"
                    />
                    <img
                      v-else
                      src="/images/avatar.jpg"
                      class="thumb-user mr-2"
                    />

                    <div class="d-inline-block vertical-align-middle ml-2">
                      <a :href="'/dashboard/admin/users/' + hiring.provider.id" target="_blank">
                        <h5 class="m-0">
                          {{ hiring.provider.name }}
                          {{
                            hiring.provider.last_name !== "null"
                              ? hiring.provider.last_name
                              : ""
                          }}
                        </h5>
                      </a>
                      <p class="m-0"><a :href="'mailto:' + hiring.provider.email" target="_blank">{{ hiring.provider.email }}</a></p>
                      <!-- <i class="ico-star-green selected"></i>
                    <i class="ico-star-green selected"></i>
                    <i class="ico-star-green selected"></i>
                    <i class="ico-star-green selected"></i>
                    <i class="ico-star-green"></i> -->
                    </div>
                  </div>
                  <div class="col-lg-3 col-6 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">CPF/CNPJ</h5>
                    <p class="txt-briefing" v-if="hiring.provider && hiring.provider.financial.type == 'pj'">{{ (hiring.provider.legal) ? hiring.provider.legal.cnpj : '-' }}</p>
                    <p class="txt-briefing" v-else>{{ (hiring.provider) ? hiring.provider.profile.cpf : '' }}</p>
                  </div>
                  <div class="col-lg-3 col-6 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">Titular</h5>
                    <p class="txt-briefing">
                      {{
                        hiring.provider && hiring.provider.financial
                          ? hiring.provider.financial.razao
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="col-lg-3 col-6 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">Banco</h5>
                    <p class="txt-briefing">
                      {{
                        hiring.provider.financial
                          ? getBankName(hiring.provider.financial.bank_id)
                          : "-"
                      }}
                    </p>
                  </div>
                  <div class="col-lg-3 col-6 pr-lg-3 mt-3 mt-lg-0">
                    <div class="d-flex justify-content-between">
                      <div class="">
                        <h5 class="f-mobile">Agência</h5>
                        <p class="txt-briefing">
                          {{
                            hiring.provider.financial
                              ? hiring.provider.financial.agency
                              : ""
                          }}
                        </p>
                      </div>
                      <div class="">
                        <h5 class="f-mobile">Conta</h5>
                        <p class="txt-briefing">
                          {{
                            hiring.provider.financial
                              ? hiring.provider.financial.account
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-lg-3 col-6 pr-lg-3 mt-3"
                    v-if="
                      hiring.reviews &&
                      hiring.reviews.length > 0 &&
                      hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0]
                    "
                  >
                    <h5 class="f-mobile">Avaliação recebida</h5>
                    <div class="evaluation-stars box-padding">
                      <div class="rating-briefing my-2">
                        <i
                          class="ico-rating-evaluated"
                          :class="{
                            'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0].total > 0,
                          }"
                        ></i>
                        <i
                          class="ico-rating-evaluated"
                          :class="{
                            'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0].total > 1,
                          }"
                        ></i>
                        <i
                          class="ico-rating-evaluated"
                          :class="{
                            'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0].total > 2,
                          }"
                        ></i>
                        <i
                          class="ico-rating-evaluated"
                          :class="{
                            'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0].total > 3,
                          }"
                        ></i>
                        <i
                          class="ico-rating-evaluated"
                          :class="{
                            'evaluated-checked': hiring.reviews.filter(review => review.target_id == hiring.provider.id)[0].total > 4,
                          }"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <!--  -->
            </div>

            <!-- Em andamento - FINANCEIRO -->
            <div class="col-12" v-if="hiring.status > 2">
              <h3>Financeiro</h3>
              <div class="mt-4 mb-3">
                <div class="row">
                  <div class="col-lg-3 col-6 mb-lg-2 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">E-mail</h5>
                    <p class="txt-briefing">
                      {{ hiring.company.financial_email || hiring.user.email }}
                    </p>
                  </div>
                  <div class="col-lg-3 col-6 mb-lg-2 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">Valor aprovado (sem taxa)</h5>
                    <p class="txt-briefing">
                      {{
                        hiring.approved_value
                          ? toCurrency(hiring.approved_value)
                          : "-"
                      }}
                    </p>
                  </div>
                  <div class="col-lg-3 col-6 mb-lg-2 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">Valor aprovado (com taxa)</h5>
                    <p class="txt-briefing">
                      {{
                        hiring.approved_value
                          ? toCurrency(
                              costByPlan(hiring.approved_value, hiring.plan)
                            )
                          : "-"
                      }}
                    </p>
                  </div>
                  <div class="col-lg-3 col-6 mb-lg-2 pr-lg-3 mt-3 mt-lg-0">
                    <h5 class="f-mobile">NF Creators</h5>
                    <p class="txt-briefing">
                      {{
                        hiring.approved_value
                          ? toCurrency(
                              costByPlan(hiring.approved_value, hiring.plan) -
                                hiring.approved_value
                            )
                          : "-"
                      }}
                    </p>
                  </div>
                  <!-- <div class="col-lg-3 col-6 mb-lg-2 pr-lg-3 mt-3 mt-lg-0" v-if="hiring.company.community_flag == 1">
                <h5 class="f-mobile">Taxa Cliente Community</h5>
                <p class="txt-briefing">R$ ***</p>
              </div> -->
                  <div class="col-12">
                    <div class="btn-anexos">
                      <a
                        v-if="providerInvoice.id"
                        :href="'/' + providerInvoice.url"
                        class="ml-2 btn btn-outline-primary sm btn-center-icons w-auto"
                        target="_blank"
                      >
                        <i class="icons ico-file mr-2"></i
                        >{{ providerInvoice.name }}
                      </a>
                      <a
                        v-if="creatorInvoice.id"
                        :href="'/' + creatorInvoice.url"
                        class="ml-2 btn btn-outline-primary sm btn-center-icons w-auto"
                        target="_blank"
                      >
                        <i class="icons ico-file mr-2"></i
                        >{{ creatorInvoice.name }}
                      </a>
                      <a
                        v-if="hiring.billing && hiring.billing.wpo_pix"
                        :href="'https://pagar.goboleto.com/?hash=' + hiring.billing.wpo_pix"
                        class="ml-2 btn btn-outline-primary sm btn-center-icons w-auto"
                        download target="_blank"
                      >
                        <i class="icons ico-file"></i> PIX
                      </a>
                      <a
                        v-if="hiring.billing && hiring.billing.wpo_boleto"
                        :href="'https://pagar.goboleto.com/?hash=' + hiring.billing.wpo_boleto"
                        class="ml-2 btn btn-outline-primary sm btn-center-icons w-auto"
                        download target="_blank"
                      >
                        <i class="icons ico-file"></i> Boleto
                      </a>
                      <a :href="'/dashboard/contract/' + hiring.id" class="ml-2 btn btn-outline-primary sm btn-center-icons w-auto" target="_blank">
                        <i class="icons ico-file"></i> Contrato
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <!--  -->
            </div>

            <!-- Novo boleto -->
            <div class="col-12" v-if="hiring.status == 4 && !creatorInvoice.id">
              <h3>Enviar novo boleto</h3>
              <div class="mt-4 mb-3">
                <div class="row">

                  <div class="col-lg-4">
                    <div class="form-group has-float-label d-flex flex-wrap px-0">
                      <input
                        type="date"
                        class="form-control"
                        :id="'invoice-date' + hiring.id"
                        v-model="boleto.date"
                        placeholder=" "
                      />
                      <label :for="'invoice-date' + hiring.id">Vencimento</label>
                      <i class="icons ico-calendar-dark pointer-events"></i>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="float-right d-flex flex-wrap d-lg-block justify-content-center">
                      <button type="button" @click="newBoleto" class="btn btn-primary fit-content m-0">
                        Enviar
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              <hr />
              <!--  -->
            </div>

            <!-- Em andamento -  Nota fiscal do cliente -->
            <div class="col-12" v-if="hiring.status == 4 && !creatorInvoice.id">
              <h3>Nota fiscal do cliente</h3>
              <div class="mt-4 mb-3">
                <div class="row">
                  <div class="col-lg-4">
                    <div
                      class="form-group has-float-label d-flex flex-wrap px-0"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :id="'invoice-name' + hiring.id"
                        v-model="formInvoice.name"
                        aria-describedby="emailHelp"
                        placeholder=" "
                      />
                      <label :for="'invoice-name' + hiring.id"
                        >Nome do arquivo</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="form-group has-float-label d-flex flex-wrap px-0"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :id="'invoice-value' + hiring.id"
                        v-model.lazy="formInvoice.value"
                        v-money="money"
                        aria-describedby="emailHelp"
                        placeholder=" "
                      />
                      <label :for="'invoice-value' + hiring.id">Valor</label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="form-group has-float-label d-flex flex-wrap px-0"
                    >
                      <input
                        type="date"
                        class="form-control"
                        :id="'invoice-date' + hiring.id"
                        v-model="formInvoice.date"
                        aria-describedby="emailHelp"
                        placeholder=" "
                      />
                      <label :for="'invoice-date' + hiring.id"
                        >Vencimento</label
                      >
                      <i class="icons ico-calendar-dark pointer-events"></i>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group has-float-label">
                      <input
                        type="file"
                        :id="'invoice' + hiring.id"
                        ref="invoice"
                        @change="handleInvoiceUpload"
                        class="form-control"
                      />
                      <label :for="'invoice' + hiring.id"
                        >Selecione anexos do briefing</label
                      >
                      <!-- ou arraste -->
                    </div>
                    <div
                      class="float-right d-flex flex-wrap d-lg-block justify-content-center"
                    >
                      <button
                        type="button"
                        @click="sendInvoice()"
                        class="btn btn-primary fit-content m-0 ml-lg-3 mb-lg-3 mb-4 mt-lg-4 order-3"
                      >
                        Enviar
                      </button>
                    </div>
                    <!-- <div class="btn-anexos">
                <a href="#" class="btn btn-outline-primary sm btn-center-icons w-auto"><i class="icons ico-file mr-2"></i>NF-profissional</a>
              </div> -->
                  </div>
                </div>
              </div>
              <hr />
              <!--  -->
            </div>

            <!-- <div class="col-12 px-0" v-if="hiring.status == 4">
      <hr>
    </div> -->

            <div class="col-12">
              <!-- NOVAS - Feedback -->

              <h3 class="mt-4 mb-3">Feedback</h3>

              <div class="form-group">
                <textarea
                  class="form-control"
                  v-model="formFeedback.body"
                  name="name"
                  rows="3"
                  cols="80"
                  placeholder="Texto de feedback para o cliente"
                ></textarea>
                <div class="text-right">
                  <button
                    type="button"
                    class="btn btn-outline-primary m-0 ml-lg-3 mb-3 mt-4 fit-content w-100-mobile order-1"
                    @click="sendFeedback()"
                  >
                    Enviar feedback
                  </button>
                </div>

                <div style="font-size: 0.9em">
                  <div v-for="feedback in hiring.feedbacks" :key="feedback.id">
                    <p class="mt-2">Data: {{ feedback.created_at }}</p>
                    <p class="mb-2">{{ feedback.body }}</p>
                  </div>
                </div>

                <div style="font-size: 0.9em">
                  <div
                    v-for="(feedback, index) in hiring.feedbacks"
                    :key="index"
                  >
                    <p class="mt-2">Data: {{ feedback.created_at }}</p>
                    <p class="mb-2">{{ feedback.body }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr v-if="hiring.status != 5">

          <!-- BUTTONS action -->
          <div class="row">

            <div class="col-6 col-lg-3" v-if="hiring.status != 5">
              <div class="form-group has-float-label">
                <select id="cancel-motive" class="form-control" v-model="motive">
                  <option :value="m.id" v-for="m in motives">{{ m.name }}</option>
                </select>
                <label for="cancel-motive">Motivo do cancelamento</label>
              </div>
            </div>

            <div class="col-6 col-lg-3" v-if="hiring.status != 5">
              <button type="button" class="btn btn-outline-danger fit-content m-0" v-on:click="cancelHiring()">
                Encerrar
              </button>
            </div>

            <div class="col-12 text-right" :class="{'col-lg-6': hiring.status != 5}">
              <button
                type="button"
                class="btn btn-primary fit-content m-0"
                v-if="hiring.status == 1"
                v-on:click="approveBriefing()"
              >
                Aprovar
              </button>
              <button
                type="button"
                class="btn btn-primary fit-content m-0"
                v-if="hiring.status == 2"
                @click="progressBriefing()"
              >
                Avançar
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import moment from "moment";
export default {
  props: ["hiring", "specialities", "banks"],

  data() {
    return {
      flickity: false,
      options: {
        cellSelector: ".carousel-cell",
        cellAlign: "left",
        wrapAround: true,
        groupCells: 3,
        pageDots: false,
        resize: true,
        initialIndex: 0,
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
      },
      editor: {
        date_start: false,
        date_end: false,
        value: false,
        company_computer: false,
        worktype: false,
        tasks: false,
        specialities: false,
        obs: false,
        brand: false,
        audience: false,
        channels: false,
        team: false,
        size: false,
        frequency: false,
        user: false,
        company: false,
        plan: false,
        status: false,
        title: false
      },
      invite: "",
      inputAddCreator: "",
      formApprove: {
        reveal: false,
      },
      loaderSize: "15px",
      loaderColor: "black",
      loading: true,
      formInvoice: {
        name: "",
        value: this.hiring.approved_value
          ? this.toCurrency(
              this.costByPlan(this.hiring.approved_value, this.hiring.plan) -
                this.hiring.approved_value
            )
          : 0,
        date: moment().format("YYYY-MM-DD"),
        file: "",
        hiring_id: this.hiring.id,
      },
      formFeedback: {
        hiring_id: this.hiring.id,
        body: "",
      },
      formSpecialities: {
        specialities: this.hiring.specialities,
        specialitiesInput: "",
      },
      boleto: {
        date: '',
      },
      motive: 1,
      motives: [
        {id: 1, name: 'CANCELADO'},
        {id: 2, name: 'REPROVADO'},
        {id: 3, name: 'SEM PROPOSTAS'},
        {id: 4, name: 'SEM COMUNICAÇÃO'},
        {id: 5, name: 'SUSPENSO CLIENTE'},
        {id: 6, name: 'SUSPENSO CRIADOR'},
        {id: 7, name: 'ERRO'},
        {id: 8, name: 'OUTROS'},
      ],
      errors: {
        specialities: [],
      },
    };
  },
  components: {
    SyncLoader,
  },

  mounted() {
    // if (!this.specialities || this.specialities.length) this.specialities = [];
    if (window.innerWidth < 768) {
      this.options.groupCells = 1;
    }
  },

  methods: {
    getBankName(bank_id) {
      if (bank_id && bank_id != "" && this.banks && this.banks.length > 0)
        return this.banks.find((bank) => bank.id === bank_id).name;

      return "-";
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    toCurrency(val) {
      return (val / 100).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      });
    },

    valueByPlan(budget, plan) {
      let value = 0;
      switch (plan) {
        case 1:
          value = budget * 0.85;
          break;
        case 2:
          value = budget * 0.85;
          break;
        case 3:
          value = budget * 0.75;
          break;
        case 4:
            value = value * 0.8928571429;
            break;
        case 5:
            value = value;
            break;
        case 6:
            value = value * 0.70;
            break;
        case 7:
            value = value * 0.80;
            break;
        default:
          value = budget * 0.85;
      }
      return value;
    },

    costByPlan(value, plan) {
      switch (plan) {
        case 1:
          value = value / 0.85;
          break;
        case 2:
          value = value / 0.85;
          break;
        case 3:
          value = value / 0.75;
          break;
        case 4:
          value = value / 0.8928571429;
          break;
        case 5:
          value = value;
          break;
        case 6:
          value = value / 0.70;
          break;
        case 7:
          value = value / 0.80;
          break;
        default:
          value = value / 0.85;
      }
      // Pernod Ricard tem 0,6% sobre o valor final
      if (this.hiring.company_id == 159) value = value * 1.006;
      return value;
    },

    toggleItem() {
      // this.open = !this.open;
      this.$emit("toggleOpen", this.hiring);
      // if (this.open);
      // this.$emit("open", this.hiring);
    },

    addCreator() {
      this.$emit("addCreator", {
        id: this.inputAddCreator,
        hiring: this.hiring,
      });
      this.inputAddCreator = "";
    },

    inviteCreator() {
      this.$emit("inviteCreator", {
        id: this.invite,
        hiring: this.hiring,
      });
      this.invite = "";
    },

    approveBriefing() {
      this.formApprove.selected = this.hiring.preselected;
      this.formApprove.hiring = this.hiring;
      this.$emit("approveBriefing", this.formApprove);
    },

    sendInvoice() {
      this.$emit("sendInvoice", this.formInvoice);
    },

    sendFeedback() {
      if (!this.formFeedback.body.length) {
        alert("Texto de feedback obrigatório");
        return;
      }
      this.$emit("sendFeedback", this.formFeedback);
      this.formFeedback.body = "";
    },

    handleInvoiceUpload(ev) {
      this.formInvoice.file = this.$refs.invoice.files[0];
    },

    progressBriefing() {
      this.$emit("progressBriefing", this.hiring);
    },

    cancelHiring() {
      let data = {
        hiring: this.hiring,
        motive: this.motive
      };
      console.log(data);
      this.$emit("cancelHiring", data);
    },

    removeOne(userId) {
      let index = this.hiring.preselected.map((e) => e.id).indexOf(userId);
      this.options.initialIndex = Math.floor(index / 2);
      if (index > -1) this.hiring.preselected.splice(index, 1);
    },

    update(value, field) {
      this.editor[field] = false;
      var data = {id: this.hiring.id};
      data[field] = value;
      this.$emit('editHiring', data);
    },

    editValue() {
      this.hiring.value = parseInt(this.hiring.value.replace(/\D/g, ""));
      this.editor.value = false;
      var data = { id: this.hiring.id, value: this.hiring.value };
      this.$emit("editHiring", data);
    },

    editDateStart() {
      this.editor.date_start = false;
      var data = { id: this.hiring.id, date_start: this.hiring.date_start };
      this.$emit("editHiring", data);
    },

    editDateEnd() {
      this.editor.date_end = false;
      var data = { id: this.hiring.id, date_end: this.hiring.date_end };
      this.$emit("editHiring", data);
    },

    changeVisibility(data) {
      var data = {hiring: data.hiring, user: data.user, visibility: data.visibility};
      this.$emit("changeVisibility", data);
    },

    newBoleto() {
      var data = { id: this.hiring.id, date: this.boleto.date };
      this.$emit("newBoleto", data);
    },

    editComputer() {
      this.editor.company_computer = false;
      var data = {
        id: this.hiring.id,
        company_computer: this.hiring.company_computer,
      };
      switch (this.hiring.company_computer) {
        case "no":
          this.hiring.company_computer = 0;
          break;
        case "yes":
          this.hiring.company_computer = 1;
          break;
        case "maybe":
          this.hiring.company_computer = 2;
          break;
        default:
          this.hiring.company_computer = 0;
      }
      this.$emit("editHiring", data);
    },

    editWorktype() {
      this.editor.worktype = false;
      var data = { id: this.hiring.id, worktype: this.hiring.inloco };
      switch (this.hiring.inloco) {
        case "remoto":
          this.hiring.inloco = 0;
          break;
        case "inloco":
          this.hiring.inloco = 1;
          break;
        case "misto":
          this.hiring.inloco = 2;
          break;
        default:
          this.hiring.inloco = 0;
      }
      this.$emit("editHiring", data);
    },

    editTasks() {
      this.editor.tasks = false;
      var data = { id: this.hiring.id, tasks: this.hiring.tasks };
      this.$emit("editHiring", data);
    },

    editSpecialities() {
      if (this.formSpecialities.specialities.length < 1) {
        this.errors.specialities.push("Escolha no mínimo 1 habilidade.");
        return;
      } else this.errors.specialities = [];
      let specialities = [];
      for (let i = 0; i < this.formSpecialities.specialities.length; i++) {
        specialities.push(this.formSpecialities.specialities[i].id);
      }
      var data = { id: this.hiring.id, specialities: specialities };
      this.editor.specialities = false;
      this.hiring.specialities = this.formSpecialities.specialities;
      this.$emit("editHiring", data);
    },

    validateListComplementares(e) {
      let text = this.formSpecialities.specialitiesInput;
      let index = this.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      let double = this.formSpecialities.specialities
        .map(function (e) {
          return e.pt;
        })
        .indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        let selected = this.specialities[index];
        // não está ainda na lista selecionada de habilidades
        if (double < 0) this.formSpecialities.specialities.push(selected);
        this.formSpecialities.specialitiesInput = "";
      }
    },
    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.formSpecialities.specialities.indexOf(s);
      if (index > -1) {
        this.formSpecialities.specialities.splice(index, 1);
      }
    },
    editUser() {
      this.editor.user = false;
      var data = {id: this.hiring.id, user_id: this.hiring.user_id};
      this.$emit('editHiring', data);
    },
    editPlan() {
      this.editor.plan = false;
      var data = { id: this.hiring.id, plan: this.hiring.plan };
      this.$emit("editHiring", data);
    },
    editStatus(){
      this.editor.status = false;
      var data = { id: this.hiring.id, status: this.hiring.status };
      this.$emit("editHiring", data);
    },
    editTitle(){
      this.editor.title = false;
      var data = { id: this.hiring.id, title: this.hiring.title };
      this.$emit("editHiring", data);
    }
  },

  computed: {
    providerInvoice() {
      let invoice = {};
      if (this.hiring.invoices && this.hiring.invoices.length) {
        let found = this.hiring.invoices.find((e) => {
          return e.target == "provider";
        });
        if (found) invoice = found;
      }
      return invoice;
    },

    creatorInvoice() {
      let invoice = {};
      if (this.hiring.invoices && this.hiring.invoices.length) {
        let found = this.hiring.invoices.find((e) => {
          return e.target == "nosotros";
        });
        if (found) invoice = found;
      }
      return invoice;
    },

    hiringTasks() {
      if (this.hiring.tasks) return this.hiring.tasks.replace(/(?:\r\n|\r|\n)/g, '<br>');
      else return "";
    }
  },

  watch: {
    hiring: {
      handler: function (updated) {
        this.$nextTick(function () {
          // the magic
          this.$refs["slider-" + updated.id] &&
            this.$refs["slider-" + updated.id].rerender();
        });
      },
      deep: true,
    },
  },
};
</script>
