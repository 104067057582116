<template>

  <div class="c-b-confirm-professional">
    <div class="box-proposal card-white rounded">
      <div class="col-12 px-0 px-lg-3">
        <div class="d-flex flex-wrap justify-content-between">
          <div class="d-flex">
            <img v-if="user.avatar" :src="'/' + user.avatar" alt="Card image" class="thumb-project">
            <img v-else src="/images/avatar.jpg" class="thumb-project">
            <h3 class="my-lg-auto ml-3">{{ $t("dash.briefing.confirmar") }} {{ user.name }} {{ user.last_name && user.last_name !== 'null' ? user.last_name : '' }}</h3>
          </div>
          <div class="d-flex">

            <button class="btn-anexos btn btn-outline-primary sm btn-center-icons purple w-auto ml-3" @click="sendCancel">{{ $t("dash.cancelar") }}</button>

            <a :href="'/dashboard/contract/' + id + '?professional=' + user.id" target="_blank" class="btn-anexos btn btn-outline-primary sm btn-center-icons purple w-auto ml-3">
              <i class="icons ico-file"></i> {{ $t("dash.briefing.previa") }}
            </a>

            <!-- <div class="submit-buttons text-right mt-auto ml-3">
              <button v-if="!syncing" type="submit" class="btn btn-primary m-0 sm-mobile" @click="sendSelected">{{ $t("dash.briefing.aprovar") }}</button>
              <button v-else class="btn btn-primary m-0 sm-mobile">
                <div id="loader-btn" class="d-inline-block">
                  <div class="dots container">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </button>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    props: ['user', 'id'],

    data() {
      return {
        syncing: false,
      }
    },

    mounted() {
    },

    methods: {
      sendCancel() {
        this.$emit('unselect');
      },

      // sendSelected() {
      //   this.syncing = true;
      //   this.$emit('selectOne', this.user);
      // },

    },
  }
</script>
