<template>
  <div class="checkbox" @click="handleClick">
    <label :for="name" :class="{ checked: value }">
      <input type="checkbox" :checked="value" :name="name" />
      <span />
    </label>
    <span class="checkbox-text">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('change', this.name, !this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  cursor: pointer;
  display: flex;
  align-content: center;
  label {
    margin-right: 5px;
    margin-bottom: 0;
    input {
      display: none;
    }
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 1px solid #ffffff;
      &:before {
        font-size: 16px;
        content: '\2713';
        display: inline-block;
        color: #B59DFF;
        padding: 1px 2px 0 2px;
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }
    }
    &.checked {
      span {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  .checkbox-text {
    line-height: normal;
    font-family: 'SF Pro Display';
    font-size: 1.2em;
    color: #ffffff;
  }
}
</style>
