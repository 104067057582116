<template>
  <span class="chip">
    {{ value }}
    <img :class="{ show: edit }" src="/images/close.svg" @click="remove" />
  </span>
</template>

<script>
export default {
  name: 'Skill',
  props: {
    value: {
      type: String,
      required: false,
      default: 'GGWP'
    },
    edit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    remove(e) {
      this.$emit('remove', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
.chip {
  margin: 5px 0;
  height: 26px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  font-size: 0.8em;
  padding: 1px 10px;
  line-height: 3em;
  transition: width 2s;
  &:not(:last-child) {
    margin-right: 10px;
  }
  .show {
    opacity: 1;
    max-width: 50px;
    margin-left: 10px;
  }
  img {
    opacity: 0;
    max-width: 0;
    cursor: pointer;
    transition: all 0.5s;
  }
}
</style>
