<template>
  <div class="c-footer wraper-footer">


    <footer>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <img src="https://storage.googleapis.com/creators-static-1/images/logo-sm.svg" class="logo-footer mb-2" />
        </div>
      </div>
    </div>
  </footer>

  </div>
</template>

<script>
    export default {
      props: ['title','sub'],
      mounted() {
          console.log('Component mounted.')
      }
    }
</script>

<style lang="scss" src="./footer.scss"></style>
