<template>
  <div class="hero-home">

    <div class="background-banner" :style="{ 'background-image': 'url(/' + banner.source + ')' }"></div>
    <section class="welcome bg-welcome">
      <div class="mask"></div>
      <div class="container">
        <div class="row no-gutters">
          <div class="col-12 white text-left pt-3">
            <p class="img-author">{{ banner ? banner.author : '' }}</p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  props: ['banner'],
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>
